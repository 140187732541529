.landingPage2Container{
    margin: 0px !important;
    padding: 0px !important;
    width: 100% !important;
    max-width: 100% !important;  
}
.MuiTypography-root.aboutUsTitle.MuiTypography-h6{
	margin-left: 43%;
	margin-top: 15%;
	margin-bottom: 5%;
	color: #369;
	font-weight: bolder;
	font-size: 1.75rem;
}
.aboutUsBodyContainer {
    display: flex;
    flex-direction: row;
    grid-gap: 30px;
    gap: 30px;
    margin-bottom: 10%;
}
.videoContainer{
    width: 100%;
    height: 340px;
    border-radius: 10;
    display: block;
}
.imgContainer {
    width: 100%;
    height: 340px;
    border-radius: 10;
    object-fit: cover;
    display: block;
}
.MuiTypography-root.leftDescription.MuiTypography-body2{
    text-align: justify;
    color: black;
    width:101%;
    font-size:1.15rem;
}
.MuiTypography-root.rightDescription.MuiTypography-body2 {
    text-align: justify;
    color: black;
    width:101%;
    font-size:1.15rem;
}
.leftContainer{
    flex: 0.5;
    display: flex;
    flex-direction: column;
    gap: 12;
    margin-left:9%;
}
@media screen and (min-width:1400px) and (max-width:1950px)
{
    .leftContainer{
        flex: 0.5;
        display: flex;
        flex-direction: column;
        gap: 12;
        margin-left:9%;
    }
    .MuiTypography-root.aboutUsTitle.MuiTypography-h6 {
        margin-left: 44%;
        margin-top: 20%;
        margin-bottom: 5%;
        color: #369;
        font-weight: bolder;
        font-size: 2.55rem;
    }
    .MuiTypography-root.leftDescription.MuiTypography-body2 {
        text-align: justify;
        color: black;
        width: 100%;
        font-size: 1.15rem;
    }
    .MuiTypography-root.rightDescription.MuiTypography-body2 {
        text-align: justify;
        color: black;
        width: 102%;
        font-size: 1.15rem;
        margin-top: 2%;
    }
    .imgContainer {
        width: 100% !important;
        height: 103% !important;
        border-radius: 10px !important;
        object-fit: cover !important;
        display: block !important;
        margin-top: 2% !important;
    }
    .videoContainer {
        width: 100%;
        height: 45%;
        border-radius: 10px;
        display: block;
    }
}
@media screen and (min-width:960px) and (max-width:1200px)
{
    .leftContainer{
        flex: 0.5;
        display: flex;
        flex-direction: column;
        gap: 12;
        margin-left:9%;
        height: 100%;
    }
    .MuiTypography-root.rightDescription.MuiTypography-body2 {
        text-align: justify;
        color: black;
        width: 101%;
        font-size: 0.95rem;
        margin-top: 3%;
    }
    .videoContainer {
        width: 100%;
        height: 240px !important;
        border-radius: 10px;
        display: block;
        margin-top: 3%;
    }
    .MuiTypography-root.leftDescription.MuiTypography-body2 {
        text-align: justify;
        color: black;
        width: 101%;
        font-size: 0.95rem;
    }
    .imgContainer{
        width: 100%;
        height: 265px !important;
        border-radius: 10px;
        object-fit: cover;
        display: block;
        margin-top: 2%;
    }

}