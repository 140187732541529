    .barGraphDiv{
        margin: 20px;
        width:100%;
   }
    .barGraphDivMessage{
        margin: 0px 20px;
        width:100%;
        height: 160px;
    }
    .iconChartDiv{
        margin:10px;
        display: block;
}
    .barGraphIcons {
        width: 85%;
        height: 110%;
        display: inline;
    }
    .MuiPaper-root.barGraphPaper.MuiPaper-elevation3.MuiPaper-rounded {
        width: 74%;
        height: 243px;
        border-radius: 10px;
    }
    .MuiPaper-root.barGraphPaperMessage.MuiPaper-elevation3.MuiPaper-rounded {
        width: 70%;
        height: 12.5em;
        border-radius: 10px;
    }
    .fleetStatusHeading.MuiTypography-body1 {
        padding-top: 15px;
        padding-bottom: 5px;
        font-size: 1.2rem;
        color: black;
        font-weight: bold;
    }
    .fleetStatusHeadingMessage.MuiTypography-body1 {
        margin-top: 5px;
        font-size: 1.2rem;
        color: black;
        font-weight: bold;
    }
    .fleetStatusGraphDiv{
        position:relative;
        width:90%;
        height:50px;
        margin-top:10px;
        margin-bottom:-16px;
        margin-left:8px;

    }
    .fleetStatusGreenDiv{
        background-color:rgb(3, 92, 3);
        height:30px;
        position: absolute;
        border-radius:50px;
        z-index:15;
        display:inline-block;
    }
    .fleetStatusYellowDiv{
        background-color:#FFEA00;
        height:30px;
        position: absolute;
        border-radius:50px;
        z-index:5;
        display:inline-block;
    }
    .fleetStatusRedDiv{
        background-color:red;
        height:30px;
        position: absolute;
        border-radius:50px;
        z-index:10;
        display:inline-block;
    }
    .iconChartSubDiv{
        margin-top: 18px;
        height:11%;
    }
    .iconChartIconLabel.MuiTypography-body1 {
        display: inline;
        color: black;
        margin-top: 8px;
        margin-left: 5px;
        font-size: 1.2rem;
    }
    .fleetStatusHeadingMessage.MuiTypography-body1 {
        margin-top: 8px;
        font-size: 1.2rem;
        color: black;
        font-weight: bold;
}    

@media screen and (min-width:1920px) and (max-width:2025px){
    .fleetStatusHeadingMessage.MuiTypography-body1 {
        margin-top: 8px;
        font-size: 1.2rem;
        color: black;
        font-weight: bold;
}
    @media screen and (min-width:1100px) and (max-width:1180px) {
        .MuiPaper-root.barGraphPaperMessage.MuiPaper-elevation3.MuiPaper-rounded {
            width: 70%;
            height: 211px;
            border-radius: 10px;
        }
    }
}
@media screen and (min-width:1100px) and (max-width:1190px){
    .MuiPaper-root.barGraphPaperMessage.MuiPaper-elevation3.MuiPaper-rounded {
        width: 70%;
        height: 206px !important;
        border-radius: 10px;
    }
}
@media screen and (min-width:1400px) and (max-width:2110px) {
    .MuiPaper-root.barGraphPaperMessage.MuiPaper-elevation3.MuiPaper-rounded {
        width: 70%;
        height: 206px;
        border-radius: 10px;
    }
}
@media screen and (min-width:1020px) and (max-width:1280px) {

    .MuiPaper-root.barGraphPaperMessage.MuiPaper-elevation3.MuiPaper-rounded {
        width: 70%;
        height: 13.5em;
        border-radius: 10px;
    }
}