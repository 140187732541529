.rolesPermissionsDashboard {
    padding-right: 60px !important;
    margin-left: 50px !important;
}
.rolePermissionMainBox{
    width: 100%;
    margin-left: 30px;
}
.MuiTypography-root.rolesPermissionHeading.MuiTypography-h5 {
    color: #336699;
    margin-top:70px;
    font-size:20px;
    margin-bottom: 50px;
    font-weight: bolder;
}
@media screen and (min-width:360px) and (max-width:900px){
    .rolePermissionMainBox{
        width: 100%;
        margin-left: 0px;
    }
}
@media screen and (min-width:960px) and (max-width:1200px){
    .rolePermissionMainBox {
        width: 95% !important;
        margin-left: 3%;
    }
    .rolesPermissionsGrid{
        width:120% !important;
    }
}
@media screen and (min-width:1390px) and (max-width:1925px){
    .MuiTypography-root.rolesPermissionHeading.MuiTypography-h5 {
        color: #336699;
        margin-top: 5%;
        font-size: 1.65rem;
        margin-bottom: 6%;
        font-weight: bolder;
    }
}
@media screen and (min-width:1920px) and (max-width:1925px){
    
}