.visualAnalyticsDashboard {
  padding-right: 60px !important;
  margin-left: 50px !important;
}
.visualAnalyticsMainBox{
  width: 100%;
  margin-left: 30px;
}
.MuiTypography-root.visualAnalyticsHeading.MuiTypography-h5 {
  color: #336699;
  margin-top: 45px;
  font-size: 22px;
  margin-bottom: 20px;
  font-weight: bolder;
  margin-left: -77px;
}
.react-share__ShareButton{
  display: inline-flex;
}
.containerLeft{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  border-radius: 15px !important;
  padding-bottom: 30px;
}

.visualContainers {
  display: flex !important;
  flex-direction: row !important;
  justify-content: start;
  grid-gap: 20px;
  gap: 20px;
  margin-top: 15px !important;
}
.gamificationContainer{
  width:500px;
  margin-left:-90px;
  margin-top:32px;
}
.visualAnalyticsRight{
  flex: 0.8;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  margin-left:-90px;
  margin-top:22px;
}
.gridstatsm{
  display: flex;
  justify-content: center;
  margin-right: -35px !important;
}
.tabGridBox {
  position: relative;
  padding-top: 20px;
  margin-left: 75px;
}
.visualAnalyticsPaper {
height: 500px;
border-radius: 20px !important;
margin-left: -175px;
width: 100%;
}
.gridCO2sm{
display: flex;
justify-content: center;
width: 100% !important;
margin-right: -35px !important;
max-width: 100% !important;
margin-top: -55px !important;
margin-left: -60px !important;
}
.cO2TabPaperOn {
  height: 70px !important;
  margin-top: -28% !important;
  margin-left: -67px !important;
  width: 103%;
  display: inline-flex;
  border-radius: 18px !important;
}

.visualAnalyticscO2Paper {
height: 500px;
  border-radius: 20px !important;
  margin-left: 0;
  width: 114%;
  margin-top: 20;
}
.MuiTypography-root.yourGPALabel.MuiTypography-body1{
width: 100%;
margin-left: -140px;	
}
.MuiTypography-root.yourCO2Label.MuiTypography-body1{
width: 100%;
margin-left: -140px;	
}
.MuiTypography-root.benchmarkLabel.MuiTypography-body1{
margin-left:-140px;
}
.labelsforVA{
    width: 55%;
  margin-left: -40px;
}
.blueLabelVA{
width:10%;
}
.greenLabelCO2{
width:10px;
height:10px;
border-radius: 50%;
background-color: #43a858;
}
.orangeLabelVA{
width:10%;
}
.MuiTypography-root.visualAnalyticsHeading.MuiTypography-h5 {
color: #336699;
margin-top: 28px;
font-size: 22px;
margin-bottom: 20px;
font-weight: bolder;
margin-left: -77px;
}

.gamificationContainer {
width: 100%;
margin-left: -93px;
margin-top: -8px;
}

.cO2Tab{
display:inline-flex;
}
.toggleCO2Image{
width:4%;
}
.cO2TabPaper {
height: 70px;
margin-top: -17px;
margin-left: -65px;
width: 103%;
display: inline-flex;
border-radius: 18px !important;
}
.cO2TabInnerBox {
display: inline-flex;
margin: 15px;
margin-left: 15px;
background: #ddd9d9;
border-radius: 10px;
height: 40px;
width: 105%;
margin-left: 25px;
}

.toggleCO2Image {
  width: 10%;
  height: 50%;
  margin-left: 20px;
  margin-top: 10px;
}
.MuiTypography-root.toggleCO2Text.MuiTypography-body1
{
 margin-top: 10px;
margin-left: 10px;
font-size: 13px;
width: 50%;
font-weight: bolder;
}
.cO2TabOff{
margin-left: 220px;
}
.cO2TabOn{
margin-left: 220px;
}
.MuiTypography-root.badgeGamificationHeading.MuiTypography-h6{
padding-top: 30px;
color: #369;
font-weight: bolder;	
}
.MuiSwitch-root.cO2TabOff span.MuiSwitch-track {
width: 100%;
height: 126% !important;
opacity: 0.38;
z-index: -1;
transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
border-radius: 10px;
background-color: #797575 !important;
}
.MuiSwitch-root.cO2TabOff span.MuiSwitch-thumb {
width: 20px;
height: 20px;
box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
border-radius: 50%;
background-color: currentColor;
margin-top: 1px;
}
.MuiSwitch-root.cO2TabOn span.MuiSwitch-track {
width: 100%;
height: 126%;
opacity: 0.38;
z-index: -1;
transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
border-radius: 10px;
background-color: #000;
}
.MuiSwitch-root.cO2TabOn span.MuiSwitch-colorPrimary.Mui-checked {
color: #e4e4e8;
}
.MuiSwitch-root.cO2TabOn span.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
background-color: #43a858;
}
.MuiSwitch-root.cO2TabOn .MuiSwitch-thumb {
width: 20px;
height: 20px;
box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
border-radius: 50%;
background-color: currentColor;
margin-top: 1px;
}
.textFieldContainer{
  width:500px;
  margin-left:-90px;
  margin-top:32px;
}

.shareButtonVA{
  background-color: #369 !important;
  border-radius: 10px !important;
  width: 70%;
  color: white !important;
  font-size: 10px !important;
  margin-top: 30px !important;
  height: 50px;
  }
  .shareIconVA{
    width: 5%;
    margin-right: 12px;
  }
  .MuiTypography-root.shareTextVA.MuiTypography-h6{
    font-size:14px;
    text-transform:none !important;
    font-weight:bolder;
  }
.tabVABoxStat {
  margin-left: 130px !important;
  padding-top: 40px !important;
}
.cO2TabVABox {
  margin-left: 130px  !important;
  padding-top: 40px !important;
}

.activeVAPageTab {
  min-width: 20px !important;
  min-height: 20px !important;
  background-color: #369 !important;
  color: white !important;
  border-radius: 30px !important;
  text-transform: none !important;
  width: 23% !important;
  height: 5% !important;
  display: inline-block;
  font-size: 10px !important;
  opacity: 1.0 !important;
  margin-right: 5px !important;
 }
.inactiveVAPageTab{
  min-width: 20px !important;
  min-height: 20px !important;
  background-color: #cac5c5 !important;
  color: #4b4949 !important;
  border-radius: 30px !important;
  text-transform: none !important;
  width: 23% !important;
  height: 5% !important;
  display: inline-block;
  font-size: 10px !important;
  opacity: 1.0 !important;
  margin-right: 5px !important;
}
.activeCO2PageTab {
  min-width: 20px !important;
  min-height: 20px !important;
  background-color: #369 !important;
  color: white !important;
  border-radius: 30px !important;
  text-transform: none !important;
  width: 23% !important;
  height: 5% !important;
  display: inline-block;
  font-size: 10px !important;
  opacity: 1.0 !important;
  margin-right: 5px !important;
 }
.inactiveCO2PageTab{
  min-width: 20px !important;
  min-height: 20px !important;
  background-color: #cac5c5 !important;
  color: #4b4949 !important;
  border-radius: 30px !important;
  text-transform: none !important;
  width: 23% !important;
  height: 5% !important;
  display: inline-block;
  font-size: 10px !important;
  opacity: 1.0 !important;
  margin-right: 5px !important;
}

.orangeLabelCO2{

width: 8%;
height: 0%;
margin-right: 8px;
margin-left: -20px;
margin-top: 5px;

}
.label2CO2{
display: inline-flex;
margin-left: 31px;
}
.MuiTypography-root.yourCO2Label.MuiTypography-body1 {
width: 100%;
margin-left: 7px;
font-size: 14px;
}
.greenLabelCO2 {
width: 16px;
height: 14px;
border-radius: 50%;
background-color: #43a858;
margin-left: -20px;
margin-top: 4px;
}
.label1CO2{    
display: inline-flex;
width: 120%;
}
.labelsforCO2{
margin-left: 100px;
}
.MuiTypography-root.gpaBarValueVA.MuiTypography-h6{
margin-left: -283px;
  font-size: 10px;
  z-index: 3;
  position: absolute;
  margin-top: 15px;
}
.shareMessageCO2{
position: absolute;
top: 55%;
left: 86%;
transform: translate(-50%,-50%);
width: 250px;
background-color: white;
border-radius: 20px;
height: 190px;
}
.shareMessageGPA{
position: absolute;
top: 55%;
left: 48%;
transform: translate(-50%,-50%);
width: 250px;
background-color: white;
border-radius: 20px;
height: 190px;
}
.MuiTypography-root.shareWithLabel.MuiTypography-body1{
margin-left: 30px;
margin-top: 20px;
color: #036;
font-weight: bolder;
}
.MuiTypography-root.twitterLabel.MuiTypography-body1{
margin-left: 10px;
margin-top: 5px;
}
.dividerHR{
margin-top: 4px;
padding: 0;
width: 72%;
color: #d3d3d3a3;
margin-bottom: 0px !important;
}
.MuiTypography-root.facebookLabel.MuiTypography-body1{
margin-left: 10px;
margin-top: 5px;
}
.MuiTypography-root.instagramLabel.MuiTypography-body1{
margin-left: 10px;
margin-top: 5px;
}
.facebookBox{
display: inline-flex;
margin-left: 40px;
margin-top: 5px;
}
.instagramBox{
display: inline-flex;
margin-left: 40px;
margin-top: 5px;
}
.twitterBox{
display: inline-flex;
margin-left: 40px;
margin-top: 5px;
}
.MuiTypography-root.cO2BarValueVA.MuiTypography-h6{
margin-left: -283px;
font-size: 10px;
z-index: 3;
position: absolute;
margin-top: 15px;
}
.cO2VA{
position: relative;
}
.gpaVA{
position: relative;
}
.gpaChartVAContainer{
width:420px;
height:220px;
}
.cO2ChartVAContainer{
width:420px;
height:220px;
}

.barChart g rect {
  ry: 8px;
  width: 15px;
}
.barDaysChart g rect {
  ry: 8px;
  width: 8px;
}
@media screen and (min-width:360px) and (max-width:900px){
  .visualAnalyticsMainBox{
      width: 100%;
      margin-left: 0px;
  }
  .gpaChartVAContainer{
    width:auto;
    height:auto;
  }
  .cO2ChartVAContainer{
    width:auto;
    height:auto;
  }  
  .visualAnalyticsPaper {
    height: 500px;
    border-radius: 20px !important;
    margin-left: -66px;
    width: 130%;
}
.activeVAPageTab {
    min-width: 20px !important;
    min-height: 20px !important;
    background-color: #369 !important;
    color: white !important;
    border-radius: 30px !important;
    text-transform: none !important;
    width: 30% !important;
    height: 5% !important;
    display: inline-block;
    font-size: 10px !important;
    opacity: 1.0 !important;
    margin-right: 5px !important;
}
.inactiveVAPageTab {
    min-width: 20px !important;
    min-height: 20px !important;
    background-color: #cac5c5 !important;
    color: #4b4949 !important;
    border-radius: 30px !important;
    text-transform: none !important;
    width: 30% !important;
    height: 5% !important;
    display: inline-block;
    font-size: 10px !important;
    opacity: 1.0 !important;
    margin-right: 5px !important;
}
.labelsforVA {
    width: 76%;
    margin-left: -17px;
}
.shareButtonVA {
    background-color: #369 !important;
    border-radius: 10px !important;
    width: 80%;
    color: white !important;
    font-size: 10px !important;
    margin-top: 30px !important;
    height: 50px;
}
.cO2TabPaper {
    height: 70px;
    margin-top: -29px;
    margin-left: -71px;
    width: 130%;
    display: inline-flex;
    border-radius: 18px !important;
}
.cO2TabInnerBox {
    display: inline-flex;
    margin: 15px;
        margin-left: 15px;
    margin-left: 15px;
    background: #ddd9d9;
    border-radius: 10px;
    height: 40px;
    width: 88%;
    margin-left: 23px;
}
.cO2TabOff {
    margin-left: 50px;
}
.cO2TabPaperOn {
  height: 70px;
  margin-top: -30px;
  margin-left: -65px;
  width: 130%;
  display: inline-flex;
  border-radius: 18px !important;
} 
.cO2TabOn {
margin-left: 50px;
}
.gridCO2sm {
  display: flex;
  justify-content: center;
  width: 100% !important;
  margin-right: -40px !important;
  max-width: 100% !important;
  margin-top: 40px !important;
  margin-left: -295px !important;
}

.visualAnalyticscO2Paper {
  height: 500px;
  border-radius: 20px !important;
  margin-left: 30px !important;
  width: 130%;
  margin-top: 20px;
}
.cO2TabVABox {
    margin-left: 20px !important;
    padding-top: 40px !important;
}
.activeCO2PageTab {
    min-width: 20px !important;
    min-height: 20px !important;
    background-color: #369 !important;
    color: white !important;
    border-radius: 30px !important;
    text-transform: none !important;
    width: 30% !important;
    height: 5% !important;
    display: inline-block;
    font-size: 10px !important;
    opacity: 1.0 !important;
    margin-right: 5px !important;
}
.inactiveCO2PageTab {
    min-width: 20px !important;
    min-height: 20px !important;
    background-color: #cac5c5 !important;
    color: #4b4949 !important;
    border-radius: 30px !important;
    text-transform: none !important;
    width: 30% !important;
    height: 5% !important;
    display: inline-block;
    font-size: 10px !important;
    opacity: 1.0 !important;
    margin-right: 5px !important;
}
.orangeLabelCO2 {
    width: 11%;
    height: 0%;
    margin-right: 8px;
    margin-left: -20px;
    margin-top: 5px;
}
.label1CO2 {
    display: inline-flex;
    width: 185%;
}
.labelsforCO2 {
    margin-left: 40px;
}
.shareMessageGPA {
  position: absolute;
  top: 50%;
  left: 48%;
  transform: translate(-50%,-50%);
  width: 250px;
  background-color: white;
  border-radius: 20px;
  height: 190px;
}
.shareMessageCO2 {
  position: absolute;
  top: 33%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 250px;
  background-color: white;
  border-radius: 20px;
  height: 190px;
}
}
@media screen and (min-width:1280px) and (max-width:1350px){
  .cO2TabOn {
     margin-left: 186px;;
}
.MuiTypography-root.toggleCO2Text.MuiTypography-body1 {
    margin-top: 11px;
    margin-left: 10px;
    font-size: 13px;
    width: 37%;
    font-weight: bolder;
    max-width: 100%;
}
.cO2TabOff {
  margin-left: 189px;
}
}
@media screen and (min-width:1390px) and (max-width:2100px){
  .gamificationContainer {
    width: 100%;
    margin-left: -24%;
    margin-top: -8%;
}
  .visualAnalyticsDashboard {
    padding-right: 10% !important;
    margin-left: 5% !important;
    width: 100% !important;
    max-width: 100% !important;
    margin-top: 4% !important;
}
.visualAnalyticsMainBox {
    width: 100%;
    margin-left: 3%;
    max-width: 100%;
}
.MuiTypography-root.visualAnalyticsHeading.MuiTypography-h5 {
    color: #336699;
    margin-top: 28px;
    font-size: 1.6rem;
    margin-bottom: -1%;
    font-weight: bolder;
    margin-left: -4%;
    margin-top: 3% !important;
}
.visualContainers {
    display: flex !important;
    flex-direction: row !important;
    justify-content: start;
    grid-gap: 20px;
    gap: 20px;
    margin-top: 3% !important;
}
.tabVABoxStat {
  margin-left: 18% !important;
  padding-top: 7% !important;
  width: 100%;
}
.activeVAPageTab {
  min-width: 20px !important;
  min-height: 20px !important;
  background-color: #369 !important;
  color: white !important;
  border-radius: 30px !important;
  text-transform: none !important;
  display: inline-block;
  font-size: 0.80rem !important;
  opacity: 1.0 !important;
  margin-right: 5px !important;
  width: 24% !important;
  height: 6% !important;
  font-weight: bolder !important;
}
.inactiveVAPageTab {
  min-width: 20px !important;
  min-height: 20px !important;
  background-color: #cac5c5 !important;
  color: #4b4949 !important;
  border-radius: 30px !important;
  text-transform: none !important;
  width: 24% !important;
  height: 6% !important;
  display: inline-block;
  font-size: 0.80rem !important;
  opacity: 1.0 !important;
  margin-right: 1% !important;
}
.MuiTypography-root.gpaBarValueVA.MuiTypography-h6 {
  margin-left: -80%;
  font-size: 0.75rem !important;
  z-index: 3;
  position: absolute;
  margin-top: 6%;
}
.gpaChartVAContainer {
  width: 100%;
  height: 60%;
  margin-top: 4%;
  margin-bottom: 0%;
}
.labelsforVA {
  width: 52%;
  margin-left: -2%;
}
.MuiTypography-root.yourGPALabel.MuiTypography-body1 {
  width: 100%;
  margin-left: -68%;
  font-size: 0.95rem;
}
.MuiTypography-root.benchmarkLabel.MuiTypography-body1 {
  margin-left: -65%;
  font-size: 0.95rem;
}
.shareButtonVA {
  background-color: #369 !important;
  border-radius: 10px !important;
  width: 70%;
  color: white !important;
  font-size: 1.2rem !important;
  margin-top: 11% !important;
  height: 50px;
}
.cO2TabPaper {
  height: 60px !important;
  margin-top: -4%;
  margin-left: -6%;
  width: 100%;
  display: inline-flex;
  border-radius: 18px !important;
}
.cO2TabInnerBox {
  display: inline-flex;
  margin: 2%;
  background: #ddd9d9;
  border-radius: 10px;
  width: 100% !important;
  margin-left: 6%;
  margin-top: 2%;
}
.MuiTypography-root.toggleCO2Text.MuiTypography-body1 {
  margin-top: 2.9%;
  margin-left: 1.5%;
  font-size: 0.75rem;
  width: 100%;
  font-weight: bolder;
}
.toggleCO2Image {
  width: 10% !important;
  height: 41% !important;
  margin-left: 5% !important;
  margin-top: 3% !important;
}
.cO2TabPaperOn {
  height: 60px !important;
  margin-top: -25% !important;
  margin-left: -8% !important;
  width: 100%;
  display: inline-flex;
  border-radius: 18px !important;
}
.cO2TabOff {
  width: 15%;
  margin-top: 0%;
  margin-left: 40%;
}
.visualAnalyticsPaper {
  height: 510px !important;
  border-radius: 20px !important;
  margin-left: -24% !important;
  width: 100% !important;
}
.gridCO2sm {
  display: flex;
  justify-content: center;
  width: 100% !important;
  margin-right: 0px !important;
  max-width: 100% !important;
  margin-top: -11% !important;
  margin-left: -7% !important;
}


.visualAnalyticscO2Paper {
  height: 505px;
  border-radius: 20px !important;
  margin-left: 0;
  width: 100%;
}
.activeCO2PageTab {
  min-width: 20px !important;
  min-height: 20px !important;
  background-color: #369 !important;
  color: white !important;
  border-radius: 30px !important;
  text-transform: none !important;
  width: 24% !important;
  height: 6% !important;
  display: inline-block;
  font-size: 0.85rem !important;
  opacity: 1.0 !important;
  margin-right: 1% !important;
  font-weight: bolder !important;
}
.inactiveCO2PageTab {
  min-width: 20px !important;
  min-height: 20px !important;
  background-color: #cac5c5 !important;
  color: #4b4949 !important;
  border-radius: 30px !important;
  text-transform: none !important;
  width: 24% !important;
  height: 6% !important;
  display: inline-block;
  font-size: 0.85rem !important;
  opacity: 1.0 !important;
  margin-right: 1% !important;
}
.cO2TabVABox {
  margin-left: 21% !important;
  padding-top: 6% !important;
  width: 94% !important;
}
.MuiTypography-root.cO2BarValueVA.MuiTypography-h6 {
  margin-left: -81%;
  font-size: 0.75rem;
  z-index: 3;
  position: absolute;
  margin-top: 5%;
}
.greenLabelCO2 {
  width: 17px;
  height: 16px;
  border-radius: 50%;
  background-color: #43a858;
  margin-left: -20px;
  margin-top: 4px;
}
.cO2VA {
    position: relative;
    margin-top: 1% !important;
}
.cO2ChartVAContainer {
  width: 100%;
  height: 60%;
  margin-top: 4% !important;
}
.labelsforCO2 {
  margin-left: 20% !important;
  width: 70% !important;
}
.MuiTypography-root.yourCO2Label.MuiTypography-body1 {
  width: 100%;
  margin-left: 7px;
  font-size: 0.95rem;
}
.orangeLabelCO2 {
  width: 10%;
  height: 0%;
  margin-right: 3%;
  margin-left: -5%;
  margin-top: 1%;
}
.MuiTypography-root.benchmarkCO2Label.MuiTypography-body1 {
  font-size: 0.95rem;
}

}
@media screen and (min-width:960px) and (max-width:1050px){
  .cO2Tab {
    display: block;
    margin-left: -5% !important;
}
.cO2TabPaper {
    height: 70px;
    margin-top: -4% !important;
    margin-left: -10% !important;
    width: 100%;
    display: inline-flex;
    border-radius: 18px !important;
}
.cO2TabPaperOn {
    height: 70px !important;
    margin-top: -5% !important;
    margin-left: -10% !important;
    width: 100%;
    display: inline-flex;
    border-radius: 18px !important;
}
.gridCO2sm {
    display: flex;
    justify-content: center;
    width: 100% !important;
    max-width: 100% !important;
    margin-top: -3% !important;
    margin-left: -10% !important;
}
.gamificationContainer {
    width: 100%;
    margin-left: -16%;
    margin-top: 0%;
}
.gamificationContainer {
    height: 200%;
}
.gridstatsm {
  display: flex;
  justify-content: center;
  margin-right: -35px !important;
  width: 100% !important;
}
.tabVABoxStat {
  margin-left: 21% !important;
  padding-top: 9% !important;
  width: 100% !important;
}
.activeVAPageTab {
  min-width: 30px !important;
  min-height: 30px !important;
  background-color: #369 !important;
  color: white !important;
  border-radius: 30px !important;
  text-transform: none !important;
  width: 23% !important;
  height: 5% !important;
  display: inline-block;
  font-size: 0.80rem !important;
  opacity: 1.0 !important;
  margin-right: 5px !important;
  font-weight: bolder !important;
}
.inactiveVAPageTab {
  min-width: 30px !important;
  min-height: 30px !important;
  background-color: #cac5c5 !important;
  color: #4b4949 !important;
  border-radius: 30px !important;
  text-transform: none !important;
  width: 22% !important;
  height: 5% !important;
  display: inline-block;
  font-size: 0.75rem !important;
  opacity: 1.0 !important;
  margin-right: 5px !important;
}
.MuiTypography-root.gpaBarValueVA.MuiTypography-h6 {
  margin-left: -67%;
  font-size: 0.65rem;
  z-index: 3;
  position: absolute;
  margin-top: 1%;
}
.gpaVA {
  position: relative;
  margin-top: 6%;
}
.gpaChartVAContainer {
  width: 420px;
  height: 220px;
  margin-left: -11% !important;
}
.cO2TabVABox {
  margin-left: 21% !important;
  padding-top: 9% !important;
  width: 100% !important;
}
.activeCO2PageTab{
   min-width: 30px !important;
  min-height: 30px !important;
  background-color: #369 !important;
  color: white !important;
  border-radius: 30px !important;
  text-transform: none !important;
  width: 23% !important;
  height: 5% !important;
  display: inline-block;
  font-size: 0.80rem !important;
  opacity: 1.0 !important;
  margin-right: 5px !important;
  font-weight: bolder !important;
}
.inactiveCO2PageTab{
    min-width: 30px !important;
  min-height: 30px !important;
  background-color: #cac5c5 !important;
  color: #4b4949 !important;
  border-radius: 30px !important;
  text-transform: none !important;
  width: 22% !important;
  height: 5% !important;
  display: inline-block;
  font-size: 0.75rem !important;
  opacity: 1.0 !important;
  margin-right: 5px !important;
}
.MuiTypography-root.cO2BarValueVA.MuiTypography-h6 {
   margin-left: -67%;
  font-size: 0.65rem;
  z-index: 3;
  position: absolute;
  margin-top: 1%;

}
.cO2ChartVAContainer {
  width: 420px;
  height: 220px;
  margin-left: -11% !important;
}
.cO2VA {
  position: relative;
  margin-top: 6%
}
.visualAnalyticscO2Paper {
  height: 520px;
  border-radius: 20px !important;
  margin-left: 0;
  width: 114%;
}
}
@media screen and (min-width:1050px) and (max-width:1200px){
  .gridstatsm {
    display: flex;
    justify-content: center;
    margin-right: -35px !important;
    width: 50% !important;
    max-width: 50% !important;
}
.tabVABoxStat {
    margin-left: 18% !important;
    padding-top: 8% !important;
    width: 104%;
}
.MuiTypography-root.gpaBarValueVA.MuiTypography-h6 {
    margin-left: -86%;
    font-size: 0.65rem;
    z-index: 3;
    position: absolute;
    margin-top: 10%;
}
.gpaChartVAContainer {
    width: 420px;
    height: 231px;
    margin-left: 6%;
    margin-top: 7%;
}
.labelsforVA {
    width: 66%;
    margin-left: -4%;
}
.MuiTypography-root.yourGPALabel.MuiTypography-body1 {
    width: 100%;
    margin-left: -147px;
}
.shareButtonVA {
    background-color: #369 !important;
    border-radius: 10px !important;
    width: 76%;
    color: white !important;
    font-size: 10px !important;
    margin-top: 30px !important;
    height: 50px;
    margin-left: -2% !important;
}
.cO2Tab {
  display: block;
  margin-left: 0% !important;
  width: 50% !important;
  max-width: 50% !important;
}
.cO2TabOff {
    margin-left: 69px;
}
.MuiTypography-root.toggleCO2Text.MuiTypography-body1 {
    margin-top: 12px;
    margin-left: 5px;
    font-size: 0.75rem;
    width: 420px;
    font-weight: bolder;
}
.toggleCO2Image {
    width: 12%;
    height: 40%;
    margin-left: 17px;
    margin-top: 13px;
}
.cO2TabInnerBox {
    display: inline-flex;
    margin: 15px;
    background: #ddd9d9;
    border-radius: 10px;
    height: 40px;
    width: 89%;
    margin-left: 25px;
}
.cO2TabPaper {
  height: 70px;
  margin-top: -17px;
  margin-left: -65px;
  width: 100%;
  display: inline-flex;
  border-radius: 18px !important;
}

.cO2TabPaperOn {
  height: 64px !important;
  margin-top: -32% !important;
  margin-left: -20% !important;
  width: 102%;
  display: inline-flex;
  border-radius: 18px !important;
}
.cO2TabInnerBox {
  display: inline-flex;
  margin: 15px;
  margin-left: 15px;
  background: #ddd9d9;
  border-radius: 10px;
  height: 40px;
  width: 89%;
  margin-left: 25px;
  margin-top: 13px;
}
.cO2TabOn {
  margin-left: 83px;
}
.cO2ToggleGrid{
  width: 102%;
}
.gridCO2sm {
  display: flex;
  justify-content: center;
  width: 93% !important;
  margin-right: 0px !important;
  max-width: 100% !important;
  margin-top: -55px !important;
  margin-left: -13% !important;
}
.cO2TabVABox {
  margin-left: 21% !important;
  padding-top: 8% !important;
  width: 99%;
}
.cO2ChartVAContainer {
  width: 420px;
  height: 220px;
  margin-left: 10%;
  margin-top: 9%;
}
.MuiTypography-root.cO2BarValueVA.MuiTypography-h6 {
  margin-left: -81%;
  font-size: 0.65rem;
  z-index: 3;
  position: absolute;
  margin-top: 12%;
}
.labelsforCO2 {
  margin-left: 100px;
  width: 90%;
  margin-top: 0%;
}

.visualAnalyticsPaper {
  height: 505px;
  border-radius: 20px !important;
  margin-left: -175px;
  width: 100%;
}
.visualAnalyticscO2Paper {
  height: 505px;
  border-radius: 20px !important;
  margin-left: 0;
  width: 114%;
  margin-top: 20;
}
}

@media screen and (min-width:1920px) and (max-width:2100px){
  .visualAnalyticsPaper {
    height: 530px !important;
    border-radius: 20px !important;
    margin-left: -37% !important;
    width: 80% !important;
}
.gridstatsm {
    display: flex;
    justify-content: center;
    margin-right: -11% !important;
}
.cO2TabInnerBox {
    display: inline-flex;
    margin: 2%;
        margin-top: 2%;
        margin-left: 2%;
    background: #ddd9d9;
    border-radius: 10px;
    width: 100% !important;
    margin-left: 3%;
    margin-top: 1.5%;
}
.cO2TabPaper {
    height: 60px !important;
    margin-top: -2.5%;
    margin-left: -6%;
    width: 82%;
    display: inline-flex;
    border-radius: 18px !important;
}
.MuiTypography-root.toggleCO2Text.MuiTypography-body1 {
    margin-top: 2.4%;
    margin-left: 1.5%;
    font-size: 0.75rem;
    width: 100%;
    font-weight: bolder;
}
.toggleCO2Image {
    width: 10% !important;
    height: 41% !important;
    margin-left: 5% !important;
    margin-top: 2.5% !important;
}
.cO2TabPaperOn {
    height: 60px !important;
    margin-top: -19% !important;
    margin-left: -8% !important;
    width: 84%;
    display: inline-flex;
    border-radius: 18px !important;
}
.gridCO2sm {
    display: flex;
    justify-content: center;
    width: 83% !important;
    margin-right: 0px !important;
    max-width: 83% !important;
    margin-top: -7.5% !important;
    margin-left: -7% !important;
}
.visualAnalyticsRight {
    flex: 0.8 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-items: center;
    justify-content: center;
    height: 100%;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    margin-left: 0% !important;
    margin-top: 2% !important;
    width: 40% !important;
}


.visualAnalyticscO2Paper {
  height: 529px;
  border-radius: 20px !important;
  margin-left: 0;
  width: 100%;
}
}