.root {
    padding-right: 40px;
}
.selectINput:after{
    position: absolute;
    top: 10px;
    background-color: pink;
    right: 10px
}
.wrapper{
    position:relative;
    width:100%;
}

.arrow{
    position:relative;
    margin-left: 93%;
    margin-top:10px;
    color:gray;
    
}
.errorIcon{
    color:red;
    width:20px;
}
.errorIconButton {
    margin-left: 63% !important;
    margin-bottom: -3% !important;
    margin-top: -4% !important;
}
.radioContainer1 {
    display: flex;
    margin-left: 40px;
}
.radioButtons{
    margin-bottom: 40px; 
    margin-right: 15px;
}
.deleteMessageBox{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    width: 450px;
    background-color: white;
    border-radius: 5px;
    height: auto;
}
.closeDeleteMessageIcon{
    width: 8%;
    position: absolute;
    margin-left: 90%;
    margin-top: 2%;
    cursor:pointer;
}
.deleteMessageIcon{
    margin-left: 32%;
    margin-top: 6%;
    width: 30%;
}
.deleteMessageBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 450px;
    background-color: white;
    border-radius: 20px;
    height: 360px;
}
.feedBackBox{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 450px;
    background-color: white;
    border-radius: 20px;
    height: 480px;
}
.MuiTypography-root.feedbackTitleInfo.MuiTypography-h6{
    width: 66%;
    text-align: center;
    margin-left: 2%;
    margin-top: 6%;
    font-weight: bolder;
    color: #006633;
    font-size: 0.95rem;
}
.downloadIconButton{
    width: 4%;
    margin-right: 1%;
}
.downloadMessageIcon {
    margin-left: 34%;
    margin-top: 12%;
    width: 30%;
}
.MuiTypography-root.downloadMessageTitleInfo.MuiTypography-h6 {
    width: 66%;
    text-align: center;
    margin-left: 17%;
    margin-top: 4%;
    font-weight: bolder;
    color: rgba(0,0,0,0.7);
    font-size: 1.1rem;
}
label.MuiFormControlLabel-root.radioGroupFormLabel span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1{
	font-size:0.85rem;
}
.radioFeedbacks{
	margin-left:7%;
}
.MuiTypography-root.otherTypography.MuiTypography-body1{
	margin-left: 7%;
	font-size: 0.85rem;
	display: inline-flex;
}
.MuiFormControl-root.MuiTextField-root.addOtherInput{
	margin-left: 20px;
	margin-top: -16px;
	font-size: 0.85rem;
}
.gridContainerButtons{
    margin-left: 3%;
    margin-top: 8%; 
}
.MuiTypography-root.deleteMessageTitleInfo.MuiTypography-h6{
    width: 66%;
    text-align: center;
    margin-left: 17%;
    margin-top: 2%;
    font-weight: bolder;
    color: rgba(0,0,0,0.7);
    font-size: 1.1rem;
}
.MuiTypography-root.deleteMessageInfo.MuiTypography-body2{
    width: 64%;
    text-align: center;
    margin-left: 18%;
    margin-top: 2%;
    margin-bottom: 4%;
}
.deleteYesButton{
    border-radius: 1.3rem;
    border: 1px solid rgb(51, 102, 153);
    font-weight: bolder;
    text-transform: none;
    width: 32%;
    margin-left: 34%;
    color: rgb(51,102,153);
}
.MuiTypography-root.cancelOption.MuiTypography-body1{
    color: rgb(28, 81, 134);
    margin-left: 44%;
    margin-top: 3%;
    cursor:pointer;
}
@media screen and (max-width:600px){
    .arrow{
        position:relative;
        margin-left: 87%;
        margin-top:10px;
        color:gray
    }   
    .radioContainer1 {
        display: flex;
        margin-left: 15px;
    }
    .radioButtons{
        margin-bottom: 70px; 
        margin-left: 5px;
        padding: 0px !important;
        
    }
    .root{
        padding-right: 10px;
    }
}

@media screen and (min-width:600px) and (max-width:780px){
    .arrow{
        position:relative;
        margin-left: 87%;
        margin-top:10px;
        color:gray
    }   
    .radioContainer1 {
        display: flex;
        margin-left: 15px;
    }
    .radioButtons{
        margin-bottom: 70px; 
        margin-left: 5px;
        padding: 0px !important;
        
    }
    
}


@media screen and (min-width:300) and (max-width:475px){
    .arrow{
        position:relative;
        margin-left: 87%;
        margin-top:10px;
        color:gray
    }   
    .radioContainer1 {
        display: flex;
        margin-left: 15px;
    }
    .radioButtons{
        margin-bottom: 70px; 
        margin-left: 5px;
        padding:0px;
    }
    .root{
        padding-right: 10px;
    }
}

@media screen and (min-width:1170px) and (max-width:1280px){
    .errorIconButton {
        margin-left: 25% !important;
        margin-bottom: -3% !important;
        margin-top: -4% !important;
        display: inline-flex !important;
    }
}
@media screen and (min-width:1390px) and (max-width:1910px){
    .errorIconButton {
        margin-left: 42% !important;
        margin-bottom: -3% !important;
        margin-top: -4% !important;
    }
}
@media screen and (min-width:1920px) and (max-width:1940px){
    .errorIconButton {
        margin-left: 60% !important;
        margin-bottom: -3% !important;
        margin-top: -4% !important;
    }
}

