
.OnboardingTwoContainer{
    margin: 0px !important;
    padding: 0px !important;
    background-color: #cbccce;
    width: 100% !important;
    max-width: 100% !important;
}
.logoGridTwo{
    position: relative;
}
.circleTopLeftImgTwo {
    margin-left: -14%;
    margin-top: -17% !important;
    width: 80%;
    z-index: 1;
    position: absolute;
}
.windEverestLogoTwo {
    margin-left: 14%;
    margin-top: 8%;
    width: 30%;
    z-index: 2;
    position: absolute;
}
.onBoardingTwoInfoTwo {
    display: flex;
    margin-top: 33% !important;
    height: 60px;
}
.infoLowerGrid{
    display: flex;
    margin-top: -70px !important;
    height: 150px;
    margin-left: 380px !important;
    width: 40%;
}
.onBTwoArrowIconTwo {
    height: 17px;
    margin-top: 127px;
    margin-left: 73px;
}
.MuiTypography-root.onBTwoArrowInfo.MuiTypography-body1 {
    color: #369;
    font-size: 15px;
    font-weight: bolder;
    width: 79%;
    margin-left: 71px;
    margin-top: 235px;
    text-align: justify;
}

.rightCircleIconTwo{
    width:30%;
    margin-left:240px;
}
.laptopImageTwo {
	z-index: 2;
	width: 160%;
	position: absolute;
	top: 47%;
	left: 50%;
	transform: translate(-53%,-42%);
}
.circleBottomLeftTwo {
    width: 36%;
   margin-top: 76%;
   margin-left: 52%;
   }
   .greenTopRightCircleTwo {
    z-index: 1;
    width: 15%;
    margin-top: 16%;
    margin-left: 79%;
    position: absolute;
}
.popupBatteryPanelGridTwo {
    width: 110%;
    margin-top: 98%;
    margin-left: 0%;
    z-index: 2;
    position: absolute;
    height: 20%;
}
.popupBatteryGridTwo {
    position: relative;
    margin-left: -11% !important;
    margin-top: -7% !important;
    width: 80% !important;
}
.laptopGridTwo{
    position: relative;
    margin-left: 36px !important;
}
.batteryInfoIconTwo {
    width: 7%;
    margin-top: 102%;
    margin-left: 99%;
    z-index: 4;
    position: absolute;
}
.batteryMeterIconTwo {
        width: 102%;
        z-index: 4;
        position: absolute;
        margin-top: 114%;
        margin-left: 4%;
}
.MuiTypography-root.batteryIconHeadingTwo.MuiTypography-h6 {
    width: 90%;
    z-index: 4;
    position: absolute;
    margin-top: 106%;
    color: white;
    margin-left: 20%;
    font-size: 17px;
    font-weight: bolder;
}
.batteryMeterTwo {
    width: 116% !important;
    z-index: 4;
    position: absolute;
    margin-top: 132%;
    margin-left: 0%;
}
.batteryMeterIconLabel{
    margin-left:12px;
    font-size:10px;
    color:white;
    width:5%;
    display: inline;
}
.batteryMeterIconLabel1{
    margin-left: 4.5%;
    font-size: 10px;
    color: white;
    width: 5%;
    display: inline;
}
.batteryIconTwo {
    z-index: 4;
    position: absolute;
    margin-top: 105%;
    margin-left: 6%;
    width: 10%;
}
.onBTwoArrowLowerIcon {
    height: 21px;
    margin-left: -22%;
    margin-top: -280%;
}
.MuiTypography-root.onBTwoArrowSecondInfoTwo.MuiTypography-body1 {
    color: #369;
    font-size: 17px;
    margin-top: -24%;
    margin-left: 6%;
    text-align: justify;
}
.onBoardingTwoLowerInfoGridTwo {
    margin-left: -15%;
    width: 19% !important;
    margin-top: 37%;
}
.footerOnBoardingEvenTwo {
    background-color: #369;
    height: 12%;
    margin-top: 8%;
}
.MuiTypography-root.typographyTACEven.MuiTypography-body1{
    margin: 50px;
    color: white;
    text-decoration: underline;
    margin-left: 160px;
}
.MuiTypography-root.typographyPolicyEven.MuiTypography-body1{
    margin: 50px;
    color: white;
    text-decoration: underline;
    margin-left: 160px;
}
.MuiTypography-root.typographyContactEven.MuiTypography-body1{
    margin: 50px;
    color: white;
    text-decoration: underline;
    margin-left: 160px;
}
.typographyTACGrid{
    margin-top:5% !important;
}
.typographyPolicyGrid{
    margin-top:5% !important;
}
.typographyContactGrid{
    margin-top:5% !important;
}
@media screen and (min-width:1400px) and (max-width:1910px)
{
    .laptopImageTwo {
        z-index: 2;
        width: 160%;
        position: absolute;
        top: 50%;
        left: 51%;
        transform: translate(-53%,-41%);
    }
    .circleTopLeftImgTwo {
        margin-left: -14%;
        margin-top: -17% !important;
        width: 70%;
        z-index: 1;
        position: absolute;
    }
    .windEverestLogoTwo {
        margin-left: 12%;
        margin-top: 9%;
        width: 25%;
        z-index: 2;
        position: absolute;
    }
    .MuiTypography-root.onBTwoArrowInfo.MuiTypography-body1 {
        color: #369;
        font-size: 16px;
        font-weight: bolder;
        width: 59%;
        margin-left: 38%;
        margin-top: 82%;
        text-align: justify;
    }
    .onBTwoArrowIconTwo {
        height: 20px;
        margin-top: 10.24rem;
        margin-left: 8rem;
      }
    .greenTopRightCircleTwo {
        z-index: 1;
        width: 15%;
        margin-top: 23%;
        margin-left: 80%;
        position: absolute;
    }
    .MuiTypography-root.batteryIconHeadingTwo.MuiTypography-h6 {
        width: 90%;
        z-index: 4;
        position: absolute;
        margin-top: 106%;
        color: white;
        margin-left: 20%;
        font-size: 24px;
        font-weight: bolder;
    }
    .MuiTypography-root.onBTwoArrowSecondInfoTwo.MuiTypography-body1 {
        color: #369;
        font-size: 17px;
        margin-top: -24%;
        margin-left: 16%;
        text-align: justify;
        width: 91%;
        font-weight: bolder;
    }
    .onBTwoArrowLowerIcon {
        height: 21px;
        margin-left: 108%;
        margin-top: -258%;
    }
    .circleBottomLeftTwo {
        width: 36%;
        margin-top: 82%;
        margin-left: 54%;
      }
    .popupBatteryGridTwo {
        position: relative;
        margin-left: -10% !important;
        margin-top: -6% !important;
        width: 80% !important;
    }
    .popupBatteryPanelGridTwo {
        width: 115%;
        margin-top: 98%;
        margin-left: 0%;
        z-index: 2;
        position: absolute;
        height: 19%;
    }
    .batteryInfoIconTwo {
        width: 7%;
        margin-top: 102%;
        margin-left: 104%;
        z-index: 4;
        position: absolute;
    }
    .batteryMeterIconTwo {
        width: 105%;
        z-index: 4;
        position: absolute;
        margin-top: 117%;
        margin-left: 4%;
    }
    .batteryMeterTwo {
        width: 120% !important;
        z-index: 4;
        position: absolute;
        margin-top: 136%;
        margin-left: 0%;
    }
    
}
@media screen and (min-width:900px) and (max-width:1200px)
{
    .laptopImageTwo {
        z-index: 2;
        width: 160%;
        position: absolute;
        top: 35%;
        left: 50%;
        transform: translate(-53%,-42%);
    }
    
    .greenTopRightCircleTwo {
        z-index: 1;
        width: 15%;
        margin-top: -5%;
        margin-left: 79%;
        position: absolute;
    }
    .popupBatteryGridTwo {
        position: relative;
        margin-left: -11% !important;
        margin-top: -5% !important;
        width: 88% !important;
    }
    .popupBatteryPanelGridTwo {
        width: 115%;
        margin-top: 98%;
        margin-left: 0%;
        z-index: 2;
        position: absolute;
        height: 21%;
    }
    .onBTwoArrowLowerIcon {
        height: 15px;
        margin-left: -22%;
        margin-top: -223%;
    }
    .MuiTypography-root.onBTwoArrowSecondInfoTwo.MuiTypography-body1 {
        color: #369;
        font-size: 0.75rem;
        margin-top: -21%;
        margin-left: 6%;
    }
    .onBTwoArrowIconTwo {
        height: 15px;
        margin-top: 161px;
        margin-left: 45px;
    }
    .circleBottomLeftTwo {
        width: 36%;
        margin-top: 45%;
        margin-left: 56%;
    }
    .laptopGridTwo {
        position: relative;
        margin-left: 36px !important;
        margin-top: 13% !important;
    }
  
    .MuiTypography-root.onBTwoArrowInfo.MuiTypography-body1 {
        color: #369;
        font-size: 0.85rem;
        font-weight: bolder;
        width: 80%;
        margin-left: 53px;
        margin-top: 235px;
        text-align: justify;
    }
    .footerOnBoardingEvenTwo {
        background-color: #369;
        height: 30%;
        margin-top: 8%;
    }
    .MuiTypography-root.typographyTACEven.MuiTypography-body1 {
        margin: 50px;
        color: white;
        text-decoration: underline;
        margin-left: 120px;
        width: 100%;
    }
}
@media screen and (min-width:1050px) and (max-width:1200px)
{
    .circleBottomLeftTwo {
        width: 36%;
        margin-top: 67%;
        margin-left: 53%;
    }
    .laptopGridTwo {
        position: relative;
        margin-left: 36px !important;
        margin-top: 10% !important;
    }
    .laptopImageTwo {
        z-index: 2;
        width: 160%;
        position: absolute;
        top: 35%;
        left: 50%;
        transform: translate(-53%,-39%);
    }
    .greenTopRightCircleTwo {
        z-index: 1;
        width: 15%;
        margin-top: 0%;
        margin-left: 79%;
        position: absolute;
    }
    .MuiTypography-root.onBTwoArrowInfo.MuiTypography-body1 {
        color: #369;
        font-size: 0.80rem;
        font-weight: bolder;
        width: 73%;
        margin-left: 53px;
        margin-top: 252px;
        text-align: justify;
    }
}
@media screen and (min-width:1920px) and (max-width:2000px){
    .onBTwoArrowIconTwo {
        height: 22px;
        margin-top: 15.9rem;
        margin-left: 8.5rem !important;
    }
    .MuiTypography-root.onBTwoArrowInfo.MuiTypography-body1 {
        color: #369;
        font-size: 1.6rem;
        font-weight: bolder;
        width: 68%;
        margin-left: 31%;
        margin-top: 92%;
        text-align: justify;
    }
    
    .greenTopRightCircleTwo {
        z-index: 1;
        width: 15%;
        margin-top: 21%;
        margin-left: 82%;
        position: absolute;
    }
    .popupBatteryPanelGridTwo {
        width: 115%;
        margin-top: 98%;
        margin-left: 0;
        z-index: 2;
        position: absolute;
        height: 18%;
      }
    .batteryIconTwo {
        z-index: 4;
        position: absolute;
        margin-top: 105%;
        margin-left: 8%;
        width: 11%;
    }
    .MuiTypography-root.batteryIconHeadingTwo.MuiTypography-h6 {
        width: 90%;
        z-index: 4;
        position: absolute;
        margin-top: 106%;
        color: #fff;
        margin-left: 23%;
        font-size: 1.7rem;
        font-weight: bolder;
    }
    .batteryMeterIconLabel {
        margin-left: 17px !important;
        font-size: 1.2rem !important;
        color: white;
        width: 5% !important;
        display: inline;
      }
      .batteryMeterIconLabel1 {
        margin-left: 4.5% !important;
        font-size: 1.2rem !important;
        color: white;
        width: 5% !important;
        display: inline;
      }
    .onBTwoArrowLowerIcon {
        height: 25px;
        margin-left: 109%;
        margin-top: -233%;
    }
    .MuiTypography-root.onBTwoArrowSecondInfoTwo.MuiTypography-body1 {
        color: #369;
        font-size: 1.6rem;
        margin-top: -23%;
        margin-left: 16%;
        text-align: left;
        width: 100%;
        font-weight: bolder;
      }
    .circleBottomLeftTwo {
        width: 36%;
        margin-top: 56%;
        margin-left: 50%;
      }
      .popupBatteryGridTwo {
        position: relative;
        margin-left: -9% !important;
        margin-top: -6% !important;
        width: 66% !important;
      }
    .laptopImageTwo {
        z-index: 2;
        width: 164%;
        position: absolute;
        margin-top: 3%;
        margin-left: 2%;
      }
    .MuiTypography-root.typographyContactEven.MuiTypography-body1, .MuiTypography-root.typographyPolicyEven.MuiTypography-body1, .MuiTypography-root.typographyTACEven.MuiTypography-body1 {
        color: #fff;
        text-decoration: underline;
        margin: 50px 56px 54px 42%;
        font-size: 1.5rem;
    }
    
    
}