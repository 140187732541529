.rpBatteryDiv {
    background-color: #336699;
    border-radius: 15px;
    width: 90%;
    height: 252px;
    margin-top: -10px;
    margin-left: -8%;
}
.rpBatteryDivMessage{
    background-color: #336699;
    border-radius: 15px;
    width: 70%;
    height: 202px;
}
.infoIconImg{
    width:20px;
    height:20px;
    margin-left: 310px;
    margin-top: 10px;
}
.rpBatteryTitleDiv{
    margin-left: 20px;
    margin-top:10px;
}
.rpNoonTitleDiv {
    margin-left: 5%;
    margin-top: 3%;
    padding-top: 2%;
}
.rpBatteryNoonTitle{
    margin-left:12px;
    font-size:18px !important;
    color:white;
    width:5%;
    font-weight: bolder !important;
    display: inline;   
}
.rpBatteryIconImgDiv{
    margin-top:-4px !important;
    margin-right:-15px !important;
}
.rpBatteryIconImg {
    width: 35px;
    height: 35px;
}
.rpBatteryMeterImg{
    width:90%;
    margin-left: 15px;
    margin-bottom: 2px;
}
.rpBatteryMeterLabelValues {
    width: 110%;
    margin-left: 2%;
}
.rpBatteryMeterImgDiv{
    margin-bottom: 10%;
}
.rpBatteryMeterLabel{
    margin-left:4px;
    font-size:10px;
    color:white;
    width:5%;
    display: inline;
}
.rpBatteryMeterLabel1{
    margin-left:6.5%;
    font-size:10px;
    color:white;
    width:5%;
    display: inline;
}
.rpBatterySliderGrid.MuiGrid-container{
    width:90%;
    margin-left:15px;
    margin-top:15px;
}
.rpBatterySliderGrid.MuiGrid-container.MuiSlider-thumb.PrivateValueLabel-offset-80.MuiSlider-valueLabel.PrivateValueLabel-circle-81{
    background-color: unset;
    top:-10px;
    font-size:14px;

}
.MuiTypography-root.rpBatteryIconLabel.MuiTypography-h5.MuiTypography-displayBlock {
    color: white;
    font-size: 1.05rem;
    font-weight: bolder;
    margin-left: -4%;
    margin-top: 1%;
}
.infoRPBoxModal{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    width: 400px;
    background-color: white;
    border-radius: 30px;
    box-shadow: 24;
}
.closeRPModalIcon{
    width:40px;
    height:40px;
    margin-left: 350px;
    margin-top: 10px;
    cursor: pointer;
}
.clockModalIcon{
    width:60px;
    height:60px;
    margin-left: 170px;
    margin-top: 20px;
}
.loadingModalIcon{
    width:60px;
    height:60px;
    margin-left: 170px;
    margin-top: 20px;
}
.clockModalInfo.MuiTypography-body1{
    margin-left:50px;
    width: 48%;
    font-size:15px;
    padding: 20px 50px 50px 50px;
    text-align: center ;
}
.loadingInfo.MuiTypography-body1{
    margin-left:60px;
    width: 43%;
    font-size:15px;
    padding: 20px 50px 50px 50px;
    text-align: center ;
}
.rpInfoMessage.MuiTypography-subtitle2{
    width:90%;
    font-size:13px;
    text-align: left ;
    margin-top:10px;
    margin-left:18px;
    color:white;
}
.rpBatteryButtonGrids{
 	margin-top: -4%;
}
.sliderBtnChargeRP {
    border-radius: 1.2rem !important;
    color: #336699 !important;
    background-color: white !important;
    font-weight: bold !important;
    text-transform: none !important;
    height: 35px;
}
.disabledBtnSubmitRP {
    color: rgba(181, 177, 177, 0.71) !important;
    border: 1px solid rgba(181, 177, 177, 0.71) !important;
    font-weight: bold !important;
    text-transform: none !important;
    border-radius: 1.2rem !important;
}
.btnSubmitRP {
    color: white !important;
    border: 2px solid white !important;
    font-weight: bolder !important;
    text-transform: none !important;
    border-radius: 1.2rem !important;
}
@media screen and (min-width:600px) and (max-width:900px){
.rpBatteryMeterLabel{
    margin-left:12px;
    font-size:10px;
    color:white;
    width:5%;
    display: inline;
}
.rpBatteryMeterLabel1{
    margin-left:21.8px;
    font-size:10px;
    color:white;
    width:5%;
    display: inline;
}
}
@media screen and (min-width:360px) and (max-width:900px){
    .rpBatteryMeterLabel{
        margin-left:4.5%;
        font-size:10px;
        color:white;
        width:5%;
        display: inline;
    }
    .rpBatteryMeterLabel1{
        margin-left:6.7%;
        font-size:10px;
        color:white;
        width:5%;
        display: inline;
    }
    .rpBatteryMeterImg{
        width:90%;
        margin-left: 15px;
        margin-bottom: 2px;
    }
    .rpBatteryMeterLabelValues{
        width:97%;
    }
    .rpBatteryDivMessage{
        background-color: #336699;
        border-radius: 15px;
        width: 70%;
        height: 212px;
    }
 }

 @media screen and (min-width:960px) and (max-width:1200px){
    .rpBatteryNoonTitle {
        margin-left: 12px;
        font-size: 1.0rem !important;
        color: white;
        width: 5%;
        font-weight: bolder !important;
        display: inline;
    }
    .rpBatteryDiv {
        background-color: #336699;
        border-radius: 15px;
        width: 90%;
        height: 252px;
        margin-top: -3%;
        margin-left: -16%;
    }
    .MuiTypography-root.rpBatteryIconLabel.MuiTypography-h5.MuiTypography-displayBlock {
        color: white;
        font-size: 0.95rem;
        font-weight: bolder;
        margin-left: 3%;
        margin-top: 2%;
    }
    .rpBatteryMeterLabelValues {
        width: 95%;
        margin-left: 2%;
    }
    .rpBatteryButtonGrids {
        margin-top: 13%;
    }
}
@media screen and (min-width:1070px) and (max-width:1200px){
    .MuiTypography-root.rpBatteryIconLabel.MuiTypography-h5.MuiTypography-displayBlock {
        color: white;
        font-size: 1.05rem;
        font-weight: bolder;
        margin-left: 0%;
        margin-top: 1%;
    }
    .rpBatteryMeterLabelValues {
        width: 107%;
        margin-left: 2%;
    }
}
@media screen and (min-width:1390px) and (max-width:1925px){
    .MuiTypography-root.rpBatteryIconLabel.MuiTypography-h5.MuiTypography-displayBlock {
        color: white;
        font-size: 1.15rem;
        font-weight: bolder;
        margin-left: -7%;
        margin-top: 1%;
    }
    .rpBatterySliderGrid.MuiGrid-container {
        width: 92%;
        margin-left: 4%;
        margin-top: 10%;
    }
    .rpBatteryMeterImg {
        width: 94%;
        margin-left: 3%;
        margin-bottom: 1%;
    }
    .rpBatteryMeterLabelValues {
        width: 120%;
        margin-left: 2%;
    }
    .rpBatteryButtonGrids {
        margin-top: -3% !important;
    }
    .rpBatteryNoonTitle {
        margin-left: 1% !important;
        font-size: 1.4rem !important;
        color: white;
        width: 100%;
        font-weight: bolder !important;
        margin-top: 2% !important;
        display: block !important;
    }
    .sliderBtnChargeRP {
        color: #336699 !important;
        font-weight: bolder !important;
        border-radius: 1.5rem !important;
        text-transform: none !important;
        background-color: white !important;
        width: 108% !important;
        height: 50px;
    }
    .disabledBtnSubmitRP {
        color: rgba(181, 177, 177, 0.71) !important;
        border: 1px solid rgba(181, 177, 177, 0.71) !important;
        font-weight: bolder !important;
        border-radius: 1.5rem !important;
        text-transform: none !important;
        width: 107% !important;
        margin-left: 4% !important;
        height: 50px !important;
    }
    .btnSubmitRP {
        color: white !important;
        border: 1px solid white !important;
        font-weight: bolder !important;
        border-radius: 1.5rem !important;
        text-transform: none !important;
        width: 107% !important;
        margin-left: 4% !important;
        height: 50px !important;
    }
    .rpBatteryDiv {
        background-color: #336699;
        border-radius: 15px;
        width: 92%;
        height: 27em;
        margin-top: -15px;
        margin-left: -8%;
    }
    .infoRPBoxModal {
        position: absolute;
        top: 40%;
        left: 55%;
        transform: translate(-50%,-50%);
        width: 400px;
        background-color: white;
        border-radius: 30px;
        box-shadow: 24;
    }
}
@media screen and (min-width:1920px) and (max-width:1925px){
    .rpBatteryDiv {
        background-color: #336699;
        border-radius: 15px;
        width: 92%;
        height: 27em;
        margin-top: -23px;
        margin-left: -8%;
    }
    .rpBatteryNoonTitle {
        margin-left: 1% !important;
        font-size: 1.6rem !important;
        color: white;
        width: 100%;
        font-weight: bolder !important;
        margin-top: 2% !important;
        display: block !important;
    }
    .rpBatteryIconImg {
        width: 40px;
        height: 40px;
    }
    .MuiTypography-root.rpBatteryIconLabel.MuiTypography-h5.MuiTypography-displayBlock {
        color: white;
        font-size: 1.35rem;
        font-weight: bolder;
        margin-left: -7%;
        margin-top: 1%;
    }
    .rpBatteryIconImgDiv {
        margin-top: -4px !important;
        margin-right: -14px !important;
        margin-left: 2% !important;
    }
    .rpBatteryMeterImg {
        width: 93%;
        margin-left: 3.5%;
        margin-bottom: 1%;
    }
    .rpBatteryMeterLabelValues {
        width: 120%;
        margin-left: 3%;
    }
    .rpBatteryMeterLabel {
        margin-left: 4px;
        font-size: 1.0rem;
        color: white;
        width: 5%;
        display: inline;
    }
    .rpBatteryMeterLabel1 {
        margin-left: 6.25%;
        font-size: 1.0rem;
        color: white;
        width: 5%;
        display: inline;
    }
    .rpBatteryMeterLabelValues {
        width: 120%;
        margin-left: 3%;
    }
    .rpBatteryButtonGrids {
        margin-top: -1% !important;
        padding-top: 2%;
    }
    .sliderBtnChargeRP {
        color: #336699 !important;
        font-weight: bolder !important;
        border-radius: 1.5rem !important;
        text-transform: none !important;
        background-color: white !important;
        width: 108% !important;
        height: 50px;
        font-size: 1.2rem !important;
    }
    .disabledBtnSubmitRP {
        color: rgba(181, 177, 177, 0.71) !important;
        border: 1px solid rgba(181, 177, 177, 0.71) !important;
        font-weight: bolder !important;
        border-radius: 1.5rem !important;
        text-transform: none !important;
        width: 107% !important;
        margin-left: 4% !important;
        height: 50px !important;
        font-size: 1.2rem !important;
    }
    .btnSubmitRP {
        color: white !important;
        border: 1px solid white !important;
        font-weight: bolder !important;
        border-radius: 1.5rem !important;
        text-transform: none !important;
        width: 107% !important;
        margin-left: 4% !important;
        height: 50px !important;
        font-size: 1.2rem !important;
    }
}