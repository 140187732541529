
.container{
    width: 100vw;
    height: 100vh;
    overflow: scroll;
    display: flex;
    flex-direction: column;
}

p{
    margin: 0;
    padding: 0;
    opacity: 0.7;
}

.header{
    width: 100%;
    height: 100px;
    margin-top: 10px;
}

.header img{
    width: 150px;
    margin-left: 5%;
}

.bodyContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.bodyContainer img{
    width: 200px;
    margin-bottom: 40px;
}
::-ms-reveal{display: none;}
hr{
    width: 500px;
    margin-top: 10px;
    margin-bottom: 10px;
    opacity: 0.4;
}

.bodyContainer span{
    color: white!important;
    text-decoration: none!important;
}