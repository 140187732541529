.landingPage1Container{
    margin: 0px !important;
    padding: 0px !important;
    width: 100% !important;
    max-width: 100% !important;
}
.videoCard{
	width: 48% !important;
	height: 9%;
	margin-top: 20%;
	margin-left: 25%;
    display: block;
}
.videoCard.video{
	width: 720px;
	height: 524px;
	margin: 0 0 0 0;
}
.MuiTypography-root.pageTitleWorks.MuiTypography-h6{
	margin-left: 43%;
	margin-top: 10%;
	margin-bottom: 5%;
	color: #369;
	font-weight: bolder;
	font-size: 1.75rem;
}
.MuiTypography-root.windAtGlanceHeading.MuiTypography-h6{
	color: #063;
	font-size: 2.05rem;
	font-weight: bolder;
	margin-top: 8%;
}
.MuiTypography-root.windAtGlanceInfo1.MuiTypography-body1{
	width: 54%;
    text-align: left;
    font-size: 1.25rem;
    font-weight: bolder;
    margin-bottom: 2%;
}
.MuiTypography-root.windAtGlanceInfo2.MuiTypography-body2{
	width: 51%;
    text-align: justify;
    font-size: 0.95rem;
    margin-bottom: 2%;
}
.MuiTypography-root.windAtGlanceInfo3.MuiTypography-body2{
	width: 50%;
    text-align: justify;
    font-size: 0.95rem;
}
.dashboardMobileImage{
	width: 100%;
}
.MuiTypography-root.personalStatsHeading.MuiTypography-h6{
	font-size: 2.05rem;
    font-weight: bolder;
    margin-top: 8%;
    color: #063;
    margin-left: 24%;
}
.MuiTypography-root.personalStatsInfo1.MuiTypography-body1{
	margin-left: 24%;
    font-size: 1.25rem;
    font-weight: bolder;
    width: 55%;
    margin-bottom: 2%;
}
.MuiTypography-root.personalStatsInfo2.MuiTypography-body2{
	font-size: 1.05rem;
    width: 59%;
    text-align: justify;
    margin-left: 24%;
}
.personalStatMobileImage{
	width: 100%;
    margin-top: -13%;
    margin-left: -8%;
}
.groupsMessageImage{
	width:100%;
}
.MuiTypography-root.groupsMessageHeading.MuiTypography-h6{
	color: #063;
    font-size: 2.05rem;
    font-weight: bolder;
    margin-top: 16%;
}
.MuiTypography-root.groupsMessageInfo1.MuiTypography-body1{
	font-size: 1.25rem;
    font-weight: bolder;
    width: 55%;
    margin-bottom: 2%;
}
.MuiTypography-root.groupsMessageInfo2.MuiTypography-body2{
	font-size: 0.95rem;
    width: 52%;
    text-align: justify;
}
.groupMessageLine{
	width: 120%;
    margin-left: -37%;
    margin-top: -32%;
}
.extraFeatureMobileImage{
	width: 100%;
    margin-left: -24%;
}
.MuiTypography-root.extraFeatureHeading.MuiTypography-h6{
    font-size: 2.05rem;
    color: #063;
    font-weight: bolder;
    margin-left: 24%;
    margin-bottom: 2%;
    margin-top: 20%;
}

.MuiTypography-root.extraFeatureInfo1.MuiTypography-body1{
	margin-left: 24%;
    font-size: 1.25rem;
    font-weight: bolder;
    margin-bottom: 2%;
}
.MuiTypography-root.extraFeatureInfo2.MuiTypography-body2{
	font-size: 0.95rem;
    width: 51%;
    margin-left: 24%;
    text-align: justify;
}
@media screen and (min-width:1400px) and (max-width:1950px)
{
    .MuiTypography-root.pageTitleWorks.MuiTypography-h6 {
        margin-left: 42%;
        margin-top: 10%;
        margin-bottom: 5%;
        color: #369;
        font-weight: bolder;
        font-size: 1.85rem;
    }

    .MuiTypography-root.windAtGlanceInfo1.MuiTypography-body1 {
        width: 52%;
        text-align: left;
        font-size: 1.25rem;
        font-weight: bolder;
        margin-bottom: 2%;
    }
    .MuiTypography-root.windAtGlanceInfo2.MuiTypography-body2 {
        width: 49%;
        text-align: justify;
        font-size: 0.95rem;
        margin-bottom: 2%;
    }
    .MuiTypography-root.windAtGlanceInfo3.MuiTypography-body2 {
        width: 50.5%;
        text-align: justify;
        font-size: 0.95rem;
    }
    .MuiTypography-root.personalStatsInfo1.MuiTypography-body1 {
        margin-left: 24%;
        font-size: 1.25rem;
        font-weight: bolder;
        width: 55%;
        margin-bottom: 2%;
    }
    .MuiTypography-root.personalStatsInfo2.MuiTypography-body2 {
        font-size: 0.95rem;
        width: 51%;
        text-align: justify;
        margin-left: 24%;
    }
    .MuiTypography-root.groupsMessageInfo1.MuiTypography-body1 {
        font-size: 1.25rem;
        font-weight: bolder;
        width: 54%;
        margin-bottom: 2%;
    }
    .MuiTypography-root.groupsMessageInfo2.MuiTypography-body2 {
        font-size: 0.95rem;
        width: 50%;
        text-align: justify;
    }
.groupMessageLine {
    width: 120%;
    margin-left: -33%;
    margin-top: -26%;
}
.MuiTypography-root.extraFeatureInfo1.MuiTypography-body1 {
    margin-left: 24%;
    font-size: 1.25rem;
    font-weight: bolder;
    margin-bottom: 0%;
}
.MuiTypography-root.extraFeatureInfo2.MuiTypography-body2 {
    font-size: 0.95rem;
    width: 50%;
    margin-left: 24%;
    text-align: justify;
}

}
@media screen and (min-width:960px) and (max-width:1200px)
{
    .videoCard {
        width: 48% !important;
        height: 9%;
        margin-top: 20%;
        margin-left: 26%;
        display: block;
    }
    .MuiTypography-root.windAtGlanceInfo1.MuiTypography-body1 {
        width: 67%;
        text-align: left;
        font-size: 1.05rem;
        font-weight: bolder;
        margin-bottom: 2%;
    }
    .MuiTypography-root.windAtGlanceInfo2.MuiTypography-body2 {
        width: 66%;
        text-align: justify;
        font-size: 0.95rem;
        margin-bottom: 2%;
    }
    .MuiTypography-root.windAtGlanceInfo3.MuiTypography-body2 {
        width: 70%;
        text-align: justify;
        font-size: 0.95rem;
    }
    .dashboardMobileImage {
        width: 103%;
    }
    .personalStatMobileImage {
        width: 100%;
        margin-top: 0%;
        margin-left: -8%;
    }
    .MuiTypography-root.personalStatsInfo1.MuiTypography-body1 {
        margin-left: 24%;
        font-size: 1.15rem;
        font-weight: bolder;
        width: 72%;
        margin-bottom: 2%;
    }
    .MuiTypography-root.personalStatsInfo2.MuiTypography-body2 {
        font-size: 0.95rem;
        width: 74%;
        text-align: justify;
        margin-left: 24%;
    }
    .MuiGrid-root.personalStatDetailsInfo.MuiGrid-item.MuiGrid-grid-xs-6.MuiGrid-grid-md-6 {
        margin-left: -2%;
        margin-right: 2%;
    }
    .MuiTypography-root.groupsMessageInfo1.MuiTypography-body1 {
        font-size: 1.15rem;
        font-weight: bolder;
        width: 71%;
        margin-bottom: 2%;
    }
    .MuiTypography-root.groupsMessageInfo2.MuiTypography-body2 {
        font-size: 0.85rem;
        width: 65%;
        text-align: justify;
    }
    .groupMessageLine {
        width: 131%;
        margin-left: -31%;
        margin-top: -32%;
    }
    .groupsMessageImage {
        width: 115%;
        margin-left: -7%;
        margin-top: -4%;
    }
    .MuiGrid-root.extraFeatureDetailsInfo.MuiGrid-item.MuiGrid-grid-xs-6.MuiGrid-grid-md-6{
        margin-top: -3%;
    }
    .MuiTypography-root.extraFeatureInfo1.MuiTypography-body1 {
        margin-left: 24%;
        font-size: 1.25rem;
        font-weight: bolder;
        margin-bottom: 2%;
    }
    .MuiTypography-root.extraFeatureInfo2.MuiTypography-body2 {
        font-size: 0.85rem;
        width: 63%;
        margin-left: 24%;
        text-align: justify;
    }
}
