
.OnboardingFiveContainer{
    margin: 0px !important;
    padding: 0px !important;
    background-color: #2b5291;
    width: 100% !important;
    max-width: 100% !important;
}

.logoGridFive{
    position: relative;
}
.circleTopLeftImgFive {
    margin-left: -48px;
    margin-top: -70px;
    width: 87%;
    z-index: 1;
    position: absolute;
}
.windEverestLogoFive {
    margin-left: 41px;
    margin-top: 17px;
    width: 33%;
    z-index: 2;
    position: absolute;
}
.MuiTypography-root.arrowOneInfoFive.MuiTypography-body1 {
    color: white;
    font-size: 17px;
    font-weight: bolder;
    width: 92%;
    margin-left: -20px;
}
 
 .arrowIconsFive {
     height: 17px;
     margin-top: 4px;
     margin-left: 4px;
 }


.MuiGrid-root.MuiGrid-item.MuiGrid-grid-md-1.infoGridIconFive{
    max-height: 30px;
}
.laptopImageFive {
    width: 150%;
    z-index: 2;
    position: relative;
    top: 53%;
    left: 50%;
    transform: translate(-21%,-65%);
}

.rightCircleIconFive {
    width: 63%;
    margin-left: 192px;
    margin-top: -71px;
}
.infoGrid {
    display: flex;
    margin-top: 54px !important;
    height: 60px;
}
.popupBadgeIcon {
    width: 125%;
    margin-top: 85px;
    margin-left: -144px;
    height: 45%;
    z-index: 3;
    position: absolute;
}
.circleBottomRightFive{
    width: 26%;
    margin-top: 335px;
    margin-left: -112px;
    z-index: 1;
    position: absolute;
}

.badgeHeading {
    position: absolute;
    z-index: 3;
    margin-top: 97px !important;
    font-size: 23px !important;
    font-weight: bolder !important;
    color: #369;
    margin-left: 12px !important;
}
.badgeBarIcon {
    z-index: 3;
    position: absolute;
    margin-top: 134px;
    width: 13%;
    margin-left: 32px;
    height: 2px;
}
.allBadgeInfo {
   z-index: 3;
	position: absolute;
	margin-top: 155px !important;
	margin-left: -63px !important;
	font-size: 11px !important;
	font-weight: bold !important;
	width: 72%;
	text-align: center;
}
.badgesIcon {
    width: 98%;
    z-index: 3;
    position: absolute;
    margin-top: 194px;
    margin-left: -93px;
}
.popupBadgeGrid{
    position: relative;
}
.weeklyTableIcon{
    width: 70%;
    z-index: 2;
    position: absolute;
    margin-left: 100px;
    margin-top: 185px;
}


.headingBadge {
    z-index: 3;
    color:#369;
    position: absolute;
    margin-top: 109px;
    margin-left: 22px;
}

.popupBatteryPanelGridFive{
    width: 110%;
    margin-top: 305px;
    margin-left: -38px;
    z-index: 2;
    position: absolute;
    height: 155px;
}
.popupBatteryGridFive {
    position: relative;
    margin-left: 17% !important;
    margin-top: -14% !important;
    width: 18% !important;
}
.batteryMeterIcon{
    width: 102%;
    z-index: 4;
    position: absolute;
    margin-top: 366px;
    margin-left: -27px;
}
.MuiTypography-root.batteryIconHeading.MuiTypography-h6{
    width: 90%;
    z-index: 4;
    position: absolute;
    margin-top: 328px;
    color: white;
    margin-left: 20px;
    font-size: 17px;
    font-weight: bolder;

}
.batteryMeter{
    width: 116% !important;
    z-index: 4;
    position: absolute;
    margin-top: 426px;
    margin-left: -40px;
}
.batteryMeterIconLabel{
    margin-left:12px;
    font-size:10px;
    color:white;
    width:5%;
    display: inline;
}
.batteryMeterIconLabel1{
    margin-left: 4.5%;
    font-size: 10px;
    color: white;
    width: 5%;
    display: inline;
}
.batteryIcon{
    z-index: 4;
    position: absolute;
    margin-top: 324px;
    margin-left: -20px;
    width: 10%;

}
.onBFiveArrowLowerIcon{
    height: 21px;
    margin-left: -13px;
    margin-top: 1px;
}
.MuiTypography-root.onBFiveArrowSecondInfo.MuiTypography-body1{
    color: white;
    font-size: 17px;
}

.onBoardingFiveLowerInfoGrid {
    margin-left: -15%;
    width: 19% !important;
    margin-top: 23%;
}
.footerOnBoardingOddFive {
    background-color: white;
    height: 6%;
    margin-top: -4% !important;
}
.MuiTypography-root.typographyTACFive.MuiTypography-body1{
    margin: 50px;
    color: green;
    text-decoration: underline;
    margin-left: 160px;
}
.MuiTypography-root.typographyPolicyFive.MuiTypography-body1{
    margin: 50px;
    color: green;
    text-decoration: underline;
    margin-left: 160px;
}
.MuiTypography-root.typographyContactFive.MuiTypography-body1{
    margin: 50px;
    color: green;
    text-decoration: underline;
    margin-left: 160px;
}
.typographyTACGrid{
    margin-top:5% !important;
}
.typographyPolicyGrid{
    margin-top:5% !important;
}
.typographyContactGrid{
    margin-top:5% !important;
}
@media screen and (min-width:1400px) and (max-width:1910px)
{
    .circleTopLeftImgFive {
        margin-left: -59px;
        margin-top: -72px;
        width: 70%;
        z-index: 1;
        position: absolute;
    }
    .batteryMeterIconLabel {
        margin-left: 3%;
        font-size: 1.2rem;
        color: white;
        width: 5%;
        display: inline;
      }
      .batteryMeterIconLabel1 {
        margin-left: 4.25%;
        font-size: 1.2rem;
        color: white;
        width: 5%;
        display: inline;
      }
    .windEverestLogoFive {
        margin-left: 10%;
        margin-top: 6%;
        width: 26%;
        z-index: 2;
        position: absolute;
    }
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-md-1.infoGridIconFive {
        max-height: 30px;
        margin-top: 8%;
    }
    .MuiTypography-root.arrowOneInfoFive.MuiTypography-body1 {
        color: white;
        font-size: 1.2rem;
        font-weight: bolder;
        width: 92%;
        margin-left: -4%;
        margin-top: 6%;
      }
    .arrowIconsFive {
        height: 19px;
        margin-top: -22%;
        margin-left: 8%;
    }
    .rightCircleIconFive {
        width: 63%;
        margin-left: 59%;
        margin-top: -22%;
    }
    .batteryIcon {
        z-index: 4;
        position: absolute;
        margin-top: 84%;
        margin-left: -6%;
        width: 10%;
      }
    .laptopImageFive {
        width: 150%;
        z-index: 2;
        position: relative;
        top: 54%;
        left: 50%;
        transform: translate(-21%,-65%);
    }
    .popupBatteryGridFive {
        position: relative;
        margin-left: 17% !important;
        margin-top: -11% !important;
        width: 26% !important;
        max-width: 27% !important;
      }
    .MuiTypography-root.batteryIconHeading.MuiTypography-h6 {
        width: 90%;
        z-index: 4;
        position: absolute;
        margin-top: 84%;
        color: white;
        margin-left: 7%;
        font-size: 1.4rem;
        font-weight: bolder;
      }
    .popupBatteryPanelGridFive {
        width: 110%;
        margin-top: 340x;
        margin-left: -45px;
        z-index: 2;
        position: absolute;
        height: 169px;
    }
    .onBFiveArrowLowerIcon {
        height: 21px;
        margin-left: -13px;
        margin-top: 7px;
    }
    .MuiTypography-root.onBFiveArrowSecondInfo.MuiTypography-body1 {
        color: white;
        font-size: 22px;
        text-align: left;
    }
    .onBoardingFiveLowerInfoGrid {
        margin-left: -19%;
        width: 22% !important;
        margin-top: 23%;
    }
    .batteryMeter {
        width: 113% !important;
        z-index: 4;
        position: absolute;
        margin-top: 114%;
        margin-left: -11%;
      }
    .batteryMeterIcon {
        width: 99%;
        z-index: 4;
        position: absolute;
        margin-top: 95%;
        margin-left: -27px;
      }
}
@media screen and (min-width:960px) and (max-width:1200px)
{
    .rightCircleIconFive {
        width: 63%;
        margin-left: 60%;
        margin-top: -23%;
    }
    .circleTopLeftImgFive {
        margin-left: -15%;
        margin-top: -19%;
        width: 87%;
        z-index: 1;
        position: absolute;
    }
    .windEverestLogoFive {
        margin-left: 16%;
        margin-top: 10%;
        width: 33%;
        z-index: 2;
        position: absolute;
    }
    .MuiTypography-root.arrowOneInfoFive.MuiTypography-body1 {
        color: white;
        font-size: 0.70rem;
        font-weight: bolder;
        width: 84%;
        margin-left: -10px;
    }
    .infoGrid {
        display: flex;
        margin-top: 7% !important;
        height: 60px;
        margin-left: 4% !important;
    }
    .laptopImageFive {
        width: 150%;
        z-index: 2;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-16%,-65%);
    }
    .MuiGrid-root.rightIconGrid.MuiGrid-item.MuiGrid-grid-md-3{
        margin-left: -4%;
    }
    .popupBatteryGridFive {
        position: relative;
        margin-left: 22% !important;
        margin-top: -24% !important;
        width: 20% !important;
    }
    .popupBatteryPanelGridFive {
        width: 120%;
        margin-top: 310px;
        margin-left: -45px;
        z-index: 2;
        position: absolute;
        height: 123px;
    }
    .batteryIcon {
        z-index: 4;
        position: absolute;
        margin-top: 318px;
        margin-left: -29px;
        width: 10%;
    }
    .MuiTypography-root.batteryIconHeading.MuiTypography-h6 {
        width: 90%;
        z-index: 4;
        position: absolute;
        margin-top: 317px;
        color: white;
        margin-left: 3px;
        font-size: 1.00 rem;
        font-weight: bolder;
    }
    .batteryMeterIcon {
        width: 102%;
        z-index: 4;
        position: absolute;
        margin-top: 352px;
        margin-left: -27px;
    }
    .batteryMeter {
        width: 116% !important;
        z-index: 4;
        position: absolute;
        margin-top: 400px;
        margin-left: -40px;
    }
    .onBoardingFiveLowerInfoGrid {
        margin-left: -18%;
        width: 20% !important;
        margin-top: 25%;
    }
    .onBFiveArrowLowerIcon {
        height: 15px;
        margin-left: -13px;
        margin-top: 1px;
    }
    .MuiTypography-root.onBFiveArrowSecondInfo.MuiTypography-body1 {
        color: white;
        font-size: 0.85rem;
    }
    .footerOnBoardingOddFive {
        background-color: white;
        height: 30%;
        margin-top: -4% !important;
    }
    .MuiTypography-root.typographyTACFive.MuiTypography-body1 {
        margin: 50px;
        color: green;
        text-decoration: underline;
        margin-left: 102px;
        width: 100%;
    }
}
@media screen and (min-width:1050px) and (max-width:1200px)
{
    .popupBatteryGridFive {
        position: relative;
        margin-left: 22% !important;
        margin-top: -18% !important;
        width: 20% !important;
    }
    .batteryMeter {
        width: 116% !important;
        z-index: 4;
        position: absolute;
        margin-top: 411px;
        margin-left: -40px;
    }
    .onBoardingFiveLowerInfoGrid {
        margin-left: -18%;
        width: 18% !important;
        margin-top: 22%;
    }
    .MuiTypography-root.onBFiveArrowSecondInfo.MuiTypography-body1 {
        color: white;
        font-size: 0.95rem;
    }

}
@media screen and (min-width:1920px) and (max-width:2000px){
    .MuiTypography-root.arrowOneInfoFive.MuiTypography-body1 {
        color: #fff;
        font-size: 1.5rem;
        font-weight: bolder;
        width: 92%;
        margin-left: -4%;
        margin-top: 6%;
      }
      .circleTopLeftImgFive {
        margin-left: -15%;
        margin-top: -19%;
        width: 87%;
        z-index: 1;
        position: absolute;
      }
      .windEverestLogoFive {
        margin-left: 15%;
        margin-top: 12%;
        width: 33%;
        z-index: 2;
        position: absolute;
      }
      .rightCircleIconFive {
        width: 63%;
        margin-left: 60%;
        margin-top: -23%;
      }
      .batteryMeter {
        width: 111% !important;
        z-index: 4;
        position: absolute;
        margin-top: 125%;
        margin-left: -8%;
      }
    .arrowIconsFive {
        height: 29px;
        margin-top: 3.5rem;
        margin-left: 0rem;
      }
    .popupBatteryPanelGridFive {
        width: 110%;
        margin-top: 93%;
        margin-left: -45px;
        z-index: 2;
        position: absolute;
        height: 204px;
    }
    .batteryIcon {
        z-index: 4;
        position: absolute;
        margin-top: 97%;
        margin-left: -12px;
        width: 9%;
    }
    .MuiTypography-root.batteryIconHeading.MuiTypography-h6 {
        width: 90%;
        z-index: 4;
        position: absolute;
        margin-top: 98%;
        color: #fff;
        margin-left: 11%;
        font-size: 1.5rem;
        font-weight: bolder;
    }
    .batteryMeterIcon {
        width: 98%;
        z-index: 4;
        position: absolute;
        margin-top: 106%;
        margin-left: -18px;
    }
    .MuiTypography-root.onBFiveArrowSecondInfo.MuiTypography-body1 {
        color: #fff;
        font-size: 1.7rem;
        text-align: left;
        width: 98%;
    }
    .MuiTypography-root.typographyContact.MuiTypography-body1, .MuiTypography-root.typographyPolicy.MuiTypography-body1, .MuiTypography-root.typographyTAC.MuiTypography-body1 {
        color: #174120;
        text-decoration: underline;
        margin: 50px 50px 50px 44%;
        cursor: pointer;
        font-size: 1.6rem;
    }
    .onBFiveArrowLowerIcon {
        height: 26px;
        margin-left: -1rem;
        margin-top: 0.6rem;
      }
}