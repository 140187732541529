.rootNotificationSettings {
  padding-right: 60px !important;
  margin-left: 25px !important;
}
.getInTouch {
    color: #336699 !important;
    font-weight: bold !important;
    margin-top: 40px !important;
    font-size: 26px !important;
  }
  .settingsHeading{
    font-weight: bolder;
    color: #336699;
    margin-top:72px !important; 
    font-size:16px;
    
  }

  .containerLeft{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    border-radius: 15px !important;
    padding-bottom: 30px;
  }

  .settingsContainers{
    display: flex !important;
    flex-direction: row !important;
    justify-content: start;
    gap: 20px;
    margin-top: 30px;
  }
  .settingsLeft{
    flex: 0.8;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    margin-left:20px;
    margin-top:22px;
    margin-right: -90px !important;
  }
  .settingsRight{
    flex: 0.8;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    margin-left:65px !important;
    margin-top:22px;
  }
  
  .morningReminderGrid{
    width:100%;
  }
  .firstReminderGrid{
    width:100%;
  }
  .secondReminderGrid{
    width:100%; 
  }
  .vacationGrid{
    width:100%;
  }
  .easyModeGrid{
    width:100%;
  }
  .enabledSettingsContainer{
    width:300px;
    margin-left:20px;
    margin-top:22px;
  }
  .MuiPaper-root.leftSettingsPaper.MuiPaper-elevation3.MuiPaper-rounded{
    width:95%;
    height:150px;
    border-radius:20px;
  }
  .MuiPaper-root.leftSettingsVacationOffPaper.MuiPaper-elevation3.MuiPaper-rounded{
    width:95%;
    height:150px;
    border-radius:20px;
  }
  .MuiPaper-root.leftSettingReminderPaper.MuiPaper-elevation3.MuiPaper-rounded{
    width:95%;
    height:300px;
    border-radius:20px;
  }
  .MuiPaper-root.leftSettingVacationPaper.MuiPaper-elevation3.MuiPaper-rounded{
    width:95%;
    height:450px;
    border-radius:20px;
  }
  .MuiTypography-root.defaultDropDownReminders.MuiTypography-body1{
    width:50%;
  }
  .firstReminderDropDown.MuiInputBase-root.MuiInput-root.MuiInput-underline::before{
    border-bottom: none;
  }
  .firstReminderDropDown.MuiInputBase-root.MuiInput-root.MuiInput-underline::after{
    border-bottom: none;
  }
  .secondReminderDropDown.MuiInputBase-root.MuiInput-root.MuiInput-underline::before{
    border-bottom: none;
  }
  .secondReminderDropDown.MuiInputBase-root.MuiInput-root.MuiInput-underline::after{
    border-bottom: none;
  }
  .morningReminderDropDown.MuiInputBase-root.MuiInput-root.MuiInput-underline::before{
    border-bottom: none;
  }
  .morningReminderDropDown.MuiInputBase-root.MuiInput-root.MuiInput-underline::after{
    border-bottom: none;
  }
  .vacationDropDownSelect.MuiInputBase-root.MuiInput-root.MuiInput-underline::before{
    border-bottom: none;
  }
  .vacationDropDownSelect.MuiInputBase-root.MuiInput-root.MuiInput-underline::after{
    border-bottom: none;
  }
  .easyModeDropDownGrid.MuiInputBase-root.MuiInput-root.MuiInput-underline::before{
    border-bottom: none;
  }
  .easyModeDropDownGrid.MuiInputBase-root.MuiInput-root.MuiInput-underline::after{
    border-bottom: none;
  }
  .morningReminderGrid{
    margin-bottom: 10px !important;
  
  }
  .firstReminderGrid{
    margin-bottom: 10px !important;
  
  }
  .secondReminderGrid{
    margin-bottom: 10px !important;
    
  }
  .easyModeDropDrownGrids{
    margin-bottom: 10px !important;
  }
  .easyModeDropDownGrid{
    background-color: #f2f2f2; /* set background color of select button */
  }
  .easyModeDropDownGrid-Menu{
    background-color: #f2f2f2; /* set background color of menu */
  }
  .easyModeDropDownGridMenuItem{
    text-align: center; /* center align all menu items */
    color: #333333; /* set color of menu item text */
  
  }
  .MuiTypography-root.switchReminderHeader.MuiTypography-body1{
    font-weight: bolder;
  }

  .easyModeDropDownGrid svg.MuiSvgIcon-root.MuiSelect-icon{
    right:3px !important;
    width:0.7em !important;
  }
  .vacationDropDownSelect svg.MuiSvgIcon-root.MuiSelect-icon{
    right:3px !important;
    width:0.7em !important;
  }
  .morningReminderDropDown svg.MuiSvgIcon-root.MuiSelect-icon{
    right:3px !important;
    width:0.7em !important;
  }
  .secondReminderDropDown svg.MuiSvgIcon-root.MuiSelect-icon{
    right:3px !important;
    width:0.7em !important;
  }
  .firstReminderDropDown svg.MuiSvgIcon-root.MuiSelect-icon{
    right:3px !important;
    width:0.7em !important;
  }
  .firstReminderGrid{
    max-width: 20% !important;
    margin-left: 10% !important;
  }
  .secondReminderGrid{
    max-width: 20% !important;
  margin-left: 10% !important;
  }
  .morningReminderGrid{
    max-width: 20% !important;
  margin-left: 10% !important;
  }
  .MuiGrid-root.reminderGrid.MuiGrid-container{
    max-width:116% !important;
    width: 110% !important;
  }
  .MuiGrid-root.reminderMainGrid.MuiGrid-container{
    max-width:116% !important;
  }
  .firstReminderDropDown #demo-simple-select {
    margin-left: 20px;
    font-size: 16px;
    color: #5b5959;
}
.secondReminderGrid #demo-simple-select {
  margin-left: 20px;
  font-size: 16px;
  color: #5b5959;
}
.morningReminderGrid #demo-simple-select {
  margin-left: 20px;
  font-size: 16px;
  color: #5b5959;
}
.vacationDefaultGrid #demo-simple-select {
  margin-left: 20px;
  font-size: 16px;
  color: #5b5959;
}

.custom-menu div.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
  background-color: #f2eeee;
  color: #918d8d;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}
.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.menuItemDropDowns.Mui-selected.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button {
  color: #3c3a3a;
  font-weight: bolder;
}
.easyModeDropDrownGrids #demo-simple-select {
  margin-left: 20px;
  font-size: 16px;
  color: #5b5959;
}
.MuiInputBase-root.MuiInput-root.MuiInput-underline.vacationDropDownSelect {
  max-width: 140%;
  margin-left: 10%;
  width: 140% !important;
}

  .MuiSwitch-root.leftSettingsPaper{
    left:50px;
  }
  .MuiSwitch-root.leftSettingsVacationOffPaper{
    left:50px;
  }
  .MuiSwitch-root.leftSettingReminderPaper{
    left:50px;
  }
  .MuiSwitch-root.leftSettingVacationPaper{
    left:50px;
  }
  .MuiSwitch-root.rightSettingsEasyPaper{
    left:50px;
  }
  .MuiSwitch-root.rightSettingsPaper{
    left:50px;
  }
  .MuiPaper-root.rightSettingsPaper.MuiPaper-elevation3.MuiPaper-rounded{
    width:95%;
    height:150px;
    border-radius:20px;
  }
  .MuiPaper-root.rightSettingsEasyPaper.MuiPaper-elevation3.MuiPaper-rounded{
    width:95%;
    height:450px;
    border-radius:20px;
  }
  .MuiTypography-root.switchHeader.MuiTypography-body1{
    margin-top:6px;
    font-weight: bolder;
  }
  .MuiGrid-root.vacationGrid.MuiGrid-container {
    max-width: 116% !important;
    width: 110%;
}
  .vacationReminderText{
    color:#006633;
    font-size:12px !important;
    margin-top:5px !important
  }
  .vacationGridBegin{
    max-width: 30% !important;
  }
  .vacationGridEnd{
    max-width: 30% !important;
  }
  .hrLine{
    margin-top:10px;
    margin-bottom:0px;
    opacity: 0.1;
    width: 120% !important;
  }
  .hrLineLeft{
    margin-top:20px;
    margin-bottom:20px;
    opacity: 0.1;
    width: 120% !important;
  }
  .hrLineRight{
    margin-top:25px;
    margin-bottom:10px;
    opacity: 0.1;
    width: 120% !important;
  }
  .easyModeDropDownGrid {
    background-color: #f2f2f2;
    width: 110% !important;
    max-width: 113% !important;
}
.MuiSwitch-root.leftSettingsPaper span.MuiSwitch-track {
    width: 100%;
    height: 126% !important;
    opacity: 0.38;
    z-index: -1;
    transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 10px;
    background-color: #797575 !important;
  }
  .MuiSwitch-root.leftSettingsVacationOffPaper span.MuiSwitch-track {
    width: 100%;
    height: 126% !important;
    opacity: 0.38;
    z-index: -1;
    transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 10px;
    background-color: #797575 !important;
  }
  
.MuiSwitch-root.leftSettingsPaper span.MuiSwitch-thumb {
  width: 20px;
  height: 20px;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
  border-radius: 50%;
  background-color: currentColor;
  margin-top: 1px;
}
.MuiSwitch-root.leftSettingsVacationOffPaper span.MuiSwitch-thumb {
  width: 20px;
  height: 20px;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
  border-radius: 50%;
  background-color: currentColor;
  margin-top: 1px;
}

.MuiSwitch-root.leftSettingReminderPaper span.MuiSwitch-track {
  width: 100%;
  height: 126%;
  opacity: 0.38;
  z-index: -1;
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 10px;
  background-color: #000;
}
.MuiSwitch-root.leftSettingReminderPaper span.MuiSwitch-colorPrimary.Mui-checked {
  color: #e4e4e8;
}
.MuiSwitch-root.leftSettingReminderPaper span.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
  background-color: #43a858;
}
.MuiSwitch-root.leftSettingReminderPaper .MuiSwitch-thumb {
  width: 20px;
  height: 20px;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
  border-radius: 50%;
  background-color: currentColor;
  margin-top: 1px;
}

.MuiSwitch-root.leftSettingVacationPaper span.MuiSwitch-track {
  width: 100%;
  height: 126%;
  opacity: 0.38;
  z-index: -1;
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 10px;
  background-color: #000;
}
.MuiSwitch-root.leftSettingVacationPaper span.MuiSwitch-colorPrimary.Mui-checked {
  color: #e4e4e8;
}
.MuiSwitch-root.leftSettingVacationPaper span.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
  background-color: #43a858;
}
.MuiSwitch-root.leftSettingVacationPaper .MuiSwitch-thumb {
  width: 20px;
  height: 20px;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
  border-radius: 50%;
  background-color: currentColor;
  margin-top: 1px;
}
.MuiSwitch-root.rightSettingsPaper span.MuiSwitch-track {
  width: 100%;
  height: 126% !important;
  opacity: 0.38;
  z-index: -1;
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 10px;
  background-color: #797575 !important;
}
.MuiSwitch-root.rightSettingsPaper span.MuiSwitch-thumb {
width: 20px;
height: 20px;
box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
border-radius: 50%;
background-color: currentColor;
margin-top: 1px;
}
.MuiSwitch-root.rightSettingsEasyPaper span.MuiSwitch-track {
width: 100%;
height: 126%;
opacity: 0.38;
z-index: -1;
transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
border-radius: 10px;
background-color: #797575;
}
.MuiSwitch-root.rightSettingsEasyPaper span.MuiSwitch-colorPrimary.Mui-checked {
color: #e4e4e8;
}
.MuiSwitch-root.rightSettingsEasyPaper span.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
background-color: #43a858;
}
.MuiSwitch-root.rightSettingsEasyPaper .MuiSwitch-thumb {
width: 20px;
height: 20px;
box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
border-radius: 50%;
background-color: currentColor;
margin-top: 1px;
}

  @media screen and (min-width: 600px) and (max-width: 680px) {
    .getInTouch {
       font-size: 18px!important;
      }
    
  }

  @media screen and (min-width: 960px) and (max-width: 1200px) {
    .containerLeft{
width: 90%!important;
    }
    
  }


  @media screen and (max-width: 959px) {
    .gridsm{
display: flex;
/* justify-content: center; */
    }

    .bodyContainers{
justify-content: center;
    }
    
  }

  @media screen and (max-width: 599px) {
    .bodyContainer{
        display: flex;
        flex-direction: column;
    }
    .containerLeft{
        width: 100%!important;
            }
  }
  @media screen and (min-width:310px) and (max-width:900px){
    .settingsHeading {
      font-weight: bold;
      color: #336699;
      margin-top: 20px !important;
      font-size: 16px;
      margin-left: -26px !important;
  }
  .MuiPaper-root.leftSettingsPaper.MuiPaper-elevation3.MuiPaper-rounded {
      width: 112%;
      height: 150px;
      border-radius: 20px;
      margin-left: -35px;
      margin-top: 20px ;
  }
  .MuiPaper-root.leftSettingsVacationOffPaper.MuiPaper-elevation3.MuiPaper-rounded {
    width: 112%;
    height: 150px;
    border-radius: 20px;
    margin-left: -35px;
    margin-top: 20px ;
}
  .hrLine {
    margin-top: 0px;
    margin-bottom: 0px;
    opacity: 0.1;
    width: 96% !important;
    margin-left: -6px;
}
  .MuiSwitch-root.leftSettingsPaperReminder {
      left: 150px;
  }
  .enabledSettingsContainer {
      width: 300px;
      margin-left: 15px;
      margin-top: 15px;
  }
  .vacationGrid {
      width: 60% !important;
  }
  .hrLineLeft {
      margin-top: 0px;
      margin-bottom: 20px;
      opacity: 0.1;
      width: 96% !important;
      margin-left: -6px;
  }
  .MuiSwitch-root.leftSettingsPaperVacation {
      left: 110px;
  }
  .settingsRight {
      flex: 0.8 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: 100%;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      margin-left: -35px !important;
      margin-top: 22px;
  }
  .MuiPaper-root.rightSettingsPaper.MuiPaper-elevation3.MuiPaper-rounded {
      width: 100%;
      height: 150px;
      border-radius: 20px;
  }
  .hrLineRight {
      margin-top: 4px;
      margin-bottom: 10px;
      opacity: 0.1;
      width: 96% !important;
      margin-left: -7px;
  }
  .MuiSwitch-root.rightSettingsPaper {
      left: 62px;
  }
  .MuiPaper-root.leftSettingReminderPaper.MuiPaper-elevation3.MuiPaper-rounded {
    width: 115%;
    height: 400px;
    border-radius: 20px;
    margin-left: -35px;
}
.MuiSwitch-root.leftSettingReminderPaper {
  left: 50px;
}
  .MuiPaper-root.leftSettingVacationPaper.MuiPaper-elevation3.MuiPaper-rounded {
      width: 113%;
      height: 600px;
      border-radius: 20px;
      margin-left: -35px;
  }
  .MuiSwitch-root.leftSettingVacationPaper {
      left: 120px;
  }
  .MuiPaper-root.rightSettingsEasyPaper.MuiPaper-elevation3.MuiPaper-rounded {
      width: 101%;
      height: 470px;
      border-radius: 20px;
  }
  .MuiSwitch-root.rightSettingsEasyPaper {
      left: 70px;
  }
 
.reminderSwitch {
    margin-left: 110px;
}
.vacationDefaultGrid{
  margin-bottom: 10px !important;
  margin-left: 20px !important;
  width: 40%;
} 
.vacationGridEnd{
  margin-bottom: 10px !important;
  margin-left: 20px !important;
  width: 40%;
}  
.vacationGridBegin{
  margin-bottom: 10px !important;
  margin-left: 20px !important;
  width: 40%;
}
.switchForReminderGrid {
  margin-left: 110px !important;
}
.MuiTypography-root.switchReminderHeader.MuiTypography-body1{
  margin-top:6px;
  font-weight: bolder;
}
.MuiTypography-root.dropDownReminders.MuiTypography-body1{
	margin-top: 10px;
}
.firstReminderGrid {
    width: 20%;
    margin-left: 55px !important;
    margin-top: 10px !important;
}
.secondReminderGrid {
    width: 20%;
    margin-left: 33px !important;
}
.morningReminderGrid {
    width: 20%;
    margin-left: 29px !important;
}
  }
@media screen and (min-width:1390px) and (max-width:1925px){
  .MuiTypography-root.switchReminderHeader.MuiTypography-body1 {
    font-size: 1.3rem;
    font-weight: bolder;
    margin-bottom: 4% !important;
}

.switchForReminderGrid {
  margin-left: -10% !important;
}
.MuiGrid-root.reminderMainGrid.MuiGrid-container {
    max-width: 89% !important;
}
.MuiTypography-root.dropDownReminders.MuiTypography-body1 {
  font-size: 1.05rem !important;
  margin-top: 2% !important;
  width: 107% !important;
}
.firstReminderDropDown #demo-simple-select {
    margin-left: 12%;
    font-size: 1.1rem !important;
    color: #5b5959;
}
.secondReminderGrid #demo-simple-select {
  margin-left: 12%;
    font-size: 1.1rem !important;
    color: #5b5959;
}
.morningReminderGrid #demo-simple-select {
  margin-left: 12%;
  font-size: 1.1rem !important;
  color: #5b5959;
}
.firstReminderGrid {
  max-width: 16% !important;
  margin-left: 12% !important;
}
.secondReminderGrid {
  max-width: 16% !important;
  margin-left: 12% !important;
}
.morningReminderGrid {
  max-width: 16% !important;
  margin-left: 12% !important;
}
.MuiSwitch-root.leftSettingVacationPaper {
    left: 36%;
}
.MuiInputBase-root.MuiInput-root.MuiInput-underline.vacationDropDownSelect {
  max-width: 100% !important;
  margin-left: 9%;
  width: 100% !important;
}
.vacationDefaultGrid #demo-simple-select {
  margin-left: 12%;
  font-size: 1.0rem !important;
  color: #5b5959;
}
.vacationGrid {
  max-width: 108% !important;
  width: 106% !important;
}
.vacationReminderText {
    color: #006633;
    font-size: 0.80rem !important;
    margin-top: 3% !important;
}
.hrLineLeft {
    margin-top: 5%;
    margin-bottom: 5%;
    opacity: 0.1;
    width: 120% !important;
}
.MuiPaper-root.leftSettingVacationPaper.MuiPaper-elevation3.MuiPaper-rounded {
    width: 95%;
    height: 480px;
    border-radius: 20px;
}
.MuiPaper-root.rightSettingsEasyPaper.MuiPaper-elevation3.MuiPaper-rounded {
    width: 95%;
    height: 480px;
    border-radius: 20px;
}
.MuiSwitch-root.rightSettingsPaper {
  left: 85%;
}
.MuiSwitch-root.rightSettingsEasyPaper {
  left: 84%;
}
.easyModeDropDrownGrids {
    margin-bottom: 2% !important;
    margin-top: 2% !important;
}
.easyModeDropDownGrid {
    background-color: #e1e1e1 !important;
    width: 95% !important;
    max-width: 113% !important;
}
.easyModeDropDrownGrids #demo-simple-select {
    margin-left: 8% !important;
    font-size: 1.0rem;
    color: #5b5959;
}
.settingsHeading {
    font-weight: bolder !important;
    color: #336699;
    margin-top: 5% !important;
    font-size: 1.6rem !important;
}
.MuiSwitch-root.leftSettingsVacationOffPaper {
  left: 37%;
}
.MuiPaper-root.leftSettingReminderPaper.MuiPaper-elevation3.MuiPaper-rounded {
  width: 95%;
  height: 345px;
  border-radius: 20px;
}
.MuiSwitch-root.leftSettingsPaper {
  left: 52px;
}
.MuiPaper-root.rightSettingsPaper.MuiPaper-elevation3.MuiPaper-rounded {
    width: 95%;
    height: 165px;
    border-radius: 20px;
}
.MuiPaper-root.leftSettingsPaper.MuiPaper-elevation3.MuiPaper-rounded {
    width: 95%;
    height: 165px;
    border-radius: 20px;
}
.enabledSettingsContainer {
  width: 78%;
}
.MuiTypography-root.switchHeader.MuiTypography-body1 {
  margin-top: 6px;
  font-weight: bolder;
  font-size: 1.2rem !important;
}
}
@media screen and (min-width: 960px) and (max-width: 1200px) {
  .firstReminderDropDown #demo-simple-select {
    margin-left: 15%;
    font-size: 0.85rem;
    color: #5b5959;
}
.secondReminderGrid #demo-simple-select {
  margin-left: 15%;
  font-size: 0.85rem;
  color: #5b5959;
}
.morningReminderGrid #demo-simple-select {
  margin-left: 15%;
  font-size: 0.85rem;
  color: #5b5959;
}
.MuiGrid-root.reminderMainGrid.MuiGrid-container {
  max-width: 62% !important;
}
.firstReminderGrid {
    max-width: 25% !important;
    margin-left: 0% !important;
}
.secondReminderGrid {
  max-width: 25% !important;
  margin-left: 0% !important;
}
.morningReminderGrid {
  max-width: 25% !important;
  margin-left: 0% !important;
}
.switchForReminderGrid{
	margin-left: -27% !important;
	margin-top: 0% !important;
}
.MuiSwitch-root.leftSettingReminderPaper {
    top: -8px !important;
    left: 0px !important;
}
.MuiTypography-root.switchReminderHeader.MuiTypography-body1 {
    font-weight: bolder;
    font-size: 1.00rem;
    margin-top: 1% !important;
}
.hrLine {
    margin-top: 10px;
    margin-bottom: 0px;
    opacity: 0.1;
    width: 82% !important;
    margin-left: -2%;
}
.MuiSwitch-root.leftSettingsPaper {
    left: 0px !important;
    top: -7px !important;
}
.MuiGrid-root.vacationGrid.MuiGrid-container {
  max-width: 116% !important;
  width: 63%;
}
.MuiSwitch-root.leftSettingVacationPaper {
  left: 30%;
}
.MuiTypography-root.defaultDropDownReminders.MuiTypography-body1 {
  width: 50%;
  font-size: 0.85rem;
}
.vacationDefaultGrid #demo-simple-select {
  margin-left: 12%;
  font-size: 0.85rem;
  color: #5b5959;
}
.MuiInputBase-root.MuiInput-root.MuiInput-underline.vacationDropDownSelect {
  max-width: 200%;
  margin-left: -2%;
  width: 190% !important;
}
.vacationReminderText {
  color: #006633;
  font-size: 0.55rem !important;
  margin-top: 5px !important;
}
.hrLineLeft {
  margin-top: 20px;
  margin-bottom: 20px;
  opacity: 0.1;
  width: 81% !important;
  margin-left: -2%;
}
.MuiSwitch-root.leftSettingsVacationOffPaper {
  left: 96%;
}
.MuiSwitch-root.leftSettingVacationPaper {
  left: 93%;
}
.enabledSettingsContainer {
  width: 105%;
  margin-left: 20px;
  margin-top: 22px;
}
.easyModeGrid {
  width: 57% !important;
  margin-top: -2%;
}
.MuiTypography-root.switchHeader.MuiTypography-body1 {
  margin-top: 6%;
  font-weight: bolder;
  font-size: 0.90rem;
  width: 120% !important;
}
.MuiTypography-root.dropDownReminders.MuiTypography-body1 {
  font-size: 0.85rem !important;
  margin-top: 4% !important;
  width: 156%;
}

.easyModeDropDrownGrids #demo-simple-select {
  margin-left: 12%;
  font-size: 0.85rem;
  color: #5b5959;
}
.easyModeDropDownGrid {
  background-color: #f2f2f2;
  width: 140% !important;
  max-width: 140% !important;
}
.hrLineRight {
  margin-top: 25px;
  margin-bottom: 10px;
  opacity: 0.1;
  width: 83% !important;
  margin-left: -2%;
}
}
@media screen and (min-width: 1920px) and (max-width: 1925px) {
  .MuiSwitch-root.leftSettingReminderPaper {
    left: 100%;
  }
  .MuiSwitch-root.leftSettingsVacationOffPaper {
    left: 63%;
}
.MuiSwitch-root.leftSettingsPaper {
  left: 106px;
}
.MuiSwitch-root.leftSettingVacationPaper {
  left: 62%;
}

}