
.OnboardingThreeContainer{
    margin: 0px !important;
    padding: 0px !important;
    background-color: #369;
    width: 100% !important;
    max-width: 100% !important;
}
.logoGridThree{
    position: relative;
}
.circleTopLeftImgThree {
    margin-left: -14%;
    margin-top: -18%;
    width: 80%;
    z-index: 1;
    position: absolute;
}
.windEverestLogoThree {
    margin-left: 15%;
    margin-top: 10%;
    width: 30%;
    z-index: 2;
    position: absolute;
}
.onBoardingTwoInfo{
    display: flex;
    margin-top:110px !important;
    height:60px;
}
.infoLowerGridThree{
    display: flex;
    margin-top: -70px !important;
    height: 150px;
    margin-left: 380px !important;
    width: 40%;
}
.onBTwoArrowIcon {
    height: 17px;
    margin-top: 127px;
    margin-left: 73px;
}
.MuiTypography-root.onBThreeArrowInfo.MuiTypography-body1{
    color: #369;
font-size: 15px;
font-weight: bolder;
width: 80%;
margin-left: 71px;
margin-top: 235px;
}

.rightCircleIconThree {
    width: 65%;
    margin-left: 52%;
    margin-top: -22%;
}
.laptopGridThree {
    position: relative;
    margin-left: 36px !important;
    margin-top: -142px !important;
}
.popupVAGridThree {
    position: relative;
    margin-left: -11% !important;
    margin-top: -13% !important;
}
.onBoardingThreeLowerInfoGrid {
    margin-left: -17%;
    width: 18% !important;
    margin-top: 3%;
}
.circleBottomLeftThree {
    width: 39%;
    margin-top: 70%;
    margin-left: 83%;
}
.laptopImageThree {
    z-index: 2;
    width: 150%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-56%,-28%);
}
.greenTopRightCircleThree{
    z-index: 1;
    width: 19%;
    margin-top: 104px;
    margin-left: 440px;
    position: absolute; 
}


.popupVAPanelGridThree {
    width: 80%;
    margin-top: 108%;
    margin-left: 43%;
    z-index: 2;
    position: absolute;
}
.daysIcon {
    z-index: 4;
    position: absolute;
    margin-top: 116%;
    margin-left: 68%;
    width: 14%;
    height: 3%;
}
.weeklyIcon {
    width: 14%;
    margin-top: 116%;
    margin-left: 83%;
    z-index: 4;
    position: absolute;
    height: 3%;
}
.yearlyIcon {
    z-index: 4;
    position: absolute;
    margin-top: 116%;
    margin-left: 98%;
    width: 14%;
    height: 3%;
}
.graphIcon {
    width: 52%;
    z-index: 4;
    position: absolute;
    margin-top: 126%;
    margin-left: 57%;
}
.shareButtonIcon {
    width: 54%;
    z-index: 4;
    position: absolute;
    margin-top: 182%;
    margin-left: 57%;
}
.shareIcon {
    width: 3%;
    z-index: 5;
    position: absolute;
    margin-top: 185%;
    margin-left: 75%;
}
.shareButtonText {
    color: white;
    z-index: 4;
    position: absolute;
    margin-top: 184% !important;
    margin-left: 81% !important;
    font-size: 13px !important;
    font-weight: bolder !important;
}
.MuiTypography-root.weeksInfo.MuiTypography-body1 {
    width: 90%;
    z-index: 4;
    position: absolute;
    margin-top: 116%;
    color: white;
    margin-left: 84%;
    font-size: 8px;
    font-weight: bolder;
}
.MuiTypography-root.daysInfo.MuiTypography-body1 {
    width: 85%;
    z-index: 4;
    position: absolute;
    margin-top: 116%;
    color: #464141;
    margin-left: 70%;
    font-size: 8px;
    font-weight: bolder;
}
.MuiTypography-root.yearsInfo.MuiTypography-body1 {
    width: 90%;
    z-index: 5;
    color: #464141;
    position: absolute;
    margin-top: 116%;
    margin-left: 100%;
    font-size: 8px;
    font-weight: bolder;
}
.gpaListGrid {
    color: #464141;
    position: absolute;
    z-index: 4;
    margin-top: 124%;
    margin-left: 53%;
    display: block !important;
    font-size: 10px;
}

.gpaItemGrid{
    margin-bottom: 25px !important;
}
.dateGrid {
    color: #464141;
    position: absolute;
    z-index: 4;
    margin-top: 166%;
    margin-left: 56%;
    font-size: 8px;
}
.gpaInfoHeading {
    color: black;
    z-index: 4;
    position: absolute;
    margin-top: 175% !important;
    margin-left: 71% !important;
    font-size: 10px !important;
}
.gpaIcon {
    width: 3%;
    z-index: 4;
    position: absolute;
    margin-top: 175%;
    margin-left: 67%;
}
.benchmarkHeading {
    width: 3%;
    z-index: 4;
    position: absolute;
    margin-top: 175% !important;
    margin-left: 93% !important;
    font-size: 10px !important;
}
.benchmarkIcon {
    width: 3%;
    z-index: 4;
    position: absolute;
    margin-top: 175%;
    margin-left: 89%;
}
.batteryInfoIcon{
    width: 7%;
    margin-top: 314px;
    margin-left: 282px;
    z-index: 4;
    position: absolute;
    }

.MuiTypography-root.batteryIconHeading.MuiTypography-h6{
    width: 90%;
    z-index: 4;
    position: absolute;
    margin-top: 328px;
    color: white;
    margin-left: 20px;
    font-size: 17px;
    font-weight: bolder;

}
.batteryMeter{
    width: 116% !important;
    z-index: 4;
    position: absolute;
    margin-top: 426px;
    margin-left: -40px;
}
.batteryMeterIconLabel{
    margin-left:12px;
    font-size:10px;
    color:white;
    width:5%;
    display: inline;
}
.batteryMeterIconLabel1{
    margin-left: 4.5%;
    font-size: 10px;
    color: white;
    width: 5%;
    display: inline;
}
.onBThreeArrowLowerIcon{
    height: 21px;
    margin-left: -13px;
    margin-top: 1px;
}
.MuiTypography-root.onBThreeArrowSecondInfo.MuiTypography-body1 {
    color: white;
    font-size: 17px;
    font-weight: bolder;
    text-align: justify;
}

.footerOnBoardingOddThree{
    background-color: white;
    height: 100px;
    margin-top: 220px;
}
.MuiTypography-root.typographyTACThree.MuiTypography-body1{
    margin: 50px;
    color: #174120;
    text-decoration: underline;
    margin-left: 160px;
}
.MuiTypography-root.typographyPolicyThree.MuiTypography-body1{
    margin: 50px;
    color: #174120;
    text-decoration: underline;
    margin-left: 160px;
}
.MuiTypography-root.typographyContactThree.MuiTypography-body1{
    margin: 50px;
    color: #174120;
    text-decoration: underline;
    margin-left: 160px;
}
.typographyTACGrid{
    margin-top:5% !important;
}
.typographyPolicyGrid{
    margin-top:5% !important;
}
.typographyContactGrid{
    margin-top:5% !important;
}
@media screen and (min-width:1400px) and (max-width:1910px)
{
    
    .circleTopLeftImgThree {
        margin-left: -14%;
        margin-top: -18%;
        width: 70%;
        z-index: 1;
        position: absolute;
    }
    .windEverestLogoThree {
        margin-left: 11%;
        margin-top: 8%;
        width: 25%;
        z-index: 2;
        position: absolute;
    }
    .laptopImageThree {
        z-index: 2;
        width: 150%;
        position: absolute;
        top: 42%;
        left: 50%;
        transform: translate(-56%,-28%);
    }
    .rightCircleIconThree {
        width: 58%;
        margin-left: 62%;
        margin-top: -20%;
    }
    .MuiTypography-root.onBThreeArrowSecondInfo.MuiTypography-body1 {
        color: white;
        font-size: 1.2rem;
        font-weight: bolder;
        text-align: justify;
        width: 102%;
    }
    .popupVAGridThree {
        position: relative;
        margin-left: -11% !important;
        margin-top: -16% !important;
    }
    .daysIcon {
        z-index: 4;
        position: absolute;
        margin-top: 116%;
        margin-left: 68%;
        width: 14%;
        height: 2%;
    }
    .MuiTypography-root.daysInfo.MuiTypography-body1 {
        width: 85%;
        z-index: 4;
        position: absolute;
        margin-top: 116%;
        color: #464141;
        margin-left: 71%;
        font-size: 8px;
        font-weight: bolder;
    }
    .weeklyIcon {
        width: 14%;
        margin-top: 116%;
        margin-left: 83%;
        z-index: 4;
        position: absolute;
        height: 2%;
    }
    .yearlyIcon {
        z-index: 4;
        position: absolute;
        margin-top: 116%;
        margin-left: 98%;
        width: 14%;
        height: 2%;
    }
    
    .circleBottomLeftThree {
        width: 39%;
        margin-top: 70%;
        margin-left: 80%;
    }
    .footerOnBoardingOddThree {
        background-color: white;
        height: 100px;
        margin-top: 17%;
    }
    .onBoardingThreeLowerInfoGrid {
        margin-left: -17%;
        width: 18% !important;
        margin-top: 0%;
    }
    
}
@media screen and (min-width:900px) and (max-width:1200px)
{

    .laptopImageThree {
        z-index: 2;
        width: 150%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-56%,-24%);
    }
    .rightCircleIconThree {
        width: 65%;
        margin-left: 56%;
        margin-top: -22%;
    }
    .MuiTypography-root.onBThreeArrowSecondInfo.MuiTypography-body1 {
        color: white;
        font-size: 0.75rem;
        font-weight: bolder;
        text-align: justify;
        width: 96%;
    }
    .onBThreeArrowLowerIcon {
        height: 15px;
        margin-left: -13px;
        margin-top: 2px;
    }
    .popupVAPanelGridThree {
        width: 83%;
        margin-top: 108%;
        margin-left: 41%;
        z-index: 2;
        position: absolute;
    }
    .MuiTypography-root.daysInfo.MuiTypography-body1 {
        width: 85%;
        z-index: 4;
        position: absolute;
        margin-top: 116%;
        color: #464141;
        margin-left: 70%;
        font-size: 0.4rem;
        font-weight: bolder;
    }
    .MuiTypography-root.weeksInfo.MuiTypography-body1 {
        width: 90%;
        z-index: 4;
        position: absolute;
        margin-top: 117%;
        color: white;
        margin-left: 85%;
        font-size: 0.35rem;
        font-weight: bolder;
    }
    .popupVAPanelGridThree {
        width: 83%;
        margin-top: 108%;
        margin-left: 41%;
        z-index: 2;
        position: absolute;
    }
    .daysIcon {
        z-index: 4;
        position: absolute;
        margin-top: 115%;
        margin-left: 68%;
        width: 14%;
        height: 3%;
    }
    .MuiTypography-root.daysInfo.MuiTypography-body1 {
        width: 85%;
        z-index: 4;
        position: absolute;
        margin-top: 116%;
        color: #464141;
        margin-left: 70%;
        font-size: 0.4rem;
        font-weight: bolder;
    }
    .weeklyIcon {
        width: 14%;
        margin-top: 115%;
        margin-left: 83%;
        z-index: 4;
        position: absolute;
        height: 3%;
    }
    .MuiTypography-root.weeksInfo.MuiTypography-body1 {
        width: 90%;
        z-index: 4;
        position: absolute;
        margin-top: 115%;
        color: white;
        margin-left: 84%;
        font-size: 0.45rem;
        font-weight: bolder;
    }
    .yearlyIcon {
        z-index: 4;
        position: absolute;
        margin-top: 115%;
        margin-left: 98%;
        width: 14%;
        height: 3%;
    }
    .MuiTypography-root.yearsInfo.MuiTypography-body1 {
        width: 90%;
        z-index: 5;
        color: #464141;
        position: absolute;
        margin-top: 115%;
        margin-left: 100%;
        font-size: 0.45rem;
        font-weight: bolder;
    }
    .gpaListGrid {
        color: #464141;
        position: absolute;
        z-index: 4;
        margin-top: 116%;
        margin-left: 51%;
        display: block !important;
        font-size: 0.45rem;
    }
    .gpaListGrid {
        color: #464141;
        font-size: 0.45rem;
    }
    .gpaIcon {
        width: 3%;
        z-index: 4;
        position: absolute;
        margin-top: 176%;
        margin-left: 63%;
    }
    .gpaInfoHeading {
        color: black;
        z-index: 4;
        position: absolute;
        margin-top: 175% !important;
        margin-left: 68% !important;
        font-size: 0.55rem !important;
    }
    .benchmarkIcon {
        width: 3%;
        z-index: 4;
        position: absolute;
        margin-top: 176%;
        margin-left: 88%;
    }
    .benchmarkHeading {
        width: 3%;
        z-index: 4;
        position: absolute;
        margin-top: 175% !important;
        margin-left: 93% !important;
        font-size: 0.55rem !important;
    }
    .shareIcon {
        width: 3%;
        z-index: 5;
        position: absolute;
        margin-top: 185%;
        margin-left: 74%;
    }
    .shareButtonText {
        color: white;
        z-index: 4;
        position: absolute;
        margin-top: 183.3% !important;
        margin-left: 80% !important;
        font-size: 0.65rem !important;
        font-weight: bolder !important;
    }
    .MuiTypography-root.typographyTACThree.MuiTypography-body1 {
        margin: 50px;
            margin-left: 50px;
        color: #174120;
        text-decoration: underline;
        margin-left: 106px;
        width: 100%;
    }
    .footerOnBoardingOddThree {
        background-color: white;
        height: 30%;
        margin-top: 220px;
    }
}
@media screen and (min-width:1920px) and (max-width:2000px){
    .MuiTypography-root.onBThreeArrowSecondInfo.MuiTypography-body1 {
        color: #fff;
        font-size: 1.5rem;
        font-weight: bolder;
        text-align: justify;
        width: 97%;
    }
    .onBThreeArrowLowerIcon {
        height: 1.8rem;
        margin-left: -1rem;
        margin-top: 0.2rem;
    }
    .MuiTypography-root.weeksInfo.MuiTypography-body1 {
        width: 90%;
        z-index: 4;
        position: absolute;
        margin-top: 117%;
        color: #fff;
        margin-left: 86%;
        font-size: 0.6rem;
        font-weight: bolder;
    }
    .shareButtonText {
        color: #fff;
        z-index: 4;
        position: absolute;
        margin-top: 183.5% !important;
        margin-left: 81% !important;
        font-size: 1.3rem !important;
        font-weight: bolder !important;
    }
    .MuiTypography-root.yearsInfo.MuiTypography-body1 {
        width: 90%;
        z-index: 5;
        color: #464141;
        position: absolute;
        margin-top: 117%;
        margin-left: 102%;
        font-size: 0.6rem;
        font-weight: bolder;
    }
    .MuiTypography-root.daysInfo.MuiTypography-body1 {
        width: 85%;
        z-index: 4;
        position: absolute;
        margin-top: 117%;
        color: #464141;
        margin-left: 71%;
        font-size: 0.6rem;
        font-weight: bolder;
    }
    .footerOnBoardingOddThree {
        background-color: white;
        height: 100px;
        margin-top: 30%;
      }
    
}