.landingPageFooterContainer{
    margin: 0px !important;
    padding: 0px !important;
    width: 100% !important;
    max-width: 100% !important;   
}
.footerInfomation {
    z-index: 2;
    position: absolute;
    top: 29%;
    left: 41%;
    width: 48% !important;
}
.landingPageFooterContainer{
	position: relative;
}
.footerImageBackground {
    width: 100%;
    z-index: 1;
    position: relative;
}
.iconAppsGrids {
    width: 60%;
    margin-left: -5%;
}
.getAppGrid {
    width: 25%;
    margin-left: 1% !important;
}
.googleAppGrids {
    margin-right: 21% !important;
    width: 100%;
}
.googlePlayIconImg {
    width: 150%;
}
.appleStoreIconImg {
    width: 125%;
}
.MuiTypography-root.getAppTypo.MuiTypography-h6 {
    color: #063;
    font-size: 2.00rem;
    font-weight: bolder;
    margin-left: 28%;
    margin-top: -9%;
    width: 30% !important;
}
.MuiTypography-root.websiteInfo.MuiTypography-h6{
	margin-left: 10%;
	font-size: 2.15rem;
	margin-top: 2%;
	color: #595757;
}
.linksFooterSection{
    background-color: #1a1aee;
	color: white;
	height: 210px;
}
.linksFooters{
	margin-top: 5%;
	margin-left: 13%;
}
.MuiTypography-root.footerFAQ.MuiTypography-body1{
	margin-left: 75%;
	cursor: pointer;
}
.MuiTypography-root.footerAbout.MuiTypography-body1{
	margin-left:20%;
	cursor: pointer;
}
.footerHR{
	width: 70%;
    margin-top: -20px;
    margin-bottom: 20px;
    opacity: 1.0;
    height: 0px;
    margin-left: 16%;
    color: white;
}
.MuiTypography-root.footerPrivacy.MuiTypography-body1{
	margin-left: 16%;
	cursor: pointer;
}
.MuiTypography-root.footerTC.MuiTypography-body1{
	margin-left: 25%;
	cursor: pointer;
}

.MuiTypography-root.footerCopyright.MuiTypography-body1{
	margin-left:35%;
	cursor: pointer;
}
.MuiTypography-root.footerContactUS.MuiTypography-body1{
	cursor: pointer;
	margin-left: 10%;
}
@media screen and (min-width:1400px) and (max-width:1950px)
{
	.MuiTypography-root.websiteInfo.MuiTypography-h6 {
		margin-left: 7%;
		font-size: 2.65rem;
		margin-top: 2%;
		color: #595757;
	}
	.MuiTypography-root.getAppTypo.MuiTypography-h6 {
		color: #063;
		font-size: 2.65rem;
		font-weight: bolder;
		margin-left: 23%;
		margin-top: -9%;
		width: 40% !important;
	}
	.footerImageBackground {
		width: 101%;
		z-index: 1;
		position: relative;
	}
	.MuiTypography-root.footerFAQ.MuiTypography-body1 {
		margin-left: 67%;
		cursor: pointer;
	}
	.MuiTypography-root.footerTC.MuiTypography-body1 {
		margin-left: 29%;
		cursor: pointer;
	}
	.footerHR {
		width: 68%;
		margin-top: -20px;
		margin-bottom: 20px;
		opacity: 1.0;
		height: 0px;
		margin-left: 16%;
		color: white;
	}
}
@media screen and (min-width:960px) and (max-width:1200px)
{	
	.getAppGrid {
		width: 25%;
		margin-left: -5% !important;
	}
	.MuiGrid-root.continueGrid.MuiGrid-grid-xs-12.MuiGrid-grid-md-12 {
		margin-left:-10% !important;
	}
    .MuiTypography-root.websiteInfo.MuiTypography-h6 {
    margin-left: 18%;
    font-size: 2.25rem;
    margin-top: 2%;
    color: #595757;
}
.MuiTypography-root.getAppTypo.MuiTypography-h6 {
    color: #063;
    font-size: 2.10rem;
    font-weight: bolder !important;
    margin-left: 33%;
    margin-top: -9%;
    width: 44% !important;
}
	.MuiTypography-root.footerTC.MuiTypography-body1 {
		margin-left: 25%;
		cursor: pointer;
		width: 105% !important;
	}
	.MuiTypography-root.footerFAQ.MuiTypography-body1 {
		margin-left: 67%;
		cursor: pointer;
	}
	.MuiTypography-root.footerTC.MuiTypography-body1 {
		margin-left: 29%;
		cursor: pointer;
	}
	.footerHR {
		width: 68%;
		margin-top: -20px;
		margin-bottom: 20px;
		opacity: 1.0;
		height: 0px;
		margin-left: 16%;
		color: white;
	}
}