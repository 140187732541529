.aboutUsBodyContainer {
  display: flex;
  flex-direction: row;
  gap: 30px;
}
@media only screen and (max-width:600px)  {
  .aboutUsBodyContainer {
    display: "flex";
    flex-direction: column;
    gap: 14;
    width:100%;
  }
  .MuiContainer-root.MuiContainer-maxWidthXl{
    padding-right:10px;
  }
    
  .imgContainer {
        width: "100%";
        height: "100px";
        border-radius: 10;
        object-fit: "cover";
        display: "flex";
        flex-direction: "row";
      }    
}

