.react-datepicker{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: Arial, Helvetica, sans-serif;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0,0,0,0.2);
    padding:10px;
}

.react-datepicker-wrapper {
    width: 139% !important;
    margin-bottom: 21% !important;
    border: none !important;
    margin-left: -82% !important;
}
.react-datepicker__current-month,.react-datepicker__navigation{
    flex:1;
    text-align: center;
}
.react-datepicker__navigation--previous{
    margin-right: 20px;
}
.react-datepicker__navigation--next{
    margin-left: 20px;
}
.react-datepicker__input-container input {
    padding: 10px 14px !important;
    width: 200% !important;
    background-color: rgba(0,0,0,0.10);
    border-radius: 10px !important;
    height: 35px;
    box-shadow: none !important;
    border: none !important;
    color: #5b5959;
}
.custom-datepicker {
    font-family: Arial, sans-serif;
    font-size: 14px;
  }
  
  .react-datepicker__month-container {
    background-color: #f2f2f2;
  }
  
  .react-datepicker__current-month {
    text-align: left;
  }
  
  .react-datepicker__navigation {
    top: 8px;
    right: 8px;
  }
  
  .react-datepicker__month-text,
  .react-datepicker__day-name,
  .react-datepicker__day {
    color: #333333;
  }
  
  .react-datepicker__day-name {
    background-color: #d8d8d8;
  }
  .react-datepicker__navigation {
    top: 8px;
    right: 8px;
    border: none;
    background-color: transparent;
  }
  
  .react-datepicker__navigation--previous::before {
    content: "\2190";
    font-size: 14px;
  }
  
  .react-datepicker__navigation--next::before {
    content: "\2192";
    font-size: 14px;
  }
  
  .react-datepicker__day-name,
  .react-datepicker__day {
    background-color: #d8d8d8;
  }

  .react-datepicker__navigation--previous {
    left: 200px;
}
.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
    margin-top: 0;
    color: #686565de;
    font-size: 12px;
    margin-left: 12px;
    font-weight: bolder;
}
.react-datepicker {
    font-family: "Helvetica Neue", helvetica, arial, sans-serif;
    font-size: 0.8rem;
    color: #666464;
    background-color: #cec9c9 !important;
}
.react-datepicker__day-names {
    margin-bottom: -8px;
    background-color: #9d9b9b;
}
.react-datepicker__month-text, .react-datepicker__day-name, .react-datepicker__day {
    color: #a49f9f;
}
.react-datepicker__day-name, .react-datepicker__day {
    background-color: #f0eaea !important;
}
.react-datepicker {
    font-family: "Helvetica Neue", helvetica, arial, sans-serif;
    font-size: 0.8rem;
    color: #e3e1e1;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    display: inline-block;
    position: relative;
    background-color: #f9f3f3 !important;
}
.react-datepicker__day-name {
    background-color: #9f9b9b !important;
}
.react-datepicker__month-text, .react-datepicker__day-name, .react-datepicker__day {
    color: #7d7979;
}
.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before {
    border-color: #ccc;
    border-style: solid;
    border-width: 3px 3px 0 0;
    content: "";
    display: block;
    height: 5px;
    position: absolute;
    top: 6px;
    width: 5px;
}
.react-datepicker__day--selected {
    background-color: #5cb85c;
    color: white;
    border-radius: 50%;
  }

  
@media screen and (min-width:1400px) and (max-width:1920px){
    .react-datepicker-wrapper {
        width: 100% !important;
        margin-bottom: 21% !important;
        border: none !important;
    }
    .react-datepicker__input-container input {
      padding: 10px 14px !important;
      width: 200% !important;
      background-color: rgba(0,0,0,0.10);
      border-radius: 10px !important;
      height: 35px;
      box-shadow: none !important;
      border: none !important;
      color: #5b5959;
      font-size: 0.95rem !important;
  }
  }

@media screen and (min-width:960px) and (max-width:1200px){
  .react-datepicker-wrapper {
    width: 190% !important;
    margin-bottom: 21% !important;
    border: none !important;
    margin-left: -103% !important;
}
}