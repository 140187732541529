
.createNewPasswordContainer{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: "row";
    overflow: hidden;
}
::-ms-reveal{display: none;}
.leftBlock{
    flex: 0.5;
    height: 100%;
    background-color: white;
}

.leftBlockImg{
    height: 100%;
    width: 100%;
}

.rightBlock{
    flex: 0.5;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.rightBlock img{
    width: 200px;
    height: 150px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    margin-bottom: 20px;
    margin-top: 10%;
}

.heading{
    color: #336699;
    font-size: 10px;
    margin-bottom: 45px!important;
    margin-top: -35px;
    font-style: normal;
}

.loginForm{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.newpasswordformGroup{
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 5px;
    margin-bottom: 10px;
}

.formGroup label{
    font-size: 18px;
}

.formGroup input{
    height: 30px;
    font-size: 18px;
    border-radius: 50px;
    outline: none;
    border: 1px solid black;
    padding-left: 10px;
}

.loginForgetpasswordContainer{
    display: flex;
    width: 50%;
}

.loginForgetpasswordContainer p{
    display: inline;
    margin-left: auto;
    text-decoration: underline;
    color: green;
}

.createNewbtn{
    margin-top: 10px!important;
    margin-bottom: 20px!important;
    width: 50%;
    height: 30px;
    border: none;
    background-color: #006633!important;
    color: white!important;
    font-weight: bold!important;
    border-radius: 20px!important;
    cursor: pointer!important;
    text-transform: none!important;

}

.btn:hover{
    opacity: 0.8;
}

.btnContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    gap: 10px;
    background-color: white;
    /* border: 1px solid black; */
    width: 50%;
    height: 30px;
    border-radius: 50px;
    margin-bottom: 20px;
    -webkit-box-shadow: -2px 0px 9px 3px rgba(112,108,112,1);
-moz-box-shadow: -2px 0px 9px 3px rgba(112,108,112,1);
box-shadow: -2px 0px 9px 3px rgba(112,108,112,1);
cursor: pointer;
    
}

.btnContainer:hover{
    opacity: 0.8;
}

.btnContainer img{
    width: 20px;
    height: 20px;
    object-fit: cover;
    margin: 0;
    padding: 0;
}


.signupOption span{
    color: #336699;
}

.inputWithIcon{
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid black;
    height: 30px;
    width: 100%;
    justify-content: space-evenly;
    border-radius: 50px;

}
.inputWithIcon input{
    /* border: none; */
    margin: 0;
    padding: 0;
    height: 28px;
    border: none;
    margin-left: 10px;
    outline: none;
    font-size: 18px;
    width: 90%;
}

.inputWithIcon img{
    width: 30px;
    height: 25px;
    margin: 0;
    padding: 0;
    padding-right: 5px;
    cursor: pointer;
}

@media screen and  ( max-width: 641px) {
    .createNewPasswordContainer{
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        overflow: scroll;
    }
    
    .rightBlock img{
        width: 170px!important;
        height: 130px!important;
        margin-left: auto;
        margin-right: auto;
        margin-top: 0px!important;
        margin-bottom: 0px!important;
    }
    .leftBlock{
        background-color: white;
    }
    .leftBlockImg{
        height: 300px!important;
        width: 100%;
        object-fit: fill!important;
    }
    .newpasswordformGroup{
        width: 80%;
    }
    .createNewbtn{
        width: 80%;
    }
    .Heading{
        margin-top: 0px!important;
    }
    .loginForm{
        margin-bottom: 30px!important;
    }
}