
.container{
    width: 100vw;
    height: 100vh;
    overflow: scroll;
}

.header{
    width: 100%;
    height: 100px;
    margin-top: 10px;
}

.header img{
    width: 150px;
    margin-left: 5%;
}

.bodyContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.bodyContainer img{
    width: 200px;
    margin-bottom: 40px;
}

.bodyContainer h2{
    color: #336699;
    font-weight: bold;
    margin-bottom: 5px;
}

.bodyContainer p{
    opacity: 0.7;
    margin-bottom: 20px;
}

.btn{
    margin-top: 20px;
    margin-bottom: 20px;
    width: 300px;
    height: 30px;
    border: none;
    background-color: green;
    color: white;
    font-weight: bold;
    border-radius: 50px;
    cursor: pointer;
}

.btn:hover{
    opacity: 0.9;
}

.bodyContainer span{
    color: #336699;
    text-decoration: underline;
    cursor: pointer;
}

.resendtext{
    color: #336699;
    text-decoration: underline;
    display: inline;
    cursor: pointer;
}
.bottomResend{
    font-size:12px;
}

@media screen and  ( max-width: 641px) {
    .desverify{
        width: 80%!important;
    }
    .bodyContainer p{
        width: 80%!important;
        text-align: center;
    }
    .butnforgetpassword{
        width: 80%;
    }
}