.getInTouch {
    color: #336699 !important;
    font-weight: bold !important;
    margin-top: 40px !important;
    font-size: 26px !important;
  }

  .containerLeft{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    border-radius: 15px !important;
    padding-bottom: 30px;
  }

  .bodyContainers{
    display: flex !important;
    flex-direction: row !important;
    justify-content: start;
    gap: 20px;
    margin-top: 30px;
  }

  .containerRight{
    flex: 0.8;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    border-radius: 20px;
  }
  .gridsm{
    display: flex;
    justify-content: center;
    margin-right: -35px !important;
  }

  @media screen and (min-width: 600px) and (max-width: 680px) {
    .getInTouch {
       font-size: 18px!important;
      }
    
  }

  @media screen and (min-width: 960px) and (max-width: 1200px) {
    .containerLeft{
width: 90%!important;
    }
    
  }


  @media screen and (max-width: 959px) {
    .gridsm{
display: flex;
/* justify-content: center; */
    }

    .bodyContainers{
justify-content: center;
    }
    
  }

  @media screen and (max-width: 599px) {
    .bodyContainer{
        display: flex;
        flex-direction: column;
    }
    .containerLeft{
        width: 100%!important;
            }
  }