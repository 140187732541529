.landingPage6Container{
    margin: 0px !important;
    padding: 0px !important;
    width: 100% !important;
    max-width: 100% !important;  
}
.MuiAccordion-root:before {
    display: none;
}

.MuiAccordion-rounded {
    margin-bottom: 10px;
}
.MuiAccordionSummary-expandIcon.Mui-expanded {
    transform: rotate(-90deg)!important;
}

.MuiAccordionDetails-root {
    padding-left: 50px!important;
    padding-top: 0px!important;
}
.MuiTypography-root.faqHeading.MuiTypography-h5 {
    color: rgb(51, 102, 153);
    font-weight: bolder;
    margin-top: 15%;
    margin-left: 36%;
    margin-bottom: 4%;
    font-size: 2.45rem;
}
@media only screen and (max-width:600px)  {
    .questionIconResponsive {
        width: 2.5rem;
        height: 2.5rem;
        color: rgb(0, 102, 51);
        margin-right: 10px;
      }    
}
@media screen and (min-width:960px) and (max-width:1200px)
{
    .MuiTypography-root.faqHeading.MuiTypography-h5{
        color: rgb(51, 102, 153);
        font-weight: bolder;
        margin-top: 15%;
        margin-left: 35%;
        margin-bottom: 4%;
        font-size: 2.45rem;
    }   

}
@media screen and (min-width:1400px) and (max-width:1925px)
{
    .MuiTypography-root.faqHeading.MuiTypography-h5 {
        color: rgb(51, 102, 153);
        font-weight: bolder;
        margin-top: 15%;
        margin-left: 39%;
        margin-bottom: 4%;
        font-size: 2.45rem;
    }
}