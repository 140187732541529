.landingPage5Container{
    margin: 0px !important;
    padding: 0px !important;
    width: 100% !important;
    max-width: 100% !important;  
}
.MuiTypography-root.privacyHeading.MuiTypography-h5 {
    color: rgb(51, 102, 153);
    font-weight: bolder;
    margin-top: 15%;
    margin-left: 27%;
    margin-bottom: 4%;
    font-size: 2.45rem;
  }
@media screen and (min-width:960px) and (max-width:1100px)
{
  .MuiTypography-root.privacyHeading.MuiTypography-h5 {
        color: rgb(51, 102, 153);
        font-weight: bolder;
        margin-top: 15%;
        margin-left: 28%;
        margin-bottom: 4%;
        font-size: 2.45rem;
      }

}
@media screen and (min-width:1100px) and (max-width:1200px)
{
    .MuiTypography-root.privacyHeading.MuiTypography-h5 {
        color: rgb(51, 102, 153);
        font-weight: bolder;
        margin-top: 15%;
        margin-left: 30%;
        margin-bottom: 4%;
        font-size: 2.45rem;
      }

}
@media screen and (min-width:1400px) and (max-width:1925px)
{
  .MuiTypography-root.privacyHeading.MuiTypography-h5 {
    color: rgb(51, 102, 153);
    font-weight: bolder;
    margin-top: 15%;
    margin-left: 32%;
    margin-bottom: 4%;
    font-size: 2.45rem;
}
}