.rootGroups {
  padding-right: 60px !important;
  margin-left: 25px !important;
}
.tabGridBox {
  position: relative;
  padding-top: 20px;
  margin-left: 75px;
}
.mainContainerGrid{
width: 100%;
display: flex;
flex-wrap: wrap;
box-sizing: border-box;
margin-left: -35px;
}
.tabVABox {
margin-left: 130px !important;
padding-top: 40px !important;
}
.MuiTypography-root.yourGPALabelGroups.MuiTypography-body1{
width: 100%;
margin-left: -140px;	
}
.MuiTypography-root.benchmarkLabelGroups.MuiTypography-body1{
margin-left:-140px;
}
.labelsforGroups{
    width: 55%;
  margin-left: -40px;
}
.blueLabelGroups{
width:10%;
}
.orangeLabelGroups{
width:10%;
}
.activeVATab {
min-width: 20px !important;
min-height: 20px !important;
background-color: #369 !important;
color: white !important;
border-radius: 30px !important;
text-transform: none !important;
width: 23% !important;
height: 5% !important;
display: inline-block;
font-size: 10px !important;
opacity: 1.0 !important;
margin-right: 5px !important;
}
.inactiveVATab{
min-width: 20px !important;
min-height: 20px !important;
background-color: #cac5c5 !important;
color: #4b4949 !important;
border-radius: 30px !important;
text-transform: none !important;
width: 23% !important;
height: 5% !important;
display: inline-block;
font-size: 10px !important;
opacity: 1.0 !important;
margin-right: 5px !important;
}
.activeGroupStatTab {
background-color: #369 !important;
color: white !important;
border-radius: 30px !important;
z-index: 2 !important;
position: absolute !important;
text-transform: none !important;
width: 60%;
opacity: 1 !important;
margin-left: -57px !important;
}
.inactiveGroupStatTab {
background-color: #cac5c5 !important;
color: #4b4949 !important;
border-radius: 30px !important;
z-index: 1 !important;
position: relative;
margin-left: -56px !important;
margin-right: 25px !important;
text-transform: none !important;
width: 55% !important;
opacity: 1 !important;
}
.activeGroupChatTab {
background-color: #369 !important;
color: white !important;
border-radius: 30px !important;
z-index: 2 !important;
position: absolute !important;
text-transform: none !important;
width: 62%;
opacity: 1 !important;
margin-left: -57px !important;
}
.inactiveGroupChatTab {
background-color: #cac5c5 !important;
color: #4b4949 !important;
border-radius: 30px !important;
z-index: 1 !important;
position: absolute;
margin-left: 136px !important;
width: 61% !important;
text-transform: none !important;
opacity: 1 !important;
}
.leftFilterContainer{
  max-height: 600px;
}
.leftFilterClosedContainer{
  height:auto;
  border-radius: 20px !important;
  padding-bottom: 60px;
}
.filterItemHeading{
  width: 90% !important;
  margin-left: 25px !important;
  margin-top: 15px !important;
  font-weight: bolder;
  cursor: pointer;
}
.hrFilterLine {
  width: 85% !important;
  margin-left: 25px !important;
  margin-top: 5px;
  opacity: 0.1;
  margin-bottom: -10px !important;
}
.applyFilterButton {
  background-color: #006633 !important;
  color: white !important;
  font-weight: bolder !important;
  border-radius: 20px !important;
  width: 90%;
  margin-left: 25px !important;
  margin-top: 50px !important;
}
.filterFieldBox {
width: 100%;
margin-left: 30px;
margin-top: 20px;
}

.root {
  padding: 5px;
}

.root:hover {
  background-color: transparent;
}
.commentIconChatLength {
    margin-left: 44%;
    position: relative;
    top: 24px;
    left: 9px;
}

.icon {
  border: 2px solid #336699;
  border-radius: 3px;
  width: 10px;
  height: 10px;
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
  background-color: #ffffff;
  background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.8), hsla(0, 0%, 100%, 0));
}

.root.Mui-focusVisible .icon {
  outline: 2px auto rgba(19, 124, 189, 0.6);
  outline-offset: 2px;
}

input:hover ~ .icon {
  background-color: #ebf1f5;
}

input:disabled ~ .icon {
  box-shadow: none;
  background: rgba(206, 217, 224, 0.5);
}

.checkedIcon {
  background-color: #137cbd;
  background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.1), hsla(0, 0%, 100%, 0));
  border: 2px solid #336699;
  border-radius: 3px;
  width: 10px;
  height: 10px;
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
  
}

.checkedIcon:before {
  display: block;
  width: 10px;
  height: 10px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E");
  content: "";
}

input:hover ~ .checkedIcon {
  background-color: #106ba3;
}

.MuiSelect-select:focus .checkedIcon {
  background-color: none;
}

.scrollBarColor::-webkit-scrollbar {
  width: 10px;
}

.scrollBarColor::-webkit-scrollbar-thumb {
  color: #336699;
}
.boxStatFilterItems {
  margin-left: 30px;
}
.statCheckItem {
  display: block !important;
  cursor: pointer;
}
.chatFilterItem .Mui-checked .MuiSvgIcon-root {
  color: #369;
  cursor: pointer;
}
.boxChatFilterItems{
  margin-left: 30px;
}
.MuiTypography-root.filterTextBox.MuiTypography-body1{
width: 50% !important;
display: inline-block;
margin-left: 10px;
cursor: pointer;
}
.filterImage {
width: 3% !important;
display: inline-block;
cursor: pointer;
}
.getInTouch {
    color: #336699 !important;
    font-weight: bold !important;
    margin-top: 40px !important;
    font-size: 26px !important;
  }
  .groupsHeading{
    font-weight: bold;
    color: #336699;
    margin-top:72px !important; 
    font-size:16px;
    
  }
  .MuiTypography-root.noPostTypography.MuiTypography-body1 {
    font-size: 18px;
    font-weight: bold;
    color: #6c6969;
    margin-left: 15%;
}
  .groupsContainers{
    display: flex !important;
    flex-direction: row !important;
    justify-content: start;
    gap: 20px;
    margin-top: 30px;
  }
  .groupsLeft{
    flex: 0.8;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    margin-left:20px;
    margin-top:22px;
    margin-right: -90px !important;
  }
  .groupsRight{
    flex: 0.8;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    margin-left:65px !important;
    margin-top:22px;
  }
  .groupsTabs {
      display: "flex";
      flex-direction: "row";
      justify-content: "center";
      margin-bottom: "25px";
      position:"relative"; 
  }
  .groupChatsPaper{
    border-radius: 30px !important;
    margin-left: 30px !important;
  }
  .openedGroupChats{
    border-radius: 30px !important;
    margin-left: 30px !important;
  }
  .rightStatContainerPaper{
    border-radius: 30px !important;
    margin-left: 30px !important;
    height:500px;
  }
  .gpaChartBox{
    position:relative;
  }
  .MuiTypography-root.gpaBarValue.MuiTypography-h6{
    margin-left: -279px;
    font-size: 10px;
    z-index: 3;
    position: absolute;
    margin-top: 10px;
  }
 .MuiTab-root.groupStatsOn {
  padding: 10px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  background-color: rgb(35, 105, 174);
  border: medium none;
  border-radius: 42px;
  width: 50%;
  text-transform: none;
  z-index: 2;
  position: absolute;
  margin-right: 200px;
  opacity:1;
 }
.MuiTab-root.groupsChatsOff {
  padding: 10px;
  color: rgb(75, 73, 73);
  font-weight: bold;
  font-size: 18px;
  background-color: rgb(190, 188, 188);
  border: medium none;
  border-radius: 35px;
  width: 50%;
  text-transform: none;
  z-index: 1;
  position: absolute;
  margin-left: 200px;
  opacity: 1;
 }
 .filterImage{
  width:10%;
  cursor: pointer;
 }
 .keyboardIconComments{
  margin-top: 20px;
  margin-left: 20px;
  margin-bottom: 15px;
 }
 .expandedPostBox{
  margin-left: 10px;
 }
.MuiTypography-root.groupnameField.MuiTypography-body1{
display: inline;
color: #15550cf0;
font-weight: bolder;
}
.MuiTypography-root.usernameField.MuiTypography-body1{
width: 50%;
margin-left: 30px;
display: inline-block;
font-weight:bolder;
}
.MuiTypography-root.creationUserField.MuiTypography-body1{
width: 50%;
display: inline-block;
margin-left: 30px;
}
.MuiTypography-root.userPostField.MuiTypography-body1{
width: 66%;
margin-left: 33px;
margin-top: 12px;
}
.MuiTypography-root.commentCountField.MuiTypography-body1{
margin-left: 53%;
margin-top: 2px;
font-size: 13px;
}
.addCommentField.MuiInput-underline::before{
border-bottom: none;
}
.addCommentField.MuiInput-underline::after{
border-bottom: none;
}
.hrDivider {
width: 65%;
background-color: lightgray !important;
height: 1px !important;
margin-left: 30px !important;
margin-top: 10px !important;
margin-bottom: 10px !important;
}
.hrShareDivider {
width: 100%;
background-color: rgb(126, 124, 124) !important;
height: 3px !important;
margin-left: 0px !important;
margin-top: 5px !important;
margin-bottom: 10px !important;
}
.MuiFormControl-root.MuiTextField-root.addCommentField{
margin-left: 47px;
width: 73%;
background-color: #eeecec;
border-radius: 20px;
margin-top: 20px;
color: #6c6969;
margin-bottom: 17px;
}
.MuiFormControl-root.MuiTextField-root.addCommentFieldOpenChat{
  margin-left: 47px;
  width: 73%;
  background-color: #eeecec;
  border-radius: 20px;
  margin-top: 20px;
  color: #6c6969;
  margin-bottom: 17px;
}
.postBoxes{
border-bottom: 3px solid lightgray;
}
.emptyRightSide{
height:600px;
}
  .addCommentField.MuiInputBase-root.MuiInput-root.MuiInput-underline::before{
  border-bottom: none;
  }
  .addCommentField.MuiInputBase-root.MuiInput-root.MuiInput-underline::after{
  border-bottom: none;
  }
  .addCommentFieldOpenChat.MuiInputBase-root.MuiInput-root.MuiInput-underline::before{
  border-bottom: none;
  }
  .addCommentFieldOpenChat.MuiInputBase-root.MuiInput-root.MuiInput-underline::after{
  border-bottom: none;
  }
.shareInputField.MuiInputBase-root.MuiInput-root.MuiInput-underline::before{
border-bottom: none;
}
.shareInputField.MuiInputBase-root.MuiInput-root.MuiInput-underline::after{
border-bottom: none;
}
.MuiInputBase-root.MuiInput-root.MuiInput-underline.shareInputField{
margin-left: 51px;
width: 73%;
background-color: #eeecec;
border-radius: 20px;
margin-top: 20px;
color: #6c6969;
margin-bottom: 17px;
}
.MuiTypography-root.topCommentHeading.MuiTypography-body1 {
font-size: 16px;
font-weight: bolder;
margin-left: 40px;
margin-top: 20px;
}
.MuiTypography-root.topCommentsUserName.MuiTypography-body1{
width: 50%;
margin-left: 42px;
display: inline-block;
font-weight:bolder;
}
.MuiTypography-root.topUserComment.MuiTypography-body1{
width: 66%;
margin-left: 42px;
margin-top: 0px;
}
.MuiTypography-root.topUserCommentCreated.MuiTypography-body1{
width: 50%;
display: inline-block;
margin-left: 42px;
}
.gpaChartContainer{
width:420px;
height:220px;
}
.rightChatContainer {
  overflow-x: hidden;
  scrollbar-width: thin;
  max-height: 875px;
  scrollbar-color: transparent transparent;
}

.rightChatContainer::-webkit-scrollbar {
  width: 6px;
}

.rightChatContainer::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.rightChatContainer::-webkit-scrollbar-track {
  background-color: transparent;
}
@media screen and (min-width:310px) and (max-width:900px){
.leftFilterClosedContainer {
  height: 140px;
  border-radius: 20px !important;
  width: 130%;
}
.labelsforGroups {
width: 83%;
margin-left: -20px;
}
.gpaChartContainer{
width:auto;
height:220px;
}
.inactiveGroupChatTab {
  background-color: #cac5c5 !important;
  color: #4b4949 !important;
  border-radius: 30px !important;
  z-index: 1 !important;
  position: absolute;
  margin-left: 60px !important;
  width: 68% !important;
  text-transform: none !important;
  opacity: 1 !important;
}
.activeGroupStatTab {
  background-color: #369 !important;
  color: white !important;
  border-radius: 30px !important;
  z-index: 2 !important;
  position: absolute !important;
  text-transform: none !important;
  width: 70%;
  opacity: 1 !important;
  margin-left: -66px !important;
}
.activeGroupChatTab {
  background-color: #369 !important;
  color: white !important;
  border-radius: 30px !important;
  z-index: 2 !important;
  position: absolute !important;
  text-transform: none !important;
  width: 73%;
  opacity: 1 !important;
  margin-left: -60px !important;
}

.inactiveGroupStatTab {
  background-color: #cac5c5 !important;
  color: #4b4949 !important;
  border-radius: 30px !important;
  z-index: 1 !important;
  position: relative;
  margin-left: -65px !important;
  margin-right: 25px !important;
  text-transform: none !important;
  width: 67% !important;
  opacity: 1 !important;

}
.filterImage {
  width: 5% !important;
  display: inline-block;
  cursor: pointer;
}
.groupChatsPaper {
  border-radius: 30px !important;
  margin-left: 0px !important;
  width: 130% !important;
  margin-top: 30px !important;
}
.openedGroupChats {
  border-radius: 30px !important;
  margin-left: 0px !important;
  width: 130% !important;
  margin-top: 30px !important;
}
.MuiInputBase-root.MuiInput-root.MuiInput-underline.shareInputField {
  margin-left: 40px;
  width: 73%;
  background-color: #eeecec;
  border-radius: 20px;
  margin-top: 20px;
  color: #6c6969;
  margin-bottom: 17px;
}
.MuiFormControl-root.MuiTextField-root.addCommentField {
  margin-left: 40px;
  width: 73%;
  background-color: #eeecec;
  border-radius: 20px;
  margin-top: 20px;
  color: #6c6969;
  margin-bottom: 17px;
  border-bottom: none !important;
}
.postBoxes {
  border-bottom: 3px solid lightgray;
  margin-top: 20px;
}
.rightStatContainerPaper {
border-radius: 30px !important;
margin-left: 0px !important;
height: 400px;
width: 73% !important;
margin-top: 30px;
}
.tabVABox {
  margin-left: 20px !important;
  padding-top: 40px !important;
}
.activeVATab {
  min-width: 20px !important;
  min-height: 20px !important;
  background-color: #369 !important;
  color: white !important;
  border-radius: 30px !important;
  text-transform: none !important;
  width: 30% !important;
  height: 5% !important;
  display: inline-block;
  font-size: 10px !important;
  opacity: 1.0 !important;
  margin-right: 5px !important;
}
.inactiveVATab {
  min-width: 20px !important;
  min-height: 20px !important;
  background-color: #cac5c5 !important;
  color: #4b4949 !important;
  border-radius: 30px !important;
  text-transform: none !important;
  width: 30% !important;
  height: 5% !important;
  display: inline-block;
  font-size: 10px !important;
  opacity: 1.0 !important;
  margin-right: 5px !important;
}
}
@media screen and (min-width:1390px) and (max-width:1925px){
  .tabGridBox {
    position: relative;
    padding-top: 5%;
    margin-left: 10%;
}
  .activeGroupStatTab {
    background-color: #369 !important;
    color: white !important;
    border-radius: 30px !important;
    z-index: 2 !important;
    position: absolute !important;
    text-transform: none !important;
    width: 60% !important;
    opacity: 1 !important;
    margin-left: -7% !important;
    max-width: 100% !important;
    font-size: 1.0rem !important;
    font-weight: bolder !important;
}
.activeGroupStatTab span.MuiTab-wrapper {
  width: 100%;
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-right: 40%;
}
.inactiveGroupChatTab {
  background-color: #cac5c5 !important;
  color: #4b4949 !important;
  border-radius: 30px !important;
  z-index: 1 !important;
  position: absolute;
  margin-left: 37% !important;
  width: 60% !important;
  text-transform: none !important;
  opacity: 1 !important;
  max-width: 100% !important;
  font-size: 1.0rem !important;
}
.inactiveGroupChatTab span.MuiTab-wrapper {
  width: 100%;
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-left: 40%;
}
.activeGroupChatTab {
  background-color: #369 !important;
  color: white !important;
  border-radius: 30px !important;
  z-index: 2 !important;
  position: absolute !important;
  text-transform: none !important;
  width: 60%;
  opacity: 1 !important;
  margin-left: -16% !important;
  max-width: 100% !important;
  font-size: 1.0rem !important;
  font-weight: bolder !important;
}
.activeGroupChatTab span.MuiTab-wrapper {
  width: 100%;
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-left: 40%;
}
.inactiveGroupStatTab {
  background-color: #cac5c5 !important;
  color: #4b4949 !important;
  border-radius: 30px !important;
  z-index: 1 !important;
  position: relative;
  margin-left: -7% !important;
  margin-right: 0px !important;
  text-transform: none !important;
  width: 60% !important;
  opacity: 1 !important;
  max-width: 100% !important;
  font-size: 1.0rem !important;
}
.inactiveGroupStatTab span.MuiTab-wrapper {
  width: 100%;
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-right: 40%;
}
.filterFieldBox {
  width: 100%;
  margin-left: 5%;
  margin-top: 3%;
  margin-bottom: 5%;
}
.MuiTypography-root.filterTextBox.MuiTypography-body1 {
  width: 50% !important;
  display: inline-block;
  font-size: 1.2rem;
  margin-bottom: -6.5%;
  cursor: pointer;
  margin-left: 3%;
}
.filterImage {
  width: 4% !important;
  display: inline-block;
  cursor: pointer;
  margin-top: 2%;
}
.filterItemHeading {
    width: 90% !important;
    margin-left: 4% !important;
    margin-top: 2% !important;
    font-weight: bolder !important;
    cursor: pointer;
}
.checkBoxText {
    font-size: 0.8rem !important;
    font-weight: bolder !important;
}
.tabVABox {
  margin-left: 24% !important;
  padding-top: 9% !important;
  width: 90% !important;
}
.activeVATab {
  min-width: 20px !important;
  min-height: 20px !important;
  background-color: #369 !important;
  color: white !important;
  border-radius: 30px !important;
  text-transform: none !important;
  width: 23% !important;
  height: 5% !important;
  display: inline-block;
  font-size: 0.75rem !important;
  opacity: 1.0 !important;
  margin-right: 1% !important;
  font-weight: bolder !important;
}
.inactiveVATab {
  min-width: 20px !important;
  min-height: 20px !important;
  background-color: #cac5c5 !important;
  color: #4b4949 !important;
  border-radius: 30px !important;
  text-transform: none !important;
  width: 23% !important;
  height: 5% !important;
  display: inline-block;
  font-size: 0.75rem !important;
  opacity: 1.0 !important;
  margin-right: 1% !important;
}
.gpaChartContainer {
  width: 100%;
  height: 65%;
  max-width: 100% !important;
  max-height: 100% !important;
  margin-top: 7%;
  margin-bottom: 7%;
}
.labelsforGroups {
  width: 70%;
  margin-left: -4%;
}
.MuiTypography-root.yourGPALabelGroups.MuiTypography-body1 {
  width: 100%;
  margin-left: -30%;
  font-size: 0.90rem;
}
.blueLabelGroups {
    width: 10%;
    margin-left: 32% !important;
}
.orangeLabelGroups {
    width: 10%;
    margin-left: -14% !important;
    
}
.orangeLabelGroups {
    width: 10%;
    margin-left: -14% !important;
}
.MuiTypography-root.benchmarkLabelGroups.MuiTypography-body1 {
  margin-left: -50%;
  margin-right: 14%;
  font-size: 0.95rem;
}
.MuiTypography-root.gpaBarValue.MuiTypography-h6 {
  margin-left: -80%;
  font-size: 0.75rem;
  z-index: 3;
  position: absolute;
  margin-top: 8%;
}

.rightStatContainerPaper {
  border-radius: 30px !important;
  margin-left: 5% !important;
  height: 130% !important;
  margin-top: 3% !important;
}
.MuiInputBase-root.MuiInput-root.MuiInput-underline.shareInputField {
  margin-left: 11%;
  width: 80%;
  background-color: #eeecec;
  border-radius: 20px;
  margin-top: 5%;
  color: #6c6969;
  margin-bottom: 17px;
  font-style: italic;
}
.firstPostBox {
  width: 122%;
  margin-left: 7%;
  margin-top: 4%;
}
.commentIconChatLength {
  margin-left: 46%;
  position: relative;
  top: 24px;
  left: 9px;
}
.MuiFormControl-root.MuiTextField-root.addCommentField {
  margin-left: 12%;
  width: 80%;
  background-color: #eeecec;
  border-radius: 20px;
  margin-top: 4%;
  color: #6c6969;
  margin-bottom: 4%;
  font-style: italic;
}
.MuiFormControl-root.MuiTextField-root.addCommentFieldOpenChat{
	margin-left: 12%;
    width: 80%;
    background-color: #eeecec;
    border-radius: 20px;
    margin-top: 4%;
    color: #6c6969;
    margin-bottom: 4%;
    font-style: italic;	
}
.MuiTypography-root.usernameField.MuiTypography-body1 {
  width: 50%;
  margin-left: 30px;
  display: inline-block;
  font-weight: bolder;
  font-size: 1.2rem;
}
.MuiTypography-root.groupnameField.MuiTypography-body1 {
  display: inline;
  color: #15550cf0;
  font-weight: bolder;
  font-size: 1.1rem;
  font-style: italic;
}
.MuiTypography-root.creationUserField.MuiTypography-body1 {
  width: 50%;
  display: inline-block;
  margin-left: 30px;
  font-size: 0.90rem !important;
}
.MuiTypography-root.userPostField.MuiTypography-body1 {
  width: 66%;
  margin-left: 33px;
  margin-top: 12px;
  font-size: 0.90rem !important;
}
.groupChatsPaper {
    border-radius: 30px !important;
    margin-left: 3% !important;
    margin-top: 3% !important;
}
.leftFilterClosedContainer {
    height: auto;
    border-radius: 20px !important;
    padding-bottom: 60px;
    margin-top: 3% !important;
}
.openedGroupChats {
    border-radius: 30px !important;
    margin-left: 3% !important;
    margin-top: 3% !important;
}
.expandedPostBox {
    margin-left: 7% !important;
    width: 123% !important;
}

.MuiTypography-root.topCommentHeading.MuiTypography-body1 {
  font-size: 1.2rem;
  font-weight: bolder;
  margin-left: 15%;
  margin-top: 4%;
}
.MuiTypography-root.topUserComment.MuiTypography-body1 {
  width: 66%;
  margin-left: 42px;
  margin-top: 0px;
  font-size: 0.90rem !important;
  text-align: justify;
}
.MuiTypography-root.topUserCommentCreated.MuiTypography-body1 {
  width: 50%;
  display: inline-block;
  margin-left: 42px;
  font-size: 0.90rem !important;
  color: #369 !important;
}
.topCommentsList{
    margin-left: 6.5% !important;
    width: 123% !important;

}


}
@media screen and (min-width:960px) and (max-width:1200px){
  .activeGroupStatTab {
    background-color: #369 !important;
    color: white !important;
    border-radius: 30px !important;
    z-index: 2 !important;
    position: absolute !important;
    text-transform: none !important;
    width: 58%;
    opacity: 1 !important;
    margin-left: -10% !important;
    max-width: 100% !important;
}
.inactiveGroupChatTab {
    background-color: #cac5c5 !important;
    color: #4b4949 !important;
    border-radius: 30px !important;
    z-index: 1 !important;
    position: absolute;
    margin-left: 35% !important;
    width: 61% !important;
    text-transform: none !important;
    opacity: 1 !important;
    max-width: 100% !important;
}
.rightStatContainerPaper {
    border-radius: 30px !important;
    margin-left: 0% !important;
    height: 106% !important;
}
.rightStatContainerPaper {
    border-radius: 30px !important;
    margin-left: 0% !important;
    height: 106% !important;
    margin-top: 5%;
    margin-bottom: 5% !important;
}
.tabVABox {
    margin-left: 20% !important;
    padding-top: 10% !important;
}
.activeVATab {
    min-width: 20px !important;
    min-height: 20px !important;
    background-color: #369 !important;
    color: white !important;
    border-radius: 30px !important;
    text-transform: none !important;
    width: 26% !important;
    height: 8% !important;
    display: inline-block;
    font-size: 0.85rem !important;
    opacity: 1.0 !important;
    margin-right: 2% !important;
    font-weight: bolder !important;
}
.inactiveVATab {
    min-width: 20px !important;
    min-height: 20px !important;
    background-color: #cac5c5 !important;
    color: #4b4949 !important;
    border-radius: 30px !important;
    text-transform: none !important;
    width: 26% !important;
    height: 8% !important;
    display: inline-block;
    font-size: 0.85rem !important;
    opacity: 1.0 !important;
    margin-right: 2% !important;
}
.activeGroupChatTab {
    background-color: #369 !important;
    color: white !important;
    border-radius: 30px !important;
    z-index: 2 !important;
    position: absolute !important;
    text-transform: none !important;
    width: 61%;
    opacity: 1 !important;
    margin-left: -13% !important;
    max-width: 100% !important;
    font-weight: bolder !important;
}
.inactiveGroupStatTab {
    background-color: #cac5c5 !important;
    color: #4b4949 !important;
    border-radius: 30px !important;
    z-index: 1 !important;
    position: relative;
    margin-left: -11% !important;
    margin-right: 5% !important;
    text-transform: none !important;
    width: 54% !important;
    opacity: 1 !important;
    max-width: 100% !important;
}
.gpaChartBox {
    position: relative;
    margin-left: -15% !important;
    margin-top: 4% !important;
}
.MuiTypography-root.gpaBarValue.MuiTypography-h6 {
    margin-left: -61% !important;
    font-size: 0.85rem;
    z-index: 3;
    position: absolute;
    margin-top: 1%;
}
.gpaChartContainer {
    width: 100%;
    height: 100%;
    margin-left: 21%;
}
.labelsforGroups {
    width: 45%;
    margin-left: 12%;
    margin-top: 4%;
}
.rightChatContainer {
    overflow-x: hidden;
    scrollbar-width: thin;
    max-height: 875px;
    scrollbar-color: transparent transparent;
    margin-left: -5% !important;
    margin-top: 6% !important;
    width: 100% !important;
}
.MuiFormControl-root.MuiTextField-root.addCommentField {
  margin-left: 6%;
  width: 66%;
  background-color: #eeecec;
  border-radius: 20px;
  margin-top: 20px;
  color: #6c6969;
  margin-bottom: 3%;
}
.postBoxes {
  border-bottom: 3px solid lightgray;
  margin-left: 0%;
  width: 127% !important;
  margin-top: 3%;
}

.MuiInputBase-root.MuiInput-root.MuiInput-underline.shareInputField {
  margin-left: 10%;
  width: 83%;
  background-color: #eeecec;
  border-radius: 20px;
  margin-top: 5%;
  color: #6c6969;
  margin-bottom: 6%;
}
.firstPostBox {
  margin-left: 6%;
}

.commentIconChatLength {
  margin-left: 46%;
  position: relative;
  top: 23px;
  left: 13px;
}
.openedGroupChats {
  border-radius: 30px !important;
  margin-left: 6% !important;
}
.expandedPostBox {
  margin-left: 4%;
  width: 122% !important;
}
.MuiFormControl-root.MuiTextField-root.addCommentFieldOpenChat {
  margin-left: 7%;
  width: 84%;
  background-color: #eeecec;
  border-radius: 20px;
  margin-top: 20px;
  color: #6c6969;
  margin-bottom: 3%;
}

}
@media screen and (min-width:1080px) and (max-width:1200px){
  .leftFilterContainer {
    max-height: 600px;
    width: 50% !important;
    max-width: 50% !important;
}
.tabGridBox {
    position: relative;
    padding-top: 20px;
    margin-left: 13%;
}
.inactiveGroupStatTab {
    background-color: #cac5c5 !important;
    color: #4b4949 !important;
    border-radius: 30px !important;
    z-index: 1 !important;
    position: relative;
    margin-left: -11% !important;
    margin-right: 1% !important;
    text-transform: none !important;
    width: 57% !important;
    opacity: 1 !important;
    max-width: 100% !important;
}
.rightStatContainer{
    margin-left:-50% !important;
}
.rightStatContainerPaper {
    border-radius: 30px !important;
    margin-left: 53% !important;
    height: 100% !important;
    margin-top: 0%;
    margin-bottom: 5% !important;
    width: 50%;
    max-width: 50%;
}
.tabVABox {
    margin-left: 18% !important;
    padding-top: 10% !important;
    margin-right: 7% !important;
}
.inactiveVATab {
  min-width: 20px !important;
  min-height: 20px !important;
  background-color: #cac5c5 !important;
  color: #4b4949 !important;
  border-radius: 30px !important;
  text-transform: none !important;
  width: 31% !important;
  height: 8% !important;
  display: inline-block;
  font-size: 0.65rem !important;
  opacity: 1.0 !important;
  margin-right: 2% !important;
}
.activeVATab {
  min-width: 20px !important;
  min-height: 20px !important;
  background-color: #369 !important;
  color: white !important;
  border-radius: 30px !important;
  text-transform: none !important;
  width: 31% !important;
  height: 8% !important;
  display: inline-block;
  font-size: 0.65rem !important;
  opacity: 1.0 !important;
  margin-right: 2% !important;
  font-weight: bolder !important;
}
.gpaChartBox {
    position: relative;
    margin-left: 0% !important;
    margin-top: 7% !important;
}
.gpaChartContainer {
    width: 100%;
    height: 100%;
    margin-left: 0%;
}
.MuiTypography-root.gpaBarValue.MuiTypography-h6 {
    margin-left: -76% !important;
    font-size: 0.65rem;
    z-index: 3;
    position: absolute;
    margin-top: 1%;
}
.labelsforGroups {
    width: 70%;
    margin-left: 0%;
    margin-top: 0%;
}
.rightChatContainer {
  overflow-x: hidden;
  scrollbar-width: thin;
  max-height: 875px;
  scrollbar-color: transparent transparent;
  margin-left: 0% !important;
  margin-top: 0% !important;
  width: 50% !important;
  max-width: 50% !important;
}
.postBoxes {
  border-bottom: 3px solid lightgray;
  margin-left: 0%;
  width: 112% !important;
  margin-top: 3%;
}
.MuiFormControl-root.MuiTextField-root.addCommentField {

  margin-left: 12%;
  width: 66%;
  background-color: #eeecec;
  border-radius: 20px;
  margin-top: 20px;
  color: #6c6969;
  margin-bottom: 3%;

}
.commentIconChatLength {
  margin-left: 39%;
  position: relative;
  top: 23px;
  left: 13px;
}
}

@media screen and (min-width:1920px) and (max-width:1925px){
	.leftFilterContainer {
	    max-height: 600px;
	    width: 40% !important;
	    max-width: 40% !important;
	}
	.rightStatContainer{
	    width: 40% !important;
	    max-width: 40% !important;
	}
  .rightChatContainer{
    width: 40% !important;
    max-width: 40% !important; 
  }
}