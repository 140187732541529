.landingPageNavContainer{
    margin: 0px !important;
    padding: 0px !important;
    width: 100% !important;
    max-width: 100% !important;
}
.MuiGrid-root.navbarLandingPage.MuiGrid-container{
    margin-top: 3%;
    margin-left: 6%;
    width: 100%;
}
.windeverestIconLandingPage{
	width: 11%;
}
.MuiTypography-root.howItWorksLabel.MuiTypography-h6{
	width: 211%;
    font-weight: 100 !important;
    color: rgba(46, 46, 46, 0.8);
    cursor: pointer;
}
.MuiTypography-root.aboutLabel.MuiTypography-h6{
	width: 211%;
    font-weight: 100 !important;
    color: rgba(46, 46, 46, 0.8);
    cursor: pointer;
}
.smallerLinkGrid{    
	margin-left: 15% !important;
    margin-right: 16% !important;
}
.otherTitleGrid{
	margin-left: -5% !important;
	margin-right: 9% !important;
}
.aboutTitleGrid{
	margin-left: -5% !important;
margin-right: 9% !important;
}
.linksLandingPage {
    display: flex;
    width: 100%;
    max-width: 100% !important;
    margin-top: 3% !important;
}

.iconLandingGrid{
	margin-bottom: 4% !important;
}
.MuiButtonBase-root.MuiButton-root.MuiButton-text.loginButtonLandingPage{
    background-color: #006633;
    color: white;
    font-weight: bold;
    text-transform: none;
    border-radius: 1.2rem;
    width: 100% !important;
}
.navbarBackground{
	z-index: 1;
	width: 86%;
	position: relative;
	height: 465px;
}
.lowerNavBarGrid{
	margin-left: 6%;
width: 104%;
}
.lowerNavBarGridInfo {
    z-index: 2;
    position: absolute;
    padding-top: 5%;
    padding-left: 4%;
}
.lowerNavBarGridImage {
    z-index: 2;
    position: absolute;
    width: 58%;
    left: 40%;
    top:15%;
    max-width: 75% !important;
}
.twinPhonesNavBar {
    width: 97%;
}
.MuiTypography-root.breezyHeading.MuiTypography-h4{
	color: #369;
	width: 65%;
	font-weight: bolder;
	font-size: 2.5rem;
}

.MuiTypography-root.windEverestTitle.MuiTypography-h6{
	font-size: 1.5rem;
	width: 100%;
	font-weight: bolder;
	color: #535151;
}
.MuiTypography-root.navInformation.MuiTypography-body1{
	font-weight: bolder;
	font-size: 1.00rem;
	width: 104%;
	text-align: justify;
	color: #595858;
}
.MuiButtonBase-root.MuiButton-root.MuiButton-text.startButton {
    background-color: #006633;
    color: white;
    font-weight: bold;
    text-transform: none;
    border-radius: 1.2rem;
    width: 35% !important;
    margin-top: 4%;
}
@media screen and (min-width:900px) and (max-width:1290px){
    .lowerNavBarGridInfo {
        z-index: 2;
        position: absolute;
        top: 58%;
        left: 12%;
    }
    .lowerNavBarGridImage {
        z-index: 2;
        position: absolute;
        left: 40%;
        width: 61%;
        top: 34%;
        max-width: 75% !important;
    }
}
@media screen and (min-width:1400px) and (max-width:1950px)
{   
    .lowerNavBarGridInfo {
        z-index: 2;
        position: absolute;
        top: 29%;
        left: 12%;
    }
    .navbarBackground {
        z-index: 1;
        width: 86%;
        position: relative;
        height: 100%;
    }
    .MuiTypography-root.breezyHeading.MuiTypography-h4 {
        color: #369;
        width: 55%;
        font-weight: bolder;
        font-size: 2.5rem;
    }
    .MuiTypography-root.windEverestTitle.MuiTypography-h6 {
        font-size: 1.5rem;
        width: 86%;
        font-weight: bolder;
        color: #535151;
    }
    .MuiTypography-root.navInformation.MuiTypography-body1 {
        font-weight: bolder;
        font-size: 1.05rem;
        width: 87%;
        text-align: justify;
        color: #595858;
    }
    .MuiButtonBase-root.MuiButton-root.MuiButton-text.startButton {
        background-color: #006633;
        color: white;
        font-weight: bold;
        text-transform: none;
        border-radius: 1.5rem;
        width: 35% !important;
        margin-top: 4%;
        height: 49px;
    }
    .lowerNavBarGridImage {
        z-index: 2;
        position: absolute;
        left: 39%;
        width: 61%;
        top: 16%;
        max-width: 75% !important;
    }
    .twinPhonesNavBar {
        width: 100%;
    }
    
    
}
@media screen and (min-width:960px) and (max-width:1200px)
{
    .windeverestIconLandingPage {
        width: 14%;
    }
    .MuiTypography-root.howItWorksLabel.MuiTypography-h6 {
        width: 151%;
        font-weight: 100 !important;
        color: rgba(58, 58, 58, 0.8);
        font-size: 1.0rem !important;
        margin-left: -70%;
        cursor: pointer;
    }
    .MuiTypography-root.aboutLabel.MuiTypography-h6 {
        width: 160%;
        font-weight: 100 !important;
        color: rgba(54, 54, 54, 0.8);
        font-size: 1.0rem !important;
        margin-left: -70%;
        cursor: pointer;
    }
    .MuiButtonBase-root.MuiButton-root.MuiButton-text.loginButtonLandingPage {
        background-color: #006633;
        color: white;
        font-weight: bold;
        text-transform: none;
        border-radius: 1.2rem;
        width: 155% !important;
        font-size: 0.85rem;
        margin-left: -37%;
        margin-top: 0%;
    }
    .otherTitleGrid {
        margin-left: -8% !important;
        margin-right: 9% !important;
        margin-top: -1% !important;
    }
    
    .lowerNavBarGrid {
        margin-left: 5%;
        width: 104% !important;
    }
    .lowerNavBarGridInfo {
        z-index: 2;
        position: absolute;
        top: 22%;
        left: 12%;
    }
    .lowerNavBarGridImage {
        z-index: 2;
        position: absolute;
        left: 40%;
        width: 61%;
        top: 13%;
        max-width: 75% !important;
    }
    .MuiTypography-root.breezyHeading.MuiTypography-h4 {
        color: #369;
        width: 92%;
        font-weight: bolder;
        font-size: 2.5rem;
    }
    .MuiTypography-root.windEverestTitle.MuiTypography-h6 {
        font-size: 1.25rem;
        width: 101%;
        font-weight: bolder;
        color: #535151;
    }
    .MuiTypography-root.navInformation.MuiTypography-body1 {
        font-weight: bolder;
        font-size: 0.85rem;
        width: 107%;
        text-align: justify;
        color: #595858;
    }
    .MuiButtonBase-root.MuiButton-root.MuiButton-text.startButton {
        background-color: #006633;
        color: white;
        font-weight: bold;
        text-transform: none;
        border-radius: 1.2rem;
        width: 45% !important;
        margin-top: 4%;
    }
    .lowerNavBarGridInfo {
        z-index: 2;
        position: absolute;
        top: 18%;
        left: 11%;
    }
    .navbarBackground {
        z-index: 1;
        width: 87%;
        position: relative;
        height: 100%;
    }  

}
