.MuiSelect-outlined.MuiSelect-outlined{
    padding: 10px!important;
    
}

.MuiSelect-select:focus{
    background-color: none !important;
    
}
input[type="checkbox"] {
    border: 2px solid #336699;
    border-radius: 5px;
}
.MuiSvgIcon-root.MuiSelect-icon.MuiSelect-iconOutlined {
    transform: none;
}

#demo-simple-select:active{
    background-color: white;
}
.MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input:active{
    background-color: white;
}
.MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input{
    background-color: white;
}

