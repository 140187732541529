
.container{
    width: 100vw;
    height: 100vh;
    overflow: scroll;
}

p{
    margin: 0;
    padding: 0;
    opacity: 0.7;
}

.header{
    width: 100%;
    height: 100px;
    margin-top: 10px;
}

.header img{
    width: 150px;
    margin-left: 5%;
}

.accountBlockBody{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.accountBlockBody img{
    width: 200px;
    margin-bottom: -20px;
    margin-left: -23px;
}

.verificationText{
    color: #336699;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 0px;

}

.alertMsg{
    font-size: 16px;
    margin: 0;
    text-align: center;
    width:310px;
}

hr{
    width: 310px;
    margin-top: 20px;
    margin-bottom: 20px;
    opacity: 0.4;
}

.forgetPassword{
    font-size: 16px;
    color: green;
    text-decoration: underline;
    cursor: pointer;
}
@media screen and  ( max-width: 641px) {
    .alertMsg{
        width: 80%!important;
    }
    .butnforgetpassword{
        width: 80%;
    }
}