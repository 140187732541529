.landingPage3Container{
  margin: 38px !important;
  padding: 0px !important;
  width: 93% !important;
  max-width: 100% !important;
  background-color: #f7f2f2;
  border-radius: 50px;
  padding-top: 0px !important;
}
.MuiTypography-root.contactUsTitle.MuiTypography-h5{
  color: rgb(51, 102, 153);
  font-weight: bolder;
  margin-top: 16%;
  margin-left: 35%;
  margin-bottom: 4%;
  font-size: 2.45rem;
  padding-top: 5%;
}
.getInTouch {
    color: #336699 !important;
    font-weight: bold !important;
    margin-top: 40px !important;
    font-size: 26px !important;
  }
.commentMessageBox{
  border: medium none;
  width: 100%;
  font-family: inherit;
  font-size: 12px;
  outline: currentcolor none medium;
  border-radius: 20px;
  height: 160px;
  padding: 10px;
}

  .containerLeft{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    border-radius: 15px !important;
    padding-bottom: 30px;
  }

  .landingBodyContainers{
    display: flex !important;
    flex-direction: row !important;
    justify-content: start;
    grid-gap: 20px;
    gap: 20px;
    margin-top: 30px;
    padding-bottom: 6%;
  }

  .containerRightLandingContact{
    flex: 0.8;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    border-radius: 20px;
  }
  .gridsmLandingContact{
    display: flex;
    justify-content: center;
    margin-right: -35px !important;
  }
  @media screen and (min-width: 600px) and (max-width: 680px) {
    .getInTouch {
       font-size: 18px!important;
      }
    
  }

  @media screen and (min-width: 960px) and (max-width: 1200px) {
    .containerLeft{
width: 90%!important;
    }
    
  }


  @media screen and (max-width: 959px) {
    .gridsmLandingContact{
display: flex;
/* justify-content: center; */
    }

    .landingBodyContainers{
justify-content: center;
    }
    
  }

  @media screen and (max-width: 599px) {
    .landingBodyContainers{
        display: flex;
        flex-direction: column;
    }
    .containerLeft{
        width: 100%!important;
            }
  }
@media screen and (min-width:1400px) and (max-width:1950px)
{
  .MuiTypography-root.contactUsTitle.MuiTypography-h5{
    color: rgb(51, 102, 153);
    font-weight: bolder;
    margin-top: 15%;
    margin-left: 39%;
    margin-bottom: 4%;
    font-size: 2.45rem;
    padding-top: 5%;
  }
  .commentMessageBox{
    border: medium none;
    width: 100%;
    font-family: inherit;
    font-size: 12px;
    outline: currentcolor none medium;
    border-radius: 20px;
    height: 217px;
    padding: 10px;
  }
}
@media screen and (min-width:1100px) and (max-width:1200px)
{
  .landingBodyContainers {
    display: flex !important;
    flex-direction: row !important;
    justify-content: start;
    grid-gap: 20px;
    grid-gap: 20px;
    gap: 20px;
    margin-top: 30px;
    width: 115% !important;
    margin-left: -8%;
}
.containerLeft {
    width: 70% !important;
    margin-right: 7% !important;
}

}
@media screen and (min-width:960px) and (max-width:1200px)
{
 .containerRightLandingContact {
  flex: 0.8 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  border-radius: 20px;
  max-width: 100% !important;
  margin-left: -2% !important;
  margin-top: 0% !important;
  width: 100% !important;
}
.containerLeft {
  width: 90% !important;
  margin-right: 17% !important;
}
.landingBodyContainers {
    display: flex !important;
    flex-direction: row !important;
    justify-content: start;
    grid-gap: 20px;
    gap: 20px;
    margin-top: 30px;
    width: 115% !important;
    margin-left: -5%;
}    

}
