.MuiAccordion-root:before {
    display: none;
}

.MuiAccordion-rounded {
    margin-bottom: 10px;
}
/* 
.MuiSvgIcon-root {
    /* transform: rotate(-90deg); }*/

.MuiAccordionSummary-expandIcon.Mui-expanded {
    transform: rotate(-90deg)!important;
}

.MuiAccordionDetails-root {
    padding-left: 50px!important;
    padding-top: 0px!important;
}
@media only screen and (max-width:600px)  {
    .questionIconResponsive {
        width: 2.5rem;
        height: 2.5rem;
        color: rgb(0, 102, 51);
        margin-right: 10px;
      }    
}
