
.OnboardingOneContainer{
    margin: 0px !important;
    padding: 0px !important;
    background-color: #369;
    width: 100% !important;
    max-width: 100% !important;
}
.logoGridOne{
    position: relative;
}
.windEverestLogoOne {
    width: 30%;
	z-index: 2;
	position: absolute;
	top: 20%;
	left: 14%;
}
.circleTopLeftImgOne {
    width: 80%;
	z-index: 1;
	position: absolute;
	top: -42%;
	left: -14%;
}
.infoGridOne {
    display: flex;
    margin-top: 6% !important;
    height: 60px;
    margin-left: 4% !important;
}
.infoThirdLowerGrid {
    display: flex;
    margin-top: -4% !important;
    height: 150px;
    margin-left: 30% !important;
    width: 40%;
}
.arrowIconsOne {
    height: 20px;
    margin-top: 5px;
}
.MuiTypography-root.DOWHeading.MuiTypography-body1{
	margin-left: -25px;
}
.MuiTypography-root.gradeHeading.MuiTypography-body1{
	margin-left:-40px;
}
.MuiTypography-root.arrowOneInfo.MuiTypography-body1{
    color:white;
    font-size: 20px;
    font-weight: bolder;
    width: 110%;
    margin-left:-10px
}
.MuiTypography-root.arrowTwoInfo.MuiTypography-body1{
    color:white;
    font-size: 20px;
    font-weight: bolder;
    width: 110%;
    margin-left: -10px;
}
.MuiTypography-root.arrowThreeInfo.MuiTypography-body1{
    color:white;
    font-size: 20px;
    font-weight: bolder;
    width: 110%;
    margin-left: -10px;
}
.MuiGrid-root.MuiGrid-item.MuiGrid-grid-md-1.infoGridIcon{
    max-height: 30px;
}
.rightCircleIconOne {
    width: 8%;
    position: absolute;
    top: 0%;
    right: 0%;
}
.laptopImageOne{
    width: 85%;
    position: absolute;
    margin:-6%;
    margin-left:-17%;
}
.circleBottomLeftOne {
    width: 60%;
	margin-top: 138%;
	margin-left: 42%;
 }
 .popupIconWeeklyData {
    width: 118%;
    margin-top: 37%;
    margin-left: -32%;
    height: 45%;
    z-index: 1;
    position: absolute;
}
.popupTableGrid{
    position: relative;
}
.weeklyTableIconOne {
    width: 60%;
    z-index: 2;
    position: absolute;
    margin-left: 10%;
    margin-top: 48%;
}
.weeklyDataPopupIcon {
    width: 20%;
    z-index: 2;
    position: absolute;
    margin-top: 49%;
    margin-left: -14%;
}
.headingsWeekly {
    z-index: 2;
    position: absolute;
    margin-top: 40%;
    margin-left: -8%;
}
.tableWeekly {
    z-index: 2;
    position: absolute;
    margin-top: 48%;
    margin-left: 10%;
}
.tableRows {
    width: 200%;
    display: inline-flex;
    margin-left: 6% !important;
    margin-top: 2% !important;
    margin-bottom: 1% !important;
}
.MuiTypography-root.gradeRow.MuiTypography-body1{
    margin-left: 55px;
}
.MuiTypography-root.gradeRow1.MuiTypography-body1{
    margin-left: 78px;
}
.MuiTypography-root.gradeRow2.MuiTypography-body1{
    margin-left: 58px;
}
.MuiTypography-root.gradeRow3.MuiTypography-body1{
    margin-left: 69px;
}
.MuiTypography-root.gradeRow4.MuiTypography-body1{
    margin-left: 63px;
}
.MuiTypography-root.gradeRow5.MuiTypography-body1{
    margin-left: 62px;
}
.MuiTypography-root.gradeRow6.MuiTypography-body1{
    margin-left: 41px;
}
.footerOnBoardingOddOne {
    background-color: white;
    height: 100px;
    margin-top: 0%;
}
.MuiTypography-root.typographyTAC.MuiTypography-body1{
    margin: 50px;
    color: #174120;
    text-decoration: underline;
    margin-left: 160px;
    cursor: pointer;
}
.MuiTypography-root.typographyPolicy.MuiTypography-body1{
    margin: 50px;
    color: #174120;
    text-decoration: underline;
    margin-left: 160px;
    cursor: pointer;
}
.MuiTypography-root.typographyContact.MuiTypography-body1{
    margin: 50px;
    color: #174120;
    text-decoration: underline;
    margin-left: 160px;
    cursor: pointer;
}
.typographyTACGrid{
    margin-top:5% !important;
}
.typographyPolicyGrid{
    margin-top:5% !important;
}
.typographyContactGrid{
    margin-top:5% !important;
}
@media screen and (min-width:1400px) and (max-width:1910px)
{
    .laptopImageOne {
        width: 85%;
        position: absolute;
        margin: -8%;
        margin-left: -18%;

    }
    .circleBottomLeftOne {
        width: 45%;
        margin-top: 138%;
        margin-left: 42%;
    }
    .infoThirdLowerGrid {
        display: flex;
        margin-top: -3% !important;
        height: 150px;
        margin-left: 29% !important;
        width: 32%;
    }
    .MuiTypography-root.arrowThreeInfo.MuiTypography-body1 {
        color: white;
        font-size: 1.45rem !important;
        font-weight: bolder !important;
        width: 170% !important;
        margin-left: 3% !important;
        margin-top: 7.25% !important;
      }
    .arrowIconsOne {
        height: 22px;
        margin-top: 6%;
        margin-left: 25%;
      }
    .circleTopLeftImgOne {
        width: 73%;
        z-index: 1;
        position: absolute;
        top: -42%;
        left: -14%;
    }
    .windEverestLogoOne {
        width: 28%;
        z-index: 2;
        position: absolute;
        top: 20%;
        left: 11%;
    }
    .infoGridIcon {
        max-height: 30px !important;
        margin-top: 8% !important;
    }
    .weeklyDataPopupIcon {
        width: 18%;
        z-index: 2;
        position: absolute;
        margin-top: 49%;
        margin-left: -14%;
    }
    .weeklyTableIconOne {
        width: 56%;
        z-index: 2;
        position: absolute;
        margin-left: 14%;
        margin-top: 46%;
    }
    .tableWeekly {
        z-index: 2;
        position: absolute;
        margin-top: 47%;
        margin-left: 12%;
      }
    .tableRows {
        width: 198%;
        display: inline-flex;
        margin-left: 6% !important;
        margin-top: 2.0% !important;
        margin-bottom: 2% !important;
      }
    .MuiTypography-root.dayRow.MuiTypography-body1 {    
        font-size: 0.80rem;
        margin-left: 9px;
    }
    .MuiTypography-root.gradeRow.MuiTypography-body1 {
        margin-left: 68px;
        font-size: 0.80rem;
    }
    .MuiTypography-root.gradeRow1.MuiTypography-body1 {
        margin-left: 46%;
        font-size: 0.80rem;
    }
    .MuiTypography-root.gradeRow2.MuiTypography-body1 {
        margin-left: 38%;
        font-size: 0.80rem;
    }
    .MuiTypography-root.gradeRow3.MuiTypography-body1 {
        margin-left: 43%;
        font-size: 0.80rem;
    }
    .MuiTypography-root.gradeRow4.MuiTypography-body1 {
        margin-left: 41%;
        font-size: 0.80rem;
    }
    .MuiTypography-root.gradeRow5.MuiTypography-body1 {
        margin-left: 41%;
        font-size: 0.80rem;
    }
    .MuiTypography-root.gradeRow6.MuiTypography-body1 {
        margin-left: 32%;
        font-size: 0.80rem;
    }
    .footerOnBoardingOddOne {
        background-color: white;
        height: 100px;
        margin-top: 3%;
    }
}
@media screen and (min-width:1020px) and (max-width:1100px)
{
    .infoGridOne {
        display: flex;
        margin-top: 4% !important;
        height: 60px;
        margin-left: 2% !important;
        margin-bottom: 0% !important;
    }
    .infoThirdLowerGrid {
        display: flex !important;
        margin-top: -4% !important;
        height: 150px !important;
        margin-left: 30% !important;
        width: 48% !important;
    }
    .circleBottomLeftOne {
        width: 60%;
        margin-top: 138%;
        margin-left: 43%;
    }

}
@media screen and (min-width:900px) and (max-width:1200px)
{
    .laptopImageOne {
        width: 85%;
        position: absolute;
        margin: -6%;
        margin-left: -17%;
    }
    .MuiTypography-root.arrowTwoInfo.MuiTypography-body1 {
        color: white;
        font-size: 0.95rem !important;
        font-weight: bolder !important;
        width: 126% !important;
        margin-left: -10px !important;
    }
    .arrowIconsOne {
        height: 15px;
        margin-top: 5px;
    }
    .MuiTypography-root.arrowOneInfo.MuiTypography-body1 {
        color: white;
        font-size: 0.95rem;
        font-weight: bolder;
        width: 100%;
        margin-left: -10px;
        margin-top: 3%;
    }
    .MuiGrid-root.infoGridIconOne.MuiGrid-item.MuiGrid-grid-md-1{
        margin-top:3%;
    }
    .MuiGrid-root.infoGridIconTwo.MuiGrid-item.MuiGrid-grid-md-1{
        margin-top:0%;    
    }
    .infoGridOne {
        display: flex;
        margin-top: 6% !important;
        height: 60px;
        margin-left: 2% !important;
    }
    .MuiTypography-root.DOWHeading.MuiTypography-body1 {
        margin-left: -25px;
        font-size: 0.85rem;
    }
    .MuiTypography-root.wLHeading.MuiTypography-body1{
        font-size:0.85rem;
    }
    .MuiTypography-root.dayRow.MuiTypography-body1 {
        font-size: 0.70rem;
    }
    .circleBottomLeftOne {
        width: 60%;
        margin-top: 146%;
        margin-left: 43%;
    }
    .MuiTypography-root.arrowThreeInfo.MuiTypography-body1 {
        color: white;
        font-size: 0.85rem !important;
        font-weight: bolder !important;
        width: 89% !important;
        margin-left: -9px !important;
        margin-top: -4px !important;
    }
    .infoThirdLowerGrid {
        display: flex !important;
        margin-top: -4% !important;
        height: 150px !important;
        margin-left: 30% !important;
        width: 100% !important;
    }
    .arrowIconsOne {
        height: 15px !important;
        margin-top: 4px !important;
        margin-left: 4px !important;
    }
    .MuiTypography-root.typographyTAC.MuiTypography-body1 {
        margin: 50px;
        color: #174120;
        text-decoration: underline;
        margin-left: 116px;
        width: 100%;
        cursor: pointer;
    }
    .MuiTypography-root.gradeRow4.MuiTypography-body1 {
        margin-left: 50px;
        font-size: 0.70rem;
    }
    .MuiTypography-root.gradeRow2.MuiTypography-body1 {
        margin-left: 46px;
        font-size: 0.70rem;
    }
    .MuiTypography-root.gradeRow3.MuiTypography-body1 {
        margin-left: 53px;
        font-size: 0.70rem;
    }
    .MuiTypography-root.gradeRow6.MuiTypography-body1 {
        margin-left: 35px;
        font-size: 0.70rem;
    }
    .MuiTypography-root.gradeRow5.MuiTypography-body1 {
        margin-left: 50px;
        font-size: 0.70rem;
    }
    .MuiTypography-root.gradeHeading.MuiTypography-body1 {
        margin-left: -25px;
        font-size: 0.85rem;
    }
    .MuiTypography-root.gradeRow.MuiTypography-body1 {
        margin-left: 44px;
        font-size: 0.70rem;
    }
    .MuiTypography-root.gradeRow1.MuiTypography-body1 {
        margin-left: 60px;
        font-size: 0.70rem;
    }
}
@media screen and (min-width:1150px) and (max-width:1200px)
{
    .laptopImageOne {
        width: 85%;
        position: absolute;
        margin:-6%;
        margin-left: -17%;
    }
    .MuiTypography-root.arrowThreeInfo.MuiTypography-body1 {
        color: white;
        font-size: 0.85rem !important;
        font-weight: bolder !important;
        width: 77% !important;
        margin-left: -9px !important;
        margin-top: -4px !important;
    }
    .circleBottomLeftOne {
        width: 60%;
        margin-top: 136%;
        margin-left: 40%;
    }
    .MuiTypography-root.dayRow.MuiTypography-body1 {
        font-size: 0.85rem;
    }
    .MuiTypography-root.gradeRow.MuiTypography-body1 {
        margin-left: 54px;
        font-size: 0.85rem;
    }
    .MuiTypography-root.gradeRow1.MuiTypography-body1 {
        margin-left: 74px;
        font-size: 0.85rem;
    }
    .MuiTypography-root.gradeRow2.MuiTypography-body1 {
        margin-left: 57px;
        font-size: 0.85rem;
    }
    .MuiTypography-root.gradeRow3.MuiTypography-body1 {
        margin-left: 67px;
        font-size: 0.85rem;
    }
    .MuiTypography-root.gradeRow4.MuiTypography-body1 {
        margin-left: 63px;
        font-size: 0.85rem;
    }
    .MuiTypography-root.gradeRow5.MuiTypography-body1 {
        margin-left: 62px;
        font-size: 0.85rem;
    }
    .MuiTypography-root.gradeRow6.MuiTypography-body1 {
        margin-left: 43px;
        font-size: 0.85rem;
    }
}
@media screen and (min-width:1920px) and (max-width:2000px){
    .weeklyTableIconOne {
        width: 53%;
        z-index: 2;
        position: absolute;
        margin-left: 13%;
        margin-top: 48%;
      }
      .arrowIconsOne {
        height: 25px;
        margin-top: 0.2rem;
        margin-left: 0.5rem;
      }
      
    .MuiTypography-root.gradeRow1.MuiTypography-body1 {
        margin-left: 47%;
        font-size: 1.0rem;
    }
    .MuiTypography-root.gradeRow.MuiTypography-body1 {
        margin-left: 37%;
        font-size: 1.0rem;
    }
    .MuiTypography-root.gradeRow2.MuiTypography-body1 {
        margin-left: 38%;
        font-size: 1.0rem;
    }
    .MuiTypography-root.gradeRow3.MuiTypography-body1 {
        margin-left: 43%;
        font-size: 1.0rem;
    }
    .MuiTypography-root.gradeRow4.MuiTypography-body1, .MuiTypography-root.gradeRow5.MuiTypography-body1 {
        margin-left: 41%;
        font-size: 1.0rem;
    }
    .MuiTypography-root.gradeRow6.MuiTypography-body1 {
        margin-left: 31%;
        font-size: 1.0rem;
    }
    .MuiTypography-root.dayRow.MuiTypography-body1 {
        font-size: 1.1rem;
        margin-left: 12%;
    }
    .tableWeekly {
        z-index: 2;
        position: absolute;
        margin-top: 48%;
        margin-left: 8%;
    }
    
    .weeklyDataPopupIcon {
        width: 18%;
        z-index: 2;
        position: absolute;
        margin-top: 48%;
        margin-left: -12%;
    }
    
    .MuiTypography-root.typographyContact.MuiTypography-body1, .MuiTypography-root.typographyPolicy.MuiTypography-body1, .MuiTypography-root.typographyTAC.MuiTypography-body1 {
        color: #174120;
        text-decoration: underline;
        margin: 50px 50px 50px 160px;
        cursor: pointer;
        font-size: 1.5rem;
    }
     
}