

.containerBlock{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: "row";
    overflow: scroll;
}

.leftBlock{
    flex: 0.5;
    height: 100%;
    background-color: pink;
}

.leftBlock img{
    height: 100%;
    width: 100%;
    /* object-fit: cover; */
}

.rightBlock{
    flex: 0.5;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.rightBlock img{
    width: 200px;
    height: 150px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    margin-bottom: 20px;
    margin-top: 10%;
}

.loginForm{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.loginformGroup{
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 5px;
    margin-bottom: 10px;
}

.formGroup label{
    font-size: 18px;
}

.formGroup input{
    height: 30px;
    font-size: 18px;
    border-radius: 50px;
    outline: none;
    border: 1px solid black;
    padding-left: 10px;
}

.loginForgetpasswordContainer{
    display: flex;
    width: 50%;
}
::-ms-reveal{display: none;}
.loginForgetpasswordContainer p{
    display: inline;
    margin-left: auto;
    text-decoration: underline;
    color: green;
    cursor: pointer;
}

.loginBtn{
    margin-top: 20px;
    margin-bottom: 20px;
    width: 50%;
    height: 30px;
    border: none;
    background-color: green;
    color: white;
    font-weight: bold;
    border-radius: 50px;
    cursor: pointer;
}

.btn:hover{
    opacity: 0.8;
}

.btnContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    gap: 10px;
    background-color: white;
    /* border: 1px solid black; */
    width: 50%;
    height: 30px;
    border-radius: 50px;
    margin-bottom: 20px;
    -webkit-box-shadow: -2px 0px 9px 3px rgba(112,108,112,1);
-moz-box-shadow: -2px 0px 9px 3px rgba(112,108,112,1);
box-shadow: -2px 0px 9px 3px rgba(112,108,112,1);
cursor: pointer;
    
}

.btnContainer:hover{
    opacity: 0.8;
}

.btnContainer img{
    width: 20px;
    height: 20px;
    object-fit: cover;
    margin: 0;
    padding: 0;
}

.signupOption{
    margin-bottom: 20px;
}

.signupOption span{
    color: #336699;
    cursor: pointer;
}



@media screen and (max-width: 480px) {
    .headingContainer{
        height: 400px;
        width: 100vw;

    }
    .loginLogo{
        object-fit: fill!important;
    }
    .loginformGroup{
        width: 80%!important;
    }
    .loginForgetpasswordContainer{
        width: 80%!important;
    }
    .buttonlogin{
        width: 80%!important;
    }
}


@media screen and (min-width: 480px ) and ( max-width: 641px) {
    .headingContainer{
        height: 500px;
        width: 100vw;

    }
    .loginLogo{
        object-fit: fill!important;
    }
    .loginformGroup{
        width: 80%!important;
    }
    .loginForgetpasswordContainer{
        width: 80%!important;
    }
    .buttonlogin{
        width: 80%!important;
    }
}
