  .rootDashboard {
    padding-right: 60px !important;
    margin-left: 25px !important;
  }
  .getInTouch {
      color: #336699 !important;
      font-weight: bold !important;
      margin-top: 40px !important;
      font-size: 26px !important;
  }
  .MuiTypography-root.weeklyHeading.MuiTypography-h5{
     font-weight: bold;
      color: rgb(51, 102, 153);
      margin-top: 72px;
      font-size: 1.6rem;
  }
    .containerLeft{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 80%;
      border-radius: 15px !important;
      padding-bottom: 30px;

    }
  
    .dashboardBodyContainers{
      display: flex !important;
      flex-direction: row !important;
      justify-content: start;
      gap: 20px;
      margin-top: 30px;
    }
  
    .dashboardContainerRight {
      flex: 0.8 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-items: center;
      justify-content: center;
      height: 100%;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      margin-left: -28px !important;
      margin-top: 11px !important;
  }
    .gridsm{
      display: flex;
      justify-content: center;
      margin-right: -35px !important;
    }
    .textFieldContainer{
      width:500px;
      margin-left:-90px;
      margin-top:32px;
    }  
  @media only screen and (min-width: 600px) and (max-width: 680px) {
    .getInTouch {
       font-size: 18px!important;

      }
    
  }

  @media only screen and (min-width: 960px) and (max-width: 1200px) {
    .containerLeft{
      width: 90%!important;
    }
    
  }


  @media only screen and (max-width: 959px)  {
    .gridsm{
      display: flex;
    }

    .dashboardBodyContainers{
      justify-content: center;
    }
    
  }

  @media only screen and (max-width: 599px) {
    .dashboardBodyContainers{
        display: flex;
        flex-direction: column;
    }
    .containerLeft{
        width: 100%!important;
    }
  }
  @media only screen and (min-width:310px) and (max-width:900px) {
 .dashboardContainerRight div.MuiBox-root.MuiBox-root-75{
        width:140% !important;
        margin-left:10px !important;
        margin-top:10px !important;
        padding-left:20px;

    }
    .textFieldContainer{
      width:140%;
      margin-left:30px;
      margin-top:12px;

    }
    .rootDashboard {
      padding-right: 60px !important;
      margin-left: 15px !important; 
    }
  
  }
  @media only screen and (min-width:310px) and (max-width:380px) {
    .rootDashboard {
      padding-right: 60px !important;
      margin-left: 15px !important;

    }
    .dashboardContainerRight div.MuiBox-root.MuiBox-root-75{
      width:140% !important;
      margin-left:5px !important;
      margin-top:10px !important;
      padding-left:10px;
  }
  .textFieldContainer{
    width:130%;
    margin-left:20px;
    margin-top:12px;
  }

  }
@media only screen and (min-width:1099px) and (max-width:1160px) {
    .dashboardContainerRight {
      flex: 0.8 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: 100%;
      border-radius: 20px;
      max-width: 40% !important;
      margin-left: 1% !important;
      margin-top: 0% !important;
  }
  .gridsm {
    display: flex;
    justify-content: center;
    margin-right: -35px !important;
    width: 63% !important;
    max-width: 60% !important;
  }
}
@media only screen and (min-width:1160px) and (max-width:1200px) {
  .dashboardContainerRight div.MuiBox-root.MuiBox-root-75{
    width:140% !important;
    margin-left:10px !important;
    margin-top:10px !important;
    padding-left:20px;
}
.textFieldContainer{
  width:140%;
  margin-left:30px;
  margin-top:12px;
}
.rootDashboard {
  padding-right: 60px !important;
  margin-left: 15px !important;
}
.gridsm {
  display: flex;
  justify-content: center;
  margin-right: -35px !important;
  width: 60% !important;
  max-width: 60% !important;
  height: 500px !important;
}
.dashboardContainerRight {
  flex: 0.8 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  border-radius: 20px;
  max-width: 46% !important;
  margin-left: -12% !important;
  margin-top: 1% !important;
}

}
@media only screen and (min-width:1019px) and (max-width:1100px) {
  .dashboardContainerRight {
    flex: 0.8 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    border-radius: 20px;
    max-width: 33% !important;
    margin-left: 0% !important;
    margin-top: 0% !important;

  }
  .gridsm {
      display: flex;
      justify-content: center;
      margin-right: -35px !important;
      max-width: 67% !important;

    }

}
@media only screen and (min-width:960px) and (max-width:1020px){
  .gridsm {
    display: flex;
    justify-content: center;
    margin-right: -40px !important;

  }
  .dashboardContainerRight {
    flex: 0.8 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    border-radius: 20px;
    max-width: 100% !important;
    margin-left: 16% !important;
    margin-top: 0% !important;
    width: 100% !important;
  }
}
@media only screen and (min-width:1920px) and (max-width:2110px){
  .dashboardContainerRight {
    flex: 0.8 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-items: center;
    justify-content: center;
    height: 100%;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    margin-left: -12% !important;
    margin-top: 0.5% !important;
}
.gridsm {
    display: flex;
    justify-content: center;
    margin-right: 1% !important;
    width: 35% !important;
    max-width: 35% !important;
}
}