.fleetStatusDiv{
    margin: 20px;
    width:100%;
}
.fleetStatusIconDiv{
    margin:10px;
    height:1em !important;
}
.fleetStatusIcons{
    width:50px;
    height:35px;
    display: inline;
}
.MuiPaper-root.fleetStatusPaper.MuiPaper-elevation3.MuiPaper-rounded {
    width: 90%;
    height: 250px;
    border-radius: 10px;
}
.fleetStatusRPHeading.MuiTypography-body1{
    padding-top:15px;
    padding-bottom:5px;
    font-size:15px;
    color:black;
     font-weight:bolder;
}
.fleetStatusRPGraphDiv{
    position:relative;
    width:90%;
    height:50px;
    margin-top:27%;
    margin-bottom:-16px;
    margin-left:8px;
}
.fleetStatusGreenRPDiv{
    background-color:rgb(3, 92, 3);
    height:35px;
    position: absolute;
    border-radius:50px;
    z-index:15;
    display:inline-block;
}
.fleetStatusYellowRPDiv{
    background-color:#FFEA00;
    height:35px;
    position: absolute;
    border-radius:50px;
    z-index:5;
    display:inline-block;
}
.fleetStatusRedRPDiv{
    background-color:red;
    height:35px;
    position: absolute;
    border-radius:50px;
    z-index:10;
    display:inline-block;
}
.fleetStatusIconSubDiv{
    margin-top: 10px;
}
.fleetStatusIconChartLabel.MuiTypography-body1{
    display:inline;
    color:black;
    margin-top:8px;
    margin-left:5px;
    font-size:14px;
}

@media screen and (min-width:110px) and (max-width:1200px){
    .fleetStatusRPGraphDiv{
        position:relative;
        width:90%;
        height:50px;
        margin-top:35%;
        margin-bottom:-16px;
        margin-left:8px;
    }
}
@media screen and (min-width:110px) and (max-width:1200px){
.fleetStatusRPGraphDiv{
    position:relative;
    width:90%;
    height:50px;
    margin-top:29% !important;
    margin-bottom:-16px;
    margin-left:8px;
}
}

@media screen and (min-width:960px) and (max-width:1200px){
    .fleetStatusIconChartLabel.MuiTypography-body1 {
        display: inline;
        color: black;
        margin-left: 37%;
        font-size: 0.975rem;
    }
    .MuiPaper-root.fleetStatusPaper.MuiPaper-elevation3.MuiPaper-rounded {
        width: 85%;
        height: 250px;
        border-radius: 10px;
    }
    .fleetStatusRPGraphDiv{
        position:relative;
        width:90%;
        height:50px;
        margin-top:38%;
        margin-bottom:-16px;
        margin-left:8px;
    }
}
@media screen and (min-width:1390px) and (max-width:1925px){
    .MuiPaper-root.fleetStatusPaper.MuiPaper-elevation3.MuiPaper-rounded {
        width: 90%;
        height: 27em;
        border-radius: 1.0rem;
    }
    .fleetStatusRPHeading.MuiTypography-body1 {
        padding-top: 4%;
        padding-bottom: 2%;
        font-size: 1.25rem;
        color: black;
        font-weight: bolder;
    }
    .fleetStatusIconDiv {
        margin: 3%;
        margin-top: 2%;
        height:1em;
    }
    .fleetStatusIconSubDiv {
        margin-top: 4%;
    }
    
    .fleetStatusChartTitleGrid {
        margin-top: 2% !important;
    }
    .fleetStatusIconChartLabel.MuiTypography-body1 {
        display: inline;
        color: black;
        margin-left: 4% !important;
        font-size: 1.0rem !important;
        font-weight: bold !important;
    }
    .fleetStatusIcons {
        width: 85%;
        height: 120%;
        display: inline;
    }
    .fleetStatusRPGraphDiv {
        position: relative;
        width: 90%;
        height: 60px;
        margin-top: 33% !important;
        margin-left: 2%;
    }
    .fleetStatusRedRPDiv {
        background-color: red;
        height: 85%;
        position: absolute;
        border-radius: 50px;
        z-index: 10;
        display: inline-block;
    }
    .fleetStatusYellowRPDiv {
        background-color: #FFEA00;
        height: 85%;
        position: absolute;
        border-radius: 50px;
        z-index: 5;
        display: inline-block;
    }
    .fleetStatusGreenRPDiv {
        background-color: rgb(3, 92, 3);
        position: absolute;
        border-radius: 50px;
        z-index: 15;
        display: inline-block;
        height: 85%;
    }
}
@media screen and (min-width:1920px) and (max-width:1925px){
    .fleetStatusRPHeading.MuiTypography-body1 {
        padding-top: 4%;
        padding-bottom: 2%;
        font-size: 1.6rem;
        color: black;
        font-weight: bolder;
    }
    .fleetStatusIconChartLabel.MuiTypography-body1 {
        display: inline;
        color: black;
        margin-left: 4% !important;
        font-size: 1.4rem !important;
        font-weight: bold !important;
    }
}