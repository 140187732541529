    .dataTableGrid.MuiGrid-grid-md-4{
        max-width:24%;
        margin-bottom:10px;
        height:28.5em;
    }
    .weeklyDataHead.MuiTypography-body1 {
        margin-left: 35px;
        margin-bottom: 10px;
        font-size: 1.2rem;
    }
    .weeklyDataGridContainer.MuiGrid-container{
        width:100px;
        display:block;
        
    }
    .weeklyDataGridItem{
        display:initial;
        max-width:100px;
    }
    .weeklyDataGridDiv {
        display: inline-block;
        width: 20px;
        height: 13.15%;
}
    .weeklyDataGridTopDiv {
        display: inline-block; 
        width: 20px;
        height: 13.15%;
        border-top-left-radius: 10px;

    }
    .weeklyDataGridBottomDiv {
        display: inline-block;
        width: 20px;
        height: 13.15%;
        border-bottom-left-radius: 10px;

    }
    .MuiPaper-root.dataWeeklyTablePaper.MuiPaper-elevation3.MuiPaper-rounded{
        border-radius:0px;
        border-top-right-radius:20px;
        border-bottom-right-radius:20px;
    }
    .gradeHeader.MuiTypography-body1 {
        display: inline-block;
        margin-left: 40%;
        font-size: 1.2rem;
    }
    .dowHeader.MuiTypography-body1 {
        display: inline-block;
        margin-left: 25px;
        font-size: 1.2rem;
    }
    .MuiTable-root.dataWeeklyTable{
        width:90%;
        margin:10px;
    }


@media screen and (min-width:986px) and (max-width:1300px) {

.gradeHeader.MuiTypography-body1 {
        display: inline-block;
        margin-left: 27%;
        font-size: 1.2rem;
    }
    .dowHeader.MuiTypography-body1 {
        display: inline-block;
        margin-left: 45px;
        font-size: 1.2rem;
    }
    .MuiPaper-root.dataWeeklyTablePaper.MuiPaper-elevation3.MuiPaper-rounded{
        border-radius:0px;
        border-top-right-radius:20px;
        border-bottom-right-radius:20px;
        margin-left:15px;

    }
}
@media screen and (min-width:310px) and (max-width:900px) {
    .gradeHeader.MuiTypography-body1 {
        display: inline-block;
        margin-left: 40%;
    }
    .dowHeader.MuiTypography-body1{
        display: inline-block;
        margin-left: 70px;
    }
    .MuiPaper-root.dataWeeklyTablePaper.MuiPaper-elevation3.MuiPaper-rounded{
        width:100%;
        margin-left: 40px;
        border-radius:0px;
        border-top-right-radius:20px;
        border-bottom-right-radius:20px;
    }
}
@media screen and (min-width:310px) and (max-width:380px) {
    .gradeHeader.MuiTypography-body1{
        display:inline-block;
        margin-left:70px;
    }
    .dowHeader.MuiTypography-body1{
        display: inline-block;
        margin-left: 70px;
    }
    .MuiPaper-root.dataWeeklyTablePaper.MuiPaper-elevation3.MuiPaper-rounded{
        width:100%;
        margin-left: 20px;
        border-radius:0px;
        border-top-right-radius:20px;
        border-bottom-right-radius:20px;
    }
}
@media screen and (min-width:1100px) and (max-width:1180px) {
    .tableGridContainer{ 
        margin-left: 15%;
    }
    .gradeHeader.MuiTypography-body1 {
        display: inline-block; 
        margin-left: 60px;
    }
    
    .dataTableGrid.MuiGrid-grid-md-4 {
        max-width: 25%;
        margin-bottom: 10px;
    }
}
@media screen and (min-width:960px) and (max-width:1020px){

    .gradeHeader.MuiTypography-body1 {
    display: inline-block;
    margin-left: 132px;
    }
}