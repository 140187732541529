    .batteryDiv {
        background-color: #336699;
        border-radius: 15px;
        width: 74%;
        height: 155px;
  }
    .batteryDivMessage{
        background-color: #336699;
        border-radius: 15px;
        width: 70%;
        height: 202px;
    }
    .infoIconImg {
        width: 20px;
        height: 20px;
        margin-left: 92%;
        margin-top: 3%;
        cursor: pointer;
    }
    .batteryTitleDiv{
        margin-left: 50px;
    }
    .batteryIconImgDiv{
        margin-top:-4px !important;
        margin-right:-15px !important;
    }
    .batteryIconImg{
        width: 30px;
        height: 30px;
    }
    .batteryMeterImg{
        width:90%;
        margin-left: 15px;
        margin-bottom: 2px;
    }
    .batteryMeterLabelValues {
        width: 92%;
    }
    .batteryMeterImgDiv{
        margin-bottom: 10%;
    }
    .batteryMeterLabel {
        margin-left: 12px;
        font-size: 12px;
        color: white;
        width: 5%;
        display: inline;
    }
    .batteryMeterLabel1 {
        margin-left: 6.5%;
        font-size: 12px;
        color: white;
        width: 5%;
        display: inline;
}
    .batterySliderGrid.MuiGrid-container{
        width:90%;
        margin-left:15px;
        margin-top:15px;
    }
    .batterySliderGrid.MuiGrid-container.MuiSlider-thumb.PrivateValueLabel-offset-80.MuiSlider-valueLabel.PrivateValueLabel-circle-81{
        background-color: unset;
        top:-10px;
        font-size:14px;    
    }
    .MuiTypography-root.batteryIconLabel.MuiTypography-h5.MuiTypography-displayBlock{
        color:white;
        font-size:16px;
        font-weight:bold;
}
    .infoCSBoxModal{
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
        width: 400px;
        background-color: white;
        border-radius: 30px;
        box-shadow: 24;
    }
    .closeCSModalIcon{
        width:40px;
        height:40px;
        margin-left: 350px;
        margin-top: 10px;
        cursor: pointer;
    }
    .infoCSModalIcon{
        width:40px;
        height:40px;
        margin-left: 180px;
        margin-top: -10px;
    }
    .modalInfo.MuiTypography-body1{
        margin-left:10px;
        font-size:13px;
        padding: 20px 50px 50px 50px;
        text-align: center ;
        cursor: pointer;
    }
    .infoMessage.MuiTypography-subtitle2{
        width:90%;
        font-size:13px;
        text-align: left ;
        margin-top:10px;
        margin-left:18px;
        color:white;
    }
@media only screen and (min-width:600px) and (max-width:900px) {
.batteryMeterLabel{
    margin-left:12px;
    font-size:10px;
    color:white;
    width:5%;
    display: inline;
}
.batteryMeterLabel1{
    margin-left:21.8px;
    font-size:10px;
    color:white;
    width:5%;
    display: inline;
}
}
@media only screen and (min-width:360px) and (max-width:900px) {
    .batteryMeterLabel{
        margin-left:4.5%;
        font-size:10px;
        color:white;
        width:5%;
        display: inline;
    }
    .batteryMeterLabel1{
        margin-left:6.7%;
        font-size:10px;
        color:white;
        width:5%;
        display: inline;

    }
    .batteryMeterImg{
        width:90%;
        margin-left: 15px;
        margin-bottom: 2px;
    }
    .batteryMeterLabelValues{
        width:97%;
    }
    .batteryDivMessage{
        background-color: #336699;
        border-radius: 15px;
        width: 70%;
        height: 212px;
    }
 }
 @media only screen and (min-width:960px) and (max-width:1019px){
    .textFieldContainer {
        width: 126%;
        margin-left: -90px;
        margin-top: 32px;
    }
    .infoIconImg {
        width: 25px;
        height: 25px;
        margin-left: 92%;
        margin-top: 10px;
    }
    .MuiTypography-root.batteryIconLabel.MuiTypography-h5.MuiTypography-displayBlock {
        color: white;
        font-size: 18px;
        font-weight: bold;
    }
    .batteryMeterLabelValues {
        width: 114%;
    }
    .batteryMeterImg {
        width: 93%;
        margin-left: 15px;
        margin-bottom: 2px;
    }
    .batterySliderGrid.MuiGrid-container {
        width: 92%;
        margin-left: 15px;
        margin-top: 15px;
    }
 }

 @media only screen and (min-width:1020px) and (max-width:1920px) {
    .textFieldContainer {       
        margin-left: 10px;
        margin-top: 32px;

    }    
 }

@media only screen and (min-width:1920px) and (max-width:2110px) {
    .batteryDiv { 
        background-color: #336699;
        border-radius: 15px;
        width: 75%;
        height: 157px;
    }
}