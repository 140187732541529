
.OnboardingFourContainer{
    margin: 0px !important;
    padding: 0px !important;
    background-color: #b8bdc5 !important;
    width: 100% !important;
    max-width: 100% !important;
}

.logoGridFour{
    position: relative;
}
.circleTopLeftImgFour {
    margin-left: -14%;
    margin-top: -18%;
    width: 80%;
    z-index: 1;
    position: absolute;
}
.windEverestLogoFour {
    margin-left: 15%;
    margin-top: 8%;
    width: 30%;
    z-index: 2;
    position: absolute;
}
.MuiTypography-root.arrowOneInfoFour.MuiTypography-body1 {
    color: #369;
     font-size: 17px;
     font-weight: bolder;
     width: 90%;
     margin-left: -20px;
 }
 
 .arrowIconsFour {
     height: 17px;
     margin-top: 4px;
     margin-left: 4px;
 }
 .circleBottomLeftFour {
    width: 10%;
    margin-top: 20%;
    margin-left: 75%;
}
.infoLowerGrid{
    display: flex;
    margin-top: -70px !important;
    height: 150px;
    margin-left: 380px !important;
    width: 40%;
}

.MuiTypography-root.arrowTwoInfo.MuiTypography-body1{
    color:white;
    font-size: 20px;
    font-weight: bolder;
    width: 110%;
    margin-left: -10px;
}
.MuiTypography-root.arrowThreeInfo.MuiTypography-body1{
    color:white;
    font-size: 20px;
    font-weight: bolder;
    width: 110%;
    margin-left: -10px;
}
.MuiGrid-root.MuiGrid-item.MuiGrid-grid-md-1.infoGridIcon{
    max-height: 30px;
}
.laptopImageFour {
    width: 150%;
    z-index: 2;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-35%);
}

.rightCircleIconFour {
    width: 33%;
    margin-left: 373% !important;
    margin-top: -29%;
}
.infoGridFour {
    display: flex;
    margin-top: 5% !important;
    height: 0px;
    margin-left: 2% !important;
}
.popupBadgeIconFour {
    width: 125%;
    margin-top: 26%;
    margin-left: -42%;
    height: 53%;
    z-index: 3;
    position: absolute;
}
.circleBottomRightFour {
    width: 26%;
    margin-top: 102%;
    margin-left: -25%;
    z-index: 1;
    position: absolute;
}

.badgeHeadingOnboarding {
    position: absolute;
    z-index: 3;
    margin-top: 97px !important;
    font-size: 23px !important;
    font-weight: bolder !important;
    color: #369;
    margin-left: 12px !important;
}
.badgeBarIconOnboarding {
    z-index: 3;
    position: absolute;
    margin-top: 134px;
    width: 13%;
    margin-left: 32px;
    height: 2px;
}
.allBadgeInfoOnboarding {
   z-index: 3;
	position: absolute;
	margin-top: 155px !important;
	margin-left: -63px !important;
	font-size: 11px !important;
	font-weight: bold !important;
	width: 72%;
	text-align: center;
}
.badgesIconOnboarding {
    width: 98%;
    z-index: 3;
    position: absolute;
    margin-top: 194px;
    margin-left: -93px;
}
.popupBadgeGridFour {
    position: relative;
    margin-top: 5% !important;
    margin-left: 0% !important;
}
.weeklyTableIcon{
    width: 70%;
    z-index: 2;
    position: absolute;
    margin-left: 100px;
    margin-top: 185px;
}


.headingBadge {
    z-index: 3;
    color:#369;
    position: absolute;
    margin-top: 109px;
    margin-left: 22px;
}
.footerOnBoardingEvenFour {
    background-color: #369;
    height: 15%;
    margin-top: 15%;
}
.MuiTypography-root.typographyTACFour.MuiTypography-body1{
    margin: 50px;
    color: white;
    text-decoration: underline;
    margin-left: 160px;
}
.MuiTypography-root.typographyPolicyFour.MuiTypography-body1{
    margin: 50px;
    color: white;
    text-decoration: underline;
    margin-left: 160px;
}
.MuiTypography-root.typographyContactFour.MuiTypography-body1{
    margin: 50px;
    color: white;
    text-decoration: underline;
    margin-left: 160px;
}
.typographyTACGrid{
    margin-top:5% !important;
}
.typographyPolicyGrid{
    margin-top:5% !important;
}
.typographyContactGrid{
    margin-top:5% !important;
}
@media screen and (min-width:1400px) and (max-width:1910px)
{
    .circleTopLeftImgFour {
        margin-left: -14%;
        margin-top: -18%;
        width: 70%;
        z-index: 1;
        position: absolute;
    }
    .windEverestLogoFour {
        margin-left: 12%;
        margin-top: 7%;
        width: 26%;
        z-index: 2;
        position: absolute;
    }
    .MuiTypography-root.arrowOneInfoFour.MuiTypography-body1 {
        color: #369;
        font-size: 1.0rem;
        font-weight: bolder;
        width: 82%;
        margin-left: 1%;
        text-align: justify;
        margin-top: 3%;
    }
    .arrowIconsFour {
        height: 19px;
        margin-top: -56%;
        margin-left: 52%;
    }
    .MuiTypography-root.arrowOneInfoFour.MuiTypography-body1 {
        color: #369 !important;
        font-size: 1.0rem !important;
        font-weight: bolder !important;
        width: 82% !important;
        margin-left: 1% !important;
        text-align: justify !important;
        margin-top: 3% !important;
    }
    .popupBadgeIconFour {
        width: 125%;
        margin-top: 15%;
        margin-left: -37%;
        height: 53%;
        z-index: 3;
        position: absolute;
    }
    .popupBadgeGridFour {
        position: relative;
        margin-top: 7% !important;
        margin-left: 0% !important;
    }
    
    .badgeHeadingOnboarding {
        position: absolute;
        z-index: 3;
        margin-top: 19% !important;
        font-size: 1.4rem !important;
        font-weight: bolder !important;
        color: #369;
        margin-left: 15% !important;
    }
    .badgeBarIconOnboarding {
        z-index: 3;
        position: absolute;
        margin-top: 29% !important;
        width: 13%;
        margin-left: 20%;
        height: 2px;
    }
    .allBadgeInfoOnboarding {
        z-index: 3;
        position: absolute;
        margin-top: 34% !important;
        margin-left: -9% !important;
        font-size: 11px !important;
        font-weight: bold !important;
        width: 72%;
        text-align: center;
    }
    .badgesIconOnboarding {
        width: 98%;
        z-index: 3;
        position: absolute;
        margin-top: 45%;
        margin-left: -20%;
    }
    .circleBottomRightFour {
        width: 26%;
        margin-top: 90%;
        margin-left: -23%;
        z-index: 1;
        position: absolute;
    }
    .circleBottomLeftFour {
        width: 10%;
        margin-top: 21%;
        margin-left: 85%;
    }

}
@media screen and (min-width:900px) and (max-width:1200px)
{
    .rightCircleIconFour {
        width: 34%;
        margin-left: 376% !important;
        margin-top: -29%;
    }
    .MuiTypography-root.arrowOneInfoFour.MuiTypography-body1 {
        color: #369;
        font-size: 0.80rem;
        font-weight: bolder;
        width: 90%;
        margin-left: -13px;
        margin-top: 2px;
    }
    .circleBottomLeftFour {
        width: 10%;
        margin-top: 23%;
        margin-left: 86%;
    }
    .badgeHeadingOnboarding {
        position: absolute;
        z-index: 3;
        margin-top: 75px !important;
        font-size: 0.90rem !important;
        font-weight: bolder !important;
        color: #369;
        margin-left: 20px !important;
    }
    .badgeBarIconOnboarding {
        z-index: 3;
        position: absolute;
        margin-top: 98px;
        width: 13%;
        margin-left: 32px;
        height: 2px;
    }
    .allBadgeInfoOnboarding {
        z-index: 3;
        position: absolute;
        margin-top: 110px !important;
        margin-left: -61px !important;
        font-size: 0.65rem !important;
        font-weight: bold !important;
        width: 91%;
        text-align: center;
    }
    .badgesIconOnboarding {
        width: 114%;
        z-index: 3;
        position: absolute;
        margin-top: 57%;
        margin-left: -34%;
    }
    .popupBadgeIconFour {
        width: 125%;
        margin-top: 26%;
        margin-left: -42%;
        height: 59%;
        z-index: 3;
        position: absolute;
    }
    .footerOnBoardingEvenFour {
        background-color: #369;
        height: 30%;
        margin-top: 15%;
    }
    .MuiTypography-root.typographyTACFour.MuiTypography-body1 {
        margin: 50px;
        color: white;
        text-decoration: underline;
        margin-left: 91px;
        width: 100%;
    }
}
@media screen and (min-width:1060px) and (max-width:1200px)
{   .badgeHeadingOnboarding {
    position: absolute;
    z-index: 3;
    margin-top: 30% !important;
    font-size: 0.90rem !important;
    font-weight: bolder !important;
    color: #369;
    margin-left: 11% !important;
}
.badgeBarIconOnboarding {
    z-index: 3;
    position: absolute;
    margin-top: 38%;
    width: 13%;
    margin-left: 14%;
    height: 2px;
}
.allBadgeInfoOnboarding {
    z-index: 3;
    position: absolute;
    margin-top: 44% !important;
    margin-left: -24% !important;
    font-size: 0.65rem !important;
    font-weight: bold !important;
    width: 91%;
    text-align: center;
}

}
@media screen and (min-width:1920px) and (max-width:2000px)
{
    .MuiTypography-root.arrowOneInfoFour.MuiTypography-body1 {
        color: #369 !important;
        font-size: 1.4rem !important;
        font-weight: bolder !important;
        width: 77% !important;
        margin-left: 1% !important;
        text-align: justify !important;
        margin-top: 3% !important;
    }
    .arrowIconsFour {
        height: 25px;
        margin-top: 2rem;
        margin-left: 3rem;
      }
      .popupBadgeIconFour {
        width: 125%;
        margin-top: 25%;
        margin-left: -35%;
        height: 53%;
        z-index: 3;
        position: absolute;
      }
      .badgeHeadingOnboarding {
        position: absolute;
        z-index: 3;
        margin-top: 30% !important;
        font-size: 1.6rem !important;
        font-weight: bolder !important;
        color: #369;
        margin-left: 17% !important;
      }
      .badgeBarIconOnboarding {
        z-index: 3;
        position: absolute;
        margin-top: 38%;
        width: 13%;
        margin-left: 20%;
        height: 2px;
      }
      .allBadgeInfoOnboarding {
        z-index: 3;
        position: absolute;
        margin-top: 43% !important;
        margin-left: -12% !important;
        font-size: 1.2rem !important;
        font-weight: 700 !important;
        width: 84%;
        text-align: center;
      }
      .badgesIconOnboarding {
        width: 98%;
        z-index: 3;
        position: absolute;
        margin-top: 55%;
        margin-left: -17%;
      }
    .MuiTypography-root.typographyContactFour.MuiTypography-body1, .MuiTypography-root.typographyPolicyFour.MuiTypography-body1, .MuiTypography-root.typographyTACFour.MuiTypography-body1 {
        color: #fff;
        text-decoration: underline;
        margin: 47px 50px 50px 46%;
        font-size: 1.5rem;
    }
    .rightCircleIconFour {
        width: 33%;
        margin-left: 377% !important;
        margin-top: -29%;
    }
    
     
}
