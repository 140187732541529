
.gamificationPaper{
    border-radius: 20px !important;
}
.gamificationWebContainer{
    height:415px;
}

.cO2TableGrid {
	width: 30% !important;
	margin: 25px !important;
	margin-left: 120px !important;
}
.cO2TabDetailsGrid {
	margin-left:-150px !important;
}
.badgeCO2Ruler{
    color: #173b5f;
    margin-top: 0px;
    margin-left: -7%;
    width: 40%;    
}
.badgeCO2Description{
	width: 100%;
	padding-bottom: 30px;
	margin-left: 15px;
}
.gamificationCO2Paper {
    border-radius: 20px !important;
    margin-left: 12px;
    width: 100%;
    height: 100%;
}
.gamificationPaper {
    border-radius: 20px !important;
    margin-left: -20px;
    margin-top: -14px;
    width: 212%;
}
.badgeGamificationHeading{
    padding-top: 30px;
    color: #369;
    font-weight: bolder;	
}
.badgeRuler{
    color:#173b5f;
    margin-top:0px;
    margin-left:0px;
    width:20%;
}
.badgeDescription{
    width:60%;
    padding-bottom: 30px;
}
.badgeUnlockedMessage{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    width: 450px;
    background-color: white;
    border-radius: 5px;
    height: auto;
    padding-bottom: 20px;
}
.badgeLockedMessage{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    width: 450px;
    background-color: white;
    border-radius: 5px;
    height:auto;
    padding-bottom: 20px;
}
.closeBadgeMessageIcon{
    width:40px;
    height:40px;
    margin-left: 420px;
    margin-top: -10px;
    cursor: pointer;
}
.badgeUnlockedIcon{
    width:60px;
    height:60px;
    margin-left: 190px;
    margin-top: 20px;
}
.badgeLockedIcon{
    width:100px;
    height:100px;
    margin-left: 160px;
    margin-top: 40px;
}
.badgeUnlockedTitleInfo{
    font-size: 22px;
    font-weight: bolder;
    text-align: center;
    margin-left: 55px !important;
    width: 40vh !important;
}
.badgeLockedTitle{
    margin-left: 120px !important;
    width: 200px;
    font-size: 16px !important;
    text-align: center;
    margin-top: 20px !important;
}
.titleUnlockedHR{
    margin-top:-1px;
    padding:0;
    width:300px;
}
.badgeUnlockedInfo{
    margin-left: 70px !important;
    width: 310px;
    font-size: 16px !important;
    text-align: center !important;
}
.tableBadgeImage{
    width:30px;
    cursor: pointer;
}
@media screen and (min-width:360px) and (max-width:900px){
    .tableBadgeImage{
        width:20px;
        cursor: pointer;
    }
    .tableCells{
        padding:10px;
    }
    .badgeUnlockedMessage{
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
        width: 350px;
        background-color: white;
        border-radius: 5px;
        height: auto;
        padding-bottom: 20px;
    }
    .badgeLockedMessage{
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
        width: 350px;
        background-color: white;
        border-radius: 5px;
        height:auto;
        padding-bottom: 20px;
    }
    .badgeLockedIcon{
        width: 70px;
        height: 70px;
        margin-left: 130px;
        margin-top: 20px;
    }
    .badgeLockedTitle{
        margin-left:70px;
        width: 200px;
        font-size:18px;
        text-align: center ;
        margin-top:20px;
    }
    .closeBadgeMessageIcon{
        width:40px;
        height:40px;
        margin-left: 320px;
        margin-top: -10px;
        cursor: pointer;
    }
    .badgeUnlockedInfo{
        margin-left: 24px;
        width: 310px;
        font-size: 14px;
        text-align: center;
    }
    .badgeUnlockedTitleInfo {
        font-size: 20px;
        font-weight: bolder;
        text-align: center;      
    }
    .badgeUnlockedIcon {
        width: 60px;
        height: 60px;
        margin-left: 140px;
        margin-top: 5px;
    }
    .gamificationPaper {
        border-radius: 20px !important;
        margin-left: -12px;
        margin-top: 100px;
        width: 112%;
    }
    .gamificationWebContainer {
        width: 120%;
        margin-left: -72px;
        margin-top: -55px;
    }
    .cO2TabDetailsGrid {
        margin-left: 10px !important;
    }
    .gamificationCO2Paper {
        border-radius: 20px !important;
        margin-left: 12px;
        width: 100%;
        height: 100%;
    }
}
@media screen and (min-width:1390px) and (max-width:2100px){
    .gamificationWebContainer {
        height: 138%;
    }
    .gamificationWebContainer {
        width: 95% !important;
        margin-left: 6% !important;
        margin-top: 0%;
    }
    .gamificationPaper {
        border-radius: 20px !important;
        margin-left: -1% !important;
        margin-top: 0% !important;
        width: 209% !important;
        height: 417px;
    }
    .badgeGamificationHeading {
        padding-top: 30px;
        color: #369;
        font-weight: bolder !important;
        font-size: 1.6rem !important;
        margin-left: -8% !important;
    }
   
    .badgeDescription {
        width: 90%;
        padding-bottom: 30px;
        margin-left: -8% !important;
        font-weight: bolder !important;
    }
    .badgeTable {
        width: 177% !important;
        margin-left: 63% !important;
    }
    .simpleBadgeTable{
        margin-left:-5% !important;
    }
    
    .gamificationCO2Paper {
        border-radius: 20px !important;
        margin-left: 19% !important;
        width: 100%;
        height: 97%;
        margin-top: 10%;
    }
    .cO2TabDetailsGrid {
        margin-left: -25% !important;
    }
    .badgeRuler {
        color: #173b5f;
        margin-top: 0px;
        margin-left: -7%;
        width: 27%;
    }
    .badgeCO2Ruler{
        color: #173b5f;
        margin-top: 0px;
        margin-left: -7%;
        width: 40%;    
    }
    .badgeCO2Description {
        width: 100%;
        padding-bottom: 30px;
        margin-left: -2.5% !important;
    }
    .cO2TableGrid {
        width: 100% !important;
        margin: 25px !important;
        margin-left: -16% !important;
        max-width: 100% !important;
    }
    .cO2TableGrid .tableBadgeImage {
        width: 100% !important;
        cursor: pointer;
    }
    .tableBadgeImage {
        width: 140% !important;
        cursor: pointer;
    }
    
}
@media screen and (min-width:960px) and (max-width:1050px){

.cO2TabDetailsGrid {
    margin-left: 5% !important;
}
.badgeGamificationHeading {
    padding-top: 30px;
    color: #369;
    font-weight: bolder !important;
    font-size: 1.75rem !important;
}
.gamificationPaper {
    border-radius: 20px !important;
    margin-left: -20px;
    margin-top: -14px;
    width: 100%;
}
.badgeRuler {
    color: #173b5f;
    margin-top: 0px;
    margin-left: 0px;
    width: 45%;
}
.badgeCO2Description {
    width: 100%;
    padding-bottom: 30px;
    margin-left: 15px;
    font-size: 1.25rem !important;
}
.tableBadgeImage {
    width: 40px;
    cursor: pointer;
}
.cO2TableGrid {
    width: 30% !important;
    margin: 25px !important;
    margin-left: 3% !important;
}
.gamificationCO2Paper {
    border-radius: 20px !important;
    margin-left: 12px;
    width: 100%;
    height: 54%;
}
}
@media screen and (min-width:1050px) and (max-width:1200px){
    .gamificationPaper {
        border-radius: 20px !important;
        margin-left: -20px;
        margin-top: -14px;
        width: 103%;
        height: 415px;
    }
    .cO2TabDetailsGrid {
        margin-left: -10% !important;
    }
    .cO2TableGrid {
        width: 35% !important;
        margin: 25px !important;
        margin-left: 0% !important;
        margin-top: 8% !important;
    }
    .badgeCO2Description {
        width: 100%;
        padding-bottom: 30px;
        margin-left: 15px !important;
    }
    .badgeTable{
        width: 120% !important;
        margin-left: 13%;
    }
    .badgeCO2Ruler {
        color: #173b5f;
        margin-top: 0px;
        margin-left: -3%;
        width: 40%;
    }

.badgeGamificationHeading {
    padding-top: 30px;
    color: #369;
    font-weight: bolder !important;
    margin-left: 3% !important;
}
.badgeCO2Ruler {
    color: #173b5f;
    margin-top: 0px;
    margin-left: 3%;
    width: 33%;
}
.badgeDescription {
    width: 60%;
    padding-bottom: 30px;
    margin-left: 3% !important;
}
.tableBadgeImage {
    width: 230%;
    cursor: pointer;
}
.tableBadgeCO2Off{
	margin-top: 4%;
}
.gamificationCO2Paper {
    border-radius: 20px !important;
    margin-left: 12px;
    width: 100%;
    height: 100%;
}
}

@media screen and (min-width:1920px) and (max-width:2100px){
    .tableBadgeImage {
        width: 115% !important;
        cursor: pointer;
    }
    .badgeGamificationHeading {
        padding-top: 30px;
        color: #369;
        font-weight: bolder !important;
        font-size: 1.6rem !important;
        margin-left: -4% !important;
    }
    .badgeRuler {
        color: #173b5f;
        margin-top: 0px;
        margin-left: -4%;
        width: 24%;
    }
    .badgeDescription {
        width: 90%;
        padding-bottom: 30px;
        margin-left: -4% !important;
        font-weight: bolder !important;
    }
    .gamificationPaper {
        border-radius: 20px !important;
        margin-left: -1% !important;
        margin-top: 0% !important;
        width: 209% !important;
        height: 445px;
    }
    .simpleBadgeTable {
        margin-left: -5% !important;
        margin-top: 5%;
    }
    .badgeCO2Ruler {
        color: #173b5f;
        margin-top: 0px;
        margin-left: -3%;
        width: 40%;
    }
    .gamificationCO2Paper {
        border-radius: 20px !important;
        margin-left: 20% !important;
        width: 82%;
        height: 88%;
        margin-top: 10%;
    }
    .cO2TabDetailsGrid {
        margin-left: -46% !important;
    }
    .badgeTable {
        width: 185% !important;
        margin-left: 63% !important;
    }
    .cO2TableGrid {
        width: 100% !important;
        margin: 25px !important;
        margin-left: 21% !important;
        max-width: 100% !important;
    }
}