.notificationContainer{
    background-color:white;
    
}
.notificationBodyContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}
.bellIcon{
    width:200px;
    margin-bottom:20px;
}
.MuiTypography-root.notificationTitle.MuiTypography-h6{
    font-weight: bolder;
    font-size:26px; 
    color: #336699;
    margin-bottom:20px;
}
.MuiTypography-root.notificationsTitle.MuiTypography-h6{
    font-weight: bolder;
    font-size: 20px;
    color: #336699;
    margin-bottom: 20px;
    margin-top: 70px;
}
.MuiTypography-root.notificationInfo.MuiTypography-body1{
    margin-bottom:35px;
    width:60%;
    text-align:center;
}
.MuiTypography-root.todayHeading.MuiTypography-body1{
    font-size: 18px;
    font-weight: bolder;
}
.MuiTypography-root.yesterdayHeading.MuiTypography-body1{
    font-size: 18px;
    font-weight: bolder;
    margin-left: 20px !important;
}
.buttonRefresh{
    background-color:rgb(0, 102, 51) !important;
    color: white !important;
    margin-top:30px ;
    border-radius: 40px !important; 
    text-transform: none !important;
    width: 300px; 
}

.todayPaper {
    height: auto;
    margin-top: 10px;
    margin-bottom: 20px;
    border-radius: 12px !important;
    width: 90%;
    margin-right: 20px;
}

.todayUnreadPaper {
    height: auto;
    margin-top: 10px;
    margin-bottom: 20px;
    border-radius: 12px !important;
    border-left: 6px solid rgb(0, 102, 51);
    width: 90%;
    margin-right: 20px;
}

.yesterdayPaper {
    height: auto;
    margin-top: 10px;
    margin-bottom: 20px;
    border-radius: 12px !important;
    width: 90%;
    margin-right: 20px;
}
.yesterdayUnreadPaper{
    height: auto;
    margin-top: 10px;
    margin-bottom: 20px;
    border-radius: 12px !important;
    border-left: 6px solid rgb(0, 102, 51);
    width: 90%;
    margin-right: 20px;
}
.MuiTypography-root.yesterdayContent.MuiTypography-body1{
    padding: 20px;
    font-size: 14px;
    font-weight: lighter;
    width: 320px;
    margin-right: 20px;
    text-align:justify;
}
.MuiTypography-root.todayContent.MuiTypography-body1 {
    padding: 20px;
    font-size: 14px;
    font-weight: lighter;
    text-align: justify;
}
.MuiTypography-root.yesterdayTimer.MuiTypography-body1 {
    margin-left: 200px;
    font-size: 13px;
    margin-top: 0px;
    font-weight: lighter;
    color: gray;
    padding-bottom: 10px;
}
.MuiTypography-root.todayTimer.MuiTypography-body1 {
    margin-left: 220px;
    font-size: 12px;
    margin-top: -19px;
    font-weight: lighter;
    color: gray;
    padding-bottom: 12px;
}
@media screen and (min-width:960px) and (max-width:1200px){
    .todaysGrid {
        max-width: 56% !important;
        width: 56% !important;
    }
    .yesterdaysGrid {
        max-width: 56% !important;
        width: 56% !important;	
    }
    .yesterdayUnreadPaper {
        height: auto;
        margin-top: 5%;
        margin-bottom: 6% !important;
        border-radius: 12px !important;
        border-left: 6px solid rgb(0, 102, 51);
        width: 95%;
        margin-right: 4%;
    }
    .MuiTypography-root.yesterdayContent.MuiTypography-body1 {
        padding: 20px;
        font-size: 14px;
        font-weight: lighter;
        width: 280px !important;
        margin-right: 20px;
        text-align: justify;
    }
    .yesterdayPaper {
        height: auto;
        margin-top: 10px;
        margin-bottom: 20px;
        border-radius: 12px !important;
        width: 97%;
        margin-right: 20px;
    }
    .todayPaper {
        height: auto;
        margin-top: 6%;
        margin-bottom: 5%;
        border-radius: 12px !important;
        width: 95%;
        margin-right: 20px;
    }
    .todayUnreadPaper {
        height: auto;
        margin-top: 6%;
        margin-bottom: 5%;
        border-radius: 12px !important;
        border-left: 6px solid rgb(0, 102, 51);
        width: 95%;
        margin-right: 20px;
    }
    .MuiTypography-root.todayTimer.MuiTypography-body1 {
        margin-left: 200px;
        font-size: 12px;
        margin-top: -10px;
        font-weight: lighter;
        color: gray;
    }    
}
@media screen and (min-width:1390px) and (max-width:1925px){
    .MuiTypography-root.yesterdayTimer.MuiTypography-body1 {
        margin-left: 71%;
        font-size: 0.75rem;
        margin-top: 0px;
        font-weight: lighter;
        color: gray;
        padding-bottom: 2%;
    }
    .MuiTypography-root.yesterdayContent.MuiTypography-body1 {
        padding: 5%;
        font-size: 0.95rem;
        font-weight: lighter;
        width: 89%;
        margin-right: 4%;
        text-align: justify;
    }
    .MuiTypography-root.todayContent.MuiTypography-body1 {
        padding: 5%;
        font-size: 0.95rem;
        font-weight: lighter;
        text-align: justify;
        width: 89%;
    }
    .MuiTypography-root.todayTimer.MuiTypography-body1 {
        margin-left: 70%;
        font-size: 0.75rem;
        margin-top: 0%;
        font-weight: lighter;
        color: gray;
        padding-bottom: 2%;
    }
    .todayPaper {
        height: auto;
        margin-top: 10px;
        margin-bottom: 20px;
        border-radius: 12px !important;
        width: 90%;
        margin-right: 20px;
    }
}