.createNewAccount{
    display: flex;
    flex-direction: column;
    /* width: 100vw; */
    /* height: 100vh; */

}

.header{
    width: 100%;
    height: 100px;
    margin-top: -20px;
    
}

.header img{
    width: 150px;
    margin-left: 20px;
}

.createNewAccountBody{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

.heading{
    color: #336699;
    margin-bottom: 20px;
    font-size: 25px;
}

.formContainer{
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.flexRow{
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;
    margin-bottom: 10px;
}

.logoImg{
    width: 240px!important;
    height: 160!important;
}

.infoBoxModal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 62%;
    background-color: white;
    border-radius: 30px;
    box-shadow: 24;
}
.modalInfoGrid{
    height: 400px;
    overflow: auto;
    scrollbar-width: none;
    overflow-y: scroll;
    ::-webkit-scrollbar{
        display: none;
    } 
}
.closeModalIcon{
    width:40px;
    height:40px;
    margin-left: 350px;
    margin-top: 10px;
}
.infoModalIcon {
    width: 40px;
    height: 40px;
    margin-left: 35px;
    margin-top: 40px;
}
.modalInfo.MuiTypography-body1 {
    font-size: 0.85rem !important;
    padding: 15px 30px 15px 30px;
    text-align: justify;
}
.signupTermsHeading{
    color: black;
    font-weight: bolder;
    display: inline-flex;
    margin-top: -42px !important;
    margin-left: 10px !important;
}
.infoMessage.MuiTypography-subtitle2{
    width:85%;
    font-size:13px;
    text-align: left ;
    margin-top:10px;
    margin-left:20px;
    color:white;
}
.buttonTCSignupDiv{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 20px;
    margin-bottom: 30px;
}

@media screen and (max-width: 580px) {
    .flexRow {
        flex-direction: column;
    }

    .headerContainer{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%!important;
    }

    /* .logoImg{
        /* width: 150px!important;
        height: 120px!important; }*/

    .formGroup{
        width: 100%;
    }
    .formContainer{
        width: 80%!important;
    }

    .heading{
        margin-top: 40px;
    }

    .createnewaccountBtn{
        width: 90%!important;
        margin-top: 10px!important;
    }
  }

  @media screen and (min-width: 480px) and (max-width: 860px) {
    .formContainer{
        width: 80%!important;
    }
  }

.formGroup{
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-bottom: 10px;
}

.formGroup label{
    opacity: 0.8;
}

.formGroup label span{
    color: red;
}

.errorIcon{
    color:red;
    width:20px;
}

.fullLengthFormGroup{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.fullLengthFormGroup label{
    opacity: 0.8;
}

.fullLengthFormGroup label span{
    color: red;
}

.fullLengthFormGroup input {
    outline: none;
    border: 1px solid black;
    height: 25px;
    padding-left: 10px;
    /* border-radius: 50px; */
}

.choiceGroupContainer{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}


.choiceGroupContainer input{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.choicGroup{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-bottom: 5px;
    margin-top: 5px;
    margin-left: 30px;
}

.createNewAccountBtn{
    width: 50%;
    margin-top: 10px;
    margin-bottom: 20px;
    height: 30px;
    border-radius: 50px;
    border: none;
    background-color: green;
    font-size: 16px;
    font-weight: bold;
    color: white;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
}

.createNewAccountBtn:hover{
    opacity: 0.8;
}

.termAndConditionContainer{
    margin-bottom: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
    align-items: center;
}

.termAndConditionContainer span{
    color: red;
    text-decoration: underline;
    cursor: pointer;
}

.MuiFormLabel-root{
    margin-bottom: 5px;
}

.wrapper{
    position:relative;
    width:100%;
}
.arrowIcon{
    position:relative;
    margin-left: 92%;
    margin-top:10px;
    color:gray;
    top:"50%";
}
.termsAndConditionCheckbox{
    -webkit-appearance: none;
    width:10px;
    height: 10px;
    background-color: #ffffff;
    border: 2px solid green !important;
    border-radius: 5px !important;
    box-shadow: 0 1px 2px green;
    display:flex;
    padding: 7px 7px !important;
    align-items: center;
}
.termsAndConditionCheckbox:checked{
    background-color: green;
    content:"";
    color:rgb(7, 122, 7);
    
}
.termsAndConditionCheckbox:checked:after{
    content:"\2714";
    font-size:15px;
    display: flex;
    margin-left: -5px;
    color:antiquewhite;
    
}
@media screen and (max-width:600px){
    
.wrapper{
    position:relative;
    width:100%;
}
.arrowIcon{
    position:relative;
    margin-left: 91%;
    margin-top:10px;
    color:gray;
    top:"50%";
}
}
.MuiPaper-root.MuiMenu-paper.makeStyles-dropdownStyle-72.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded{
    min-width: 306px !important;
    border-radius: 10px;
}
::-ms-reveal{display: none;}