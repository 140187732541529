.landingPageWebContainer{
    margin: 0px !important;
    padding: 0px !important;
    width: 100% !important;
    max-width: 100% !important;
}
@media screen and (min-width:960px) and (max-width:1200px)
{

.videoCard {
    width: 48% !important;
    height: 9%;
    margin-top: 20%;
    margin-left: 26%;
    display: block;
}
.MuiTypography-root.windAtGlanceInfo1.MuiTypography-body1 {
    width: 67%;
    text-align: left;
    font-size: 1.05rem;
    font-weight: bolder;
    margin-bottom: 2%;
}
.MuiTypography-root.windAtGlanceInfo2.MuiTypography-body2 {
    width: 66%;
    text-align: justify;
    font-size: 0.95rem;
    margin-bottom: 2%;
}
.MuiTypography-root.windAtGlanceInfo3.MuiTypography-body2 {
    width: 70%;
    text-align: justify;
    font-size: 0.95rem;
}
.dashboardMobileImage {
    width: 103%;
}
.personalStatMobileImage {
    width: 100%;
    margin-top: 0%;
    margin-left: -8%;
}
.MuiTypography-root.personalStatsInfo1.MuiTypography-body1 {
    margin-left: 24%;
    font-size: 1.15rem;
    font-weight: bolder;
    width: 72%;
    margin-bottom: 2%;
}
.MuiTypography-root.personalStatsInfo2.MuiTypography-body2 {
    font-size: 0.95rem;
    width: 74%;
    text-align: justify;
    margin-left: 24%;
}
.MuiGrid-root.personalStatDetailsInfo.MuiGrid-item.MuiGrid-grid-xs-6.MuiGrid-grid-md-6 {
    margin-left: -2%;
    margin-right: 2%;
}
.MuiTypography-root.groupsMessageInfo1.MuiTypography-body1 {
    font-size: 1.15rem;
    font-weight: bolder;
    width: 71%;
    margin-bottom: 2%;
}
.MuiTypography-root.groupsMessageInfo2.MuiTypography-body2 {
    font-size: 0.85rem;
    width: 65%;
    text-align: justify;
}
.groupMessageLine {
    width: 131%;
    margin-left: -31%;
    margin-top: -32%;
}
.groupsMessageImage {
    width: 115%;
    margin-left: -7%;
    margin-top: -4%;
}
.MuiGrid-root.extraFeatureDetailsInfo.MuiGrid-item.MuiGrid-grid-xs-6.MuiGrid-grid-md-6{
	margin-top: -3%;
}
.MuiTypography-root.extraFeatureInfo1.MuiTypography-body1 {
    margin-left: 24%;
    font-size: 1.25rem;
    font-weight: bolder;
    margin-bottom: 2%;
}
.MuiTypography-root.extraFeatureInfo2.MuiTypography-body2 {
    font-size: 0.85rem;
    width: 63%;
    margin-left: 24%;
    text-align: justify;
}
}
@media screen and (min-width:1100px) and (max-width:1200px)
{
    .lowerNavBarGridInfo {
        z-index: 2;
        position: absolute;
        top: 26%;
        left: 11%;
    }
    .lowerNavBarGridImage {
        z-index: 2;
        position: absolute;
        left: 40%;
        width: 61%;
        top: 16%;
        max-width: 75% !important;
    }
}